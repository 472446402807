export default {
  "추가정보": {
    "ko": "추가 정보",
    "en": "Additional Information",
    "id": "informasi tambahan",
    "ja": "(J)추가정보"
  },
  "선택": {
    "ko": "선택",
    "en": "Select",
    "id": "Pilih",
    "ja": "(J)선택"
  },
  "차대번호": {
    "ko": "차대번호",
    "en": "VIN",
    "id": "Vin",
    "ja": "(J)차대번호"
  },
  "입력하세요": {
    "ko": "입력하세요",
    "en": "Please enter.",
    "id": "Harap masuk.",
    "ja": "(J)입력하세요"
  },
  "빈칸은 안됩니다.": {
    "ko": "빈칸은 안됩니다.",
    "en": "All fields must be filled.",
    "id": "Semua bidang harus diisi.",
    "ja": "(J)빈칸은 안됩니다."
  },
  "확인": {
    "ko": "확인",
    "en": "Confirm",
    "id": "Mengonfirmasi",
    "ja": "(J)확인"
  },
  "자동차 번호는 20자 이내로 작성해주세요.": {
    "ko": "자동차 번호는 20자 이내로 작성해 주세요.",
    "en": "The license plate can have up to 20 characters.",
    "id": "Plat nomor dapat memiliki hingga 20 karakter.",
    "ja": "(J)자동차 번호는 20자 이내로 작성해주세요."
  },
  "자동차 등록이 완료되었습니다.": {
    "ko": "자동차 등록이 완료되었습니다.",
    "en": "The vehicle is successfully registered.",
    "id": "Kendaraan berhasil terdaftar.",
    "ja": "(J)자동차 등록이 완료되었습니다."
  },
  "등록된 자동차는 자동차 정보에서 조회할 수 있습니다.": {
    "ko": "등록된 자동차는 자동차 정보에서 조회할 수 있습니다.",
    "en": "The registered vehicle can be viewed in the Car Information.",
    "id": "Kendaraan terdaftar dapat dilihat dalam informasi mobil.",
    "ja": "(J)등록된 자동차는 자동차 정보에서 조회할 수 있습니다."
  },
  "필요한 경우 등록된 자동차 정보를 수정하거나": {
    "ko": "필요한 경우 등록된 자동차 정보를 수정하거나",
    "en": "If necessary, you can edit the car information or",
    "id": "Jika perlu, Anda dapat mengedit informasi mobil atau",
    "ja": "(J)필요한 경우 등록된 자동차 정보를 수정하거나"
  },
  "디바이스를 추가할 수 있습니다.": {
    "ko": "디바이스를 추가할 수 있습니다.",
    "en": "Able to add a device",
    "id": "Dapat menambahkan perangkat",
    "ja": "(J)디바이스를 추가할 수 있습니다."
  },
  "자동차 등록": {
    "ko": "자동차 등록",
    "en": "Add Car",
    "id": "Tambahkan mobil",
    "ja": "(J)자동차 등록"
  },
  "자동차 정보 등록시 기본적으로 자동차 정보는 필수로 입력을 해야 합니다.": {
    "ko": "자동차 정보 등록시 기본적으로 자동차 정보는 필수로 입력을 해야 합니다.",
    "en": "You are required to enter car information for its registration.",
    "id": "Anda diharuskan memasukkan informasi mobil untuk pendaftarannya.",
    "ja": "(J)자동차 정보 등록시 기본적으로 자동차 정보는 필수로 입력을 해야 합니다."
  },
  "임의로 자동차 정보를 생성하는 경우 자동차 정보를 등록하고 이후 실제 정보로 수정할 수 있습니다.": {
    "ko": "임의로 자동차 정보를 생성하는 경우 자동차 정보를 등록하고 이후 실제 정보로 수정할 수 있습니다.",
    "en": "If car information is randomly created, you can add car information and edit later.",
    "id": "Jika informasi mobil dibuat secara acak, Anda dapat menambahkan informasi mobil dan mengedit nanti.",
    "ja": "(J)임의로 자동차 정보를 생성하는 경우 자동차 정보를 등록하고 이후 실제 정보로 수정할 수 있습니다."
  },
  "저장하기": {
    "ko": "저장하기",
    "en": "Save",
    "id": "Menyimpan",
    "ja": "(J)저장하기"
  },
  "제조사": {
    "ko": "제조사",
    "en": "Manufacturer",
    "id": "Pabrikan",
    "ja": "(J)제조사"
  },
  "제조사를 선택하세요.": {
    "ko": "제조사를 선택하세요.",
    "en": "Please select the manufacturer.",
    "id": "Pilih pabrikannya.",
    "ja": "(J)제조사를 선택하세요."
  },
  "차종": {
    "ko": "차종",
    "en": "Vehicle Type",
    "id": "Jenis Kendaraan",
    "ja": "(J)차종"
  },
  "모델을 선택하세요.": {
    "ko": "모델을 선택하세요.",
    "en": "Select a model.",
    "id": "Pilih model.",
    "ja": "(J)모델을 선택하세요."
  },
  "연식을 선택하세요.": {
    "ko": "연식을 선택하세요.",
    "en": "Select model year.",
    "id": "Pilih Model Tahun.",
    "ja": "(J)연식을 선택하세요."
  },
  "모델": {
    "ko": "모델",
    "en": "Model",
    "id": "Model",
    "ja": "(J)모델"
  },
  "세부모델을 선택하세요.": {
    "ko": "세부모델을 선택하세요.",
    "en": "Select a specific model.",
    "id": "Pilih model tertentu.",
    "ja": "(J)세부모델을 선택하세요."
  },
  "연료": {
    "ko": "연료",
    "en": "Fuel",
    "id": "Bahan bakar",
    "ja": "(J)연료"
  },
  "선택하세요.": {
    "ko": "선택하세요.",
    "en": "Select",
    "id": "Pilih",
    "ja": "(J)선택하세요."
  },
  "자동차정보 ": {
    "ko": "자동차 정보",
    "en": "Car Information",
    "id": "Informasi mobil",
    "ja": "(J)자동차정보 "
  },
  "필수": {
    "ko": "필수",
    "en": "Required",
    "id": "Diperlukan",
    "ja": "(J)필수"
  },
  "기업 검색": {
    "ko": "기업 검색",
    "en": "Company Search",
    "id": "Pencarian Perusahaan",
    "ja": "(J)기업 검색"
  },
  "기업명": {
    "ko": "기업명",
    "en": "Company Name",
    "id": "Nama perusahaan",
    "ja": "(J)기업명"
  },
  "사업자등록번호": {
    "ko": "사업자등록번호",
    "en": "Business Registration No.",
    "id": "No. Pendaftaran Bisnis",
    "ja": "(J)사업자등록번호"
  },
  "법인등록번호": {
    "ko": "법인등록번호",
    "en": "Corporation Registration No.",
    "id": "No. Pendaftaran Korporasi",
    "ja": "(J)법인등록번호"
  },
  "기업 코드": {
    "ko": "기업 코드",
    "en": "Company Code",
    "id": "Kode perusahaan",
    "ja": "(J)기업 코드"
  },
  "사업자 등록번호": {
    "ko": "사업자 등록번호",
    "en": "(E)사업자 등록번호",
    "id": "(ID)사업자 등록번호",
    "ja": "(J)사업자 등록번호"
  },
  "법인 등록번호": {
    "ko": "법인 등록번호",
    "en": "(E)법인 등록번호",
    "id": "(ID)법인 등록번호",
    "ja": "(J)법인 등록번호"
  },
  "회원 검색": {
    "ko": "회원 검색",
    "en": "Member Search",
    "id": "Pencarian Anggota",
    "ja": "(J)회원 검색"
  },
  "이름": {
    "ko": "이름",
    "en": "Name",
    "id": "Nama",
    "ja": "(J)이름"
  },
  "전화번호": {
    "ko": "전화번호",
    "en": "Phone No.",
    "id": "No Telepon.",
    "ja": "(J)전화번호"
  },
  "이메일": {
    "ko": "이메일",
    "en": "Email",
    "id": "Surel",
    "ja": "(J)이메일"
  },
  "차량번호": {
    "ko": "차량번호",
    "en": "License Plate No.",
    "id": "No. Plat No.",
    "ja": "(J)차량번호"
  },
  "검색된 결과가 없습니다": {
    "ko": "검색된 결과가 없습니다",
    "en": "No results are found!",
    "id": "Tidak ada hasil yang ditemukan!",
    "ja": "(J)검색된 결과가 없습니다"
  },
  "소유자": {
    "ko": "소유자",
    "en": "Owner",
    "id": "Pemilik",
    "ja": "(J)소유자"
  },
  "기업": {
    "ko": "기업",
    "en": "Company",
    "id": "Perusahaan",
    "ja": "(J)기업"
  },
  "개인": {
    "ko": "개인",
    "en": "Individual",
    "id": "Individu",
    "ja": "(J)개인"
  },
  "검색을 진행해주세요": {
    "ko": "검색을 진행해 주세요",
    "en": "Please search.",
    "id": "Harap cari.",
    "ja": "(J)검색을 진행해주세요"
  },
  "자동차번호": {
    "ko": "자동차 번호",
    "en": "License Plate No.",
    "id": "No. Plat No.",
    "ja": "(J)자동차번호"
  },
  "원격제어이력": {
    "ko": "원격제어이력",
    "en": "(E)원격제어이력",
    "id": "(ID)원격제어이력",
    "ja": "(J)원격제어이력"
  },
  "등록된 제어 이력이 없습니다.": {
    "ko": "등록된 제어 이력이 없습니다.",
    "en": "(E)등록된 제어 이력이 없습니다.",
    "id": "(ID)등록된 제어 이력이 없습니다.",
    "ja": "(J)등록된 제어 이력이 없습니다."
  },
  "구분": {
    "ko": "구분",
    "en": "(E)구분",
    "id": "(ID)구분",
    "ja": "(J)구분"
  },
  "요청자": {
    "ko": "요청자",
    "en": "(E)요청자",
    "id": "(ID)요청자",
    "ja": "(J)요청자"
  },
  "상태": {
    "ko": "상태",
    "en": "Status",
    "id": "Status",
    "ja": "(J)상태"
  },
  "제어항목": {
    "ko": "제어 항목",
    "en": "Control Item",
    "id": "Item kontrol",
    "ja": "(J)제어항목"
  },
  "요청일시": {
    "ko": "요청 일시",
    "en": "Request Date",
    "id": "Tanggal Permintaan",
    "ja": "(J)요청일시"
  },
  "응답일시": {
    "ko": "응답 일시",
    "en": "Response Date",
    "id": "Tanggal respons",
    "ja": "(J)응답일시"
  },
  "해당 기능은 반드시 회원의 동의를 받아 처리해야 합니다.": {
    "ko": "해당 기능은 반드시 회원의 동의를 받아 처리해야 합니다.",
    "en": "(E)해당 기능은 반드시 회원의 동의를 받아 처리해야 합니다.",
    "id": "(ID)해당 기능은 반드시 회원의 동의를 받아 처리해야 합니다.",
    "ja": "(J)해당 기능은 반드시 회원의 동의를 받아 처리해야 합니다."
  },
  "취소": {
    "ko": "취소",
    "en": "Cancel",
    "id": "Membatalkan",
    "ja": "(J)취소"
  },
  "요청하기": {
    "ko": "요청하기",
    "en": "(E)요청하기",
    "id": "(ID)요청하기",
    "ja": "(J)요청하기"
  },
  "원격제어 기능은 긴급상황에서만 사용해야 합니다.": {
    "ko": "원격제어 기능은 긴급상황에서만 사용해야 합니다.",
    "en": "(E)원격제어 기능은 긴급상황에서만 사용해야 합니다.",
    "id": "(ID)원격제어 기능은 긴급상황에서만 사용해야 합니다.",
    "ja": "(J)원격제어 기능은 긴급상황에서만 사용해야 합니다."
  },
  "해당 기능으로 제어 시 반드시 고객의 동의를 받아야 합니다.": {
    "ko": "해당 기능으로 제어 시 반드시 고객의 동의를 받아야 합니다.",
    "en": "(E)해당 기능으로 제어 시 반드시 고객의 동의를 받아야 합니다.",
    "id": "(ID)해당 기능으로 제어 시 반드시 고객의 동의를 받아야 합니다.",
    "ja": "(J)해당 기능으로 제어 시 반드시 고객의 동의를 받아야 합니다."
  },
  "원격으로 {value} 요청이 전송됩니다.": {
    "ko": "원격으로 {value} 요청이 전송됩니다.",
    "en": "(E)원격으로 {value} 요청이 전송됩니다.",
    "id": "(ID)원격으로 {value} 요청이 전송됩니다.",
    "ja": "(J)원격으로 {value} 요청이 전송됩니다."
  },
  "도어 잠금": {
    "ko": "도어 잠금",
    "en": "Door Lock",
    "id": "Kunci pintu",
    "ja": "(J)도어 잠금"
  },
  "도어 잠금 해제": {
    "ko": "도어 잠금 해제",
    "en": "Door Unlock",
    "id": "Membuka kunci pintu",
    "ja": "(J)도어 잠금 해제"
  },
  "현재 원격 제어 중입니다. 잠시만 기다리세요.": {
    "ko": "현재 원격 제어 중입니다. 잠시만 기다리세요.",
    "en": "(E)현재 원격 제어 중입니다. 잠시만 기다리세요.",
    "id": "(ID)현재 원격 제어 중입니다. 잠시만 기다리세요.",
    "ja": "(J)현재 원격 제어 중입니다. 잠시만 기다리세요."
  },
  "원격 도어 제어 기능은 긴급상황에서만 사용해야 합니다.": {
    "ko": "원격 도어 제어 기능은 긴급상황에서만 사용해야 합니다.",
    "en": "The remote control door lock should be used at emergency situations only.",
    "id": "Kunci pintu remote control harus digunakan hanya pada situasi darurat.",
    "ja": "(J)원격 도어 제어 기능은 긴급상황에서만 사용해야 합니다."
  },
  "주차": {
    "ko": "주차",
    "en": "(E)주차",
    "id": "(ID)주차",
    "ja": "(J)주차"
  },
  "후진": {
    "ko": "후진",
    "en": "(E)후진",
    "id": "(ID)후진",
    "ja": "(J)후진"
  },
  "중립": {
    "ko": "중립",
    "en": "(E)중립",
    "id": "(ID)중립",
    "ja": "(J)중립"
  },
  "주행": {
    "ko": "주행",
    "en": "(E)주행",
    "id": "(ID)주행",
    "ja": "(J)주행"
  },
  "열림": {
    "ko": "열림",
    "en": "Open",
    "id": "Membuka",
    "ja": "(J)열림"
  },
  "닫힘": {
    "ko": "닫힘",
    "en": "Close",
    "id": "Menutup",
    "ja": "(J)닫힘"
  },
  "잠김": {
    "ko": "잠김",
    "en": "Lock",
    "id": "Kunci",
    "ja": "(J)잠김"
  },
  "자동차 상태는 인포 모듈을 설치한 경우에만 확인 가능합니다": {
    "ko": "자동차 상태는 인포 모듈을 설치한 경우에만 확인 가능합니다",
    "en": "Unable to check car status unless an INFO module is installed!",
    "id": "Tidak dapat memeriksa status mobil kecuali jika modul info diinstal!",
    "ja": "(J)자동차 상태는 인포 모듈을 설치한 경우에만 확인 가능합니다"
  },
  "업데이트 일시": {
    "ko": "업데이트 일시",
    "en": "Update Date",
    "id": "Tanggal Pembaruan",
    "ja": "(J)업데이트 일시"
  },
  "적산거리": {
    "ko": "적산거리",
    "en": "Odometer",
    "id": "Odometer",
    "ja": "(J)적산거리"
  },
  "시동후 주행시간": {
    "ko": "시동후 주행시간",
    "en": "Travel time after starting the engine",
    "id": "Waktu perjalanan setelah menyalakan mesin",
    "ja": "(J)시동후 주행시간"
  },
  "시동후 주행거리": {
    "ko": "시동후 주행거리",
    "en": "Mileage after starting the engine",
    "id": "Jarak tempuh setelah menyalakan mesin",
    "ja": "(J)시동후 주행거리"
  },
  "배터리상태": {
    "ko": "배터리 상태",
    "en": "Battery Status",
    "id": "Status baterai",
    "ja": "(J)배터리상태"
  },
  "배터리충전률": {
    "ko": "배터리 충전률",
    "en": "Battery Level",
    "id": "Level baterai",
    "ja": "(J)배터리충전률"
  },
  "배터리전압": {
    "ko": "배터리 전압",
    "en": "Battery Voltage",
    "id": "Tegangan baterai",
    "ja": "(J)배터리전압"
  },
  "잔여연료량": {
    "ko": "잔여 연료량",
    "en": "Fuel Remain",
    "id": "Level Bahan Bakar",
    "ja": "(J)잔여연료량"
  },
  "시동상태": {
    "ko": "시동 상태",
    "en": "Ignition Status",
    "id": "Status Mulai",
    "ja": "(J)시동상태"
  },
  "속도": {
    "ko": "속도",
    "en": "Speed",
    "id": "Kecepatan",
    "ja": "(J)속도"
  },
  "기어상태": {
    "ko": "기어 상태",
    "en": "Transmission Status",
    "id": "Status transmisi",
    "ja": "(J)기어상태"
  },
  "운전지수": {
    "ko": "운전 지수",
    "en": "Driving Index",
    "id": "Indeks mengemudi",
    "ja": "(J)운전지수"
  },
  "급가속": {
    "ko": "급가속",
    "en": "Sudden Acceleration",
    "id": "Akselerasi mendadak",
    "ja": "(J)급가속"
  },
  "급감속": {
    "ko": "급감속",
    "en": "Sudden Deceleration",
    "id": "Perlambatan mendadak",
    "ja": "(J)급감속"
  },
  "안전운전점수": {
    "ko": "안전운전 점수",
    "en": "Safety Driving Score",
    "id": "Skor mengemudi keamanan",
    "ja": "(J)안전운전점수"
  },
  "{value} 명": {
    "ko": "{value} 명",
    "en": "{value} persons",
    "id": "{value} orang",
    "ja": "(J){value} 명"
  },
  "누적평균": {
    "ko": "누적 평균",
    "en": "Cumulative Average",
    "id": "Rata -rata kumulatif",
    "ja": "(J)누적평균"
  },
  "냉각수온도": {
    "ko": "냉각수 온도",
    "en": "Coolant Temperature",
    "id": "Suhu pendingin anti-beku",
    "ja": "(J)냉각수온도"
  },
  "엔진오일온도": {
    "ko": "엔진오일 온도",
    "en": "Engine Oil Temperature",
    "id": "Suhu oli mesin",
    "ja": "(J)엔진오일온도"
  },
  "미션오일온도": {
    "ko": "미션오일 온도",
    "en": "Transmission Oil Temperature",
    "id": "Suhu oli transmisi",
    "ja": "(J)미션오일온도"
  },
  "1열 좌측 도어": {
    "ko": "1열 좌측 도어",
    "en": "Front-row Left Door",
    "id": "Pintu kiri barisan depan",
    "ja": "(J)1열 좌측 도어"
  },
  "2열 좌측 도어": {
    "ko": "2열 좌측 도어",
    "en": "Rear-row Left Door",
    "id": "Pintu kiri baris belakang",
    "ja": "(J)2열 좌측 도어"
  },
  "1열 우측 도어": {
    "ko": "1열 우측 도어",
    "en": "Front-row Right Door",
    "id": "Pintu kanan barisan depan",
    "ja": "(J)1열 우측 도어"
  },
  "2열 우측 도어": {
    "ko": "2열 우측 도어",
    "en": "Rear-row Right Door",
    "id": "Pintu kanan belakang",
    "ja": "(J)2열 우측 도어"
  },
  "디바이스 등록": {
    "ko": "디바이스 등록",
    "en": "Add Device",
    "id": "Tambahkan perangkat",
    "ja": "(J)디바이스 등록"
  },
  "디바이스 정보": {
    "ko": "디바이스 정보",
    "en": "Device Information",
    "id": "Informasi perangkat",
    "ja": "(J)디바이스 정보"
  },
  "디바이스 등록 이력": {
    "ko": "디바이스 등록 이력",
    "en": "Device Addition History",
    "id": "Riwayat penambahan perangkat",
    "ja": "(J)디바이스 등록 이력"
  },
  "자동차 상태": {
    "ko": "자동차 상태",
    "en": "Car Status",
    "id": "Status mobil",
    "ja": "(J)자동차 상태"
  },
  "원격제어": {
    "ko": "원격제어",
    "en": "(E)원격제어",
    "id": "(ID)원격제어",
    "ja": "(J)원격제어"
  },
  "월간 리포트": {
    "ko": "월간 리포트",
    "en": "Monthly Report",
    "id": "Laporan bulanan",
    "ja": "(J)월간 리포트"
  },
  "운전자": {
    "ko": "운전자",
    "en": "Driver",
    "id": "Pengemudi",
    "ja": "(J)운전자"
  },
  "월간 리포트가 없습니다.": {
    "ko": "월간 리포트가 없습니다.",
    "en": "Cannot find a monthly report!",
    "id": "Tidak dapat menemukan laporan bulanan!",
    "ja": "(J)월간 리포트가 없습니다."
  },
  "제목": {
    "ko": "제목",
    "en": "Title",
    "id": "Judul",
    "ja": "(J)제목"
  },
  "발행일시": {
    "ko": "발행일",
    "en": "Issue Date",
    "id": "Tanggal pembuatan",
    "ja": "(J)발행일시"
  },
  "발송일시": {
    "ko": "발송일",
    "en": "Date Forwarded",
    "id": "Tanggal diteruskan",
    "ja": "(J)발송일시"
  },
  "등록된 운전자가 없습니다.": {
    "ko": "등록된 운전자가 없습니다.",
    "en": "Cannot find a registered driver!",
    "id": "Tidak dapat menemukan driver terdaftar!",
    "ja": "(J)등록된 운전자가 없습니다."
  },
  "분류": {
    "ko": "분류",
    "en": "Classification",
    "id": "Klasifikasi",
    "ja": "(J)분류"
  },
  "휴대폰번호": {
    "ko": "휴대폰 번호",
    "en": "Mobile No.",
    "id": "Nomor ponsel.",
    "ja": "(J)휴대폰번호"
  },
  "등록일시": {
    "ko": "등록일",
    "en": "Date Registered",
    "id": "Tanggal terdaftar",
    "ja": "(J)등록일시"
  },
  "배차일시": {
    "ko": "배차일시",
    "en": "Date Allocated",
    "id": "Tanggal dialokasikan",
    "ja": "(J)배차일시"
  },
  "설정": {
    "ko": "설정",
    "en": "Settings",
    "id": "Pengaturan",
    "ja": "(J)설정"
  },
  "임시비밀번호 발행": {
    "ko": "임시 비밀번호 발행",
    "en": "Issue Temporary Password",
    "id": "Mengeluarkan kata sandi sementara",
    "ja": "(J)임시비밀번호 발행"
  },
  "현재 설정 보기": {
    "ko": "현재 설정 보기",
    "en": "View Current Settings",
    "id": "Lihat Pengaturan Saat Ini",
    "ja": "(J)현재 설정 보기"
  },
  "디바이스 등록 이력이 없습니다.": {
    "ko": "디바이스 등록 이력이 없습니다.",
    "en": "Cannot find any device addition history!",
    "id": "Tidak dapat menemukan riwayat penambahan perangkat apa pun!",
    "ja": "(J)디바이스 등록 이력이 없습니다."
  },
  "등록": {
    "ko": "등록",
    "en": "Add",
    "id": "Menambahkan",
    "ja": "(J)등록"
  },
  "삭제": {
    "ko": "삭제",
    "en": "Delete",
    "id": "Menghapus",
    "ja": "(J)삭제"
  },
  "삭제일시": {
    "ko": "삭제일시",
    "en": "Date Deleted",
    "id": "Tanggal dihapus",
    "ja": "(J)삭제일시"
  },
  "안내메일발송": {
    "ko": "안내 메일 발송",
    "en": "Send Notification Mail",
    "id": "Kirim surat pemberitahuan",
    "ja": "(J)안내메일발송"
  },
  "상세 정보 확인하기": {
    "ko": "상세 정보 확인하기",
    "en": "Check Details",
    "id": "Periksa detail",
    "ja": "(J)상세 정보 확인하기"
  },
  "시리얼": {
    "ko": "시리얼",
    "en": "Serial",
    "id": "Serial",
    "ja": "(J)시리얼"
  },
  "키배열": {
    "ko": "키 배열",
    "en": "Key Array",
    "id": "Array kunci",
    "ja": "(J)키배열"
  },
  "Wire Position과 Wire Position 사이에 빈 값이 존재합니다.": {
    "ko": "Wire Position과 Wire Position 사이에 빈 값이 존재합니다.",
    "en": "A blank exists between wire positions.",
    "id": "Ada kosong di antara posisi kawat.",
    "ja": "(J)Wire Position과 Wire Position 사이에 빈 값이 존재합니다."
  },
  "번째 Position을 확인해주세요.": {
    "ko": "번째 Position을 확인해 주세요.",
    "en": "Please check the __th position.",
    "id": "Silakan periksa posisi __th.",
    "ja": "(J)번째 Position을 확인해주세요."
  },
  "Wire Position이 없는 버튼이 있습니다.": {
    "ko": "Wire Position이 없는 버튼이 있습니다.",
    "en": "A button without a wire position is found.",
    "id": "Tombol tanpa posisi kawat ditemukan.",
    "ja": "(J)Wire Position이 없는 버튼이 있습니다."
  },
  "번째 버튼을 확인해주세요.": {
    "ko": "번째 버튼을 확인해 주세요.",
    "en": "Please check the __th button.",
    "id": "Silakan periksa tombol __th.",
    "ja": "(J)번째 버튼을 확인해주세요."
  },
  "적어도 한개의 버튼은 있어야 합니다.": {
    "ko": "적어도 한개의 버튼은 있어야 합니다.",
    "en": "At least one button is required.",
    "id": "Setidaknya satu tombol diperlukan.",
    "ja": "(J)적어도 한개의 버튼은 있어야 합니다."
  },
  "버튼과 버튼 사이에 🚫(None) 버튼이 들어갈 수 없습니다.": {
    "ko": "버튼과 버튼 사이에 🚫(None) 버튼이 들어갈 수 없습니다.",
    "en": "‘🚫 (None)’ button cannot be entered into between buttons.",
    "id": "Tombol ‘🚫 (tidak ada)’ tidak dapat dimasukkan ke antara tombol.",
    "ja": "(J)버튼과 버튼 사이에 🚫(None) 버튼이 들어갈 수 없습니다."
  },
  "키배열 및 버튼 이미지가 변경되었습니다.": {
    "ko": "키배열 및 버튼 이미지가 변경되었습니다.",
    "en": "Key array and button images have changed.",
    "id": "Array utama dan gambar tombol telah berubah.",
    "ja": "(J)키배열 및 버튼 이미지가 변경되었습니다."
  },
  "변경": {
    "ko": "변경",
    "en": "Change",
    "id": "Mengubah",
    "ja": "(J)변경"
  },
  "디바이스 정보 수정": {
    "ko": "디바이스 정보 수정",
    "en": "Edit Device Information",
    "id": "Edit informasi perangkat",
    "ja": "(J)디바이스 정보 수정"
  },
  "키배열 및 버튼 이미지": {
    "ko": "키배열 및 버튼 이미지",
    "en": "Key Array and Button Image",
    "id": "Array kunci dan gambar tombol",
    "ja": "(J)키배열 및 버튼 이미지"
  },
  "등록정보": {
    "ko": "등록정보",
    "en": "Registration Information",
    "id": "informasi pendaftaran",
    "ja": "(J)등록정보"
  },
  "이미지 저장": {
    "ko": "이미지 저장",
    "en": "Save Image",
    "id": "Menyimpan gambar",
    "ja": "(J)이미지 저장"
  },
  "펌웨어 정보": {
    "ko": "펌웨어 정보",
    "en": "Firmware Information",
    "id": "Informasi firmware",
    "ja": "(J)펌웨어 정보"
  },
  "펌웨어 정보가 없습니다.": {
    "ko": "펌웨어 정보가 없습니다.",
    "en": "Cannot find firmware information!",
    "id": "Tidak dapat menemukan informasi firmware!",
    "ja": "(J)펌웨어 정보가 없습니다."
  },
  "FBP 정보": {
    "ko": "FBP 정보",
    "en": "(E)FBP 정보",
    "id": "(ID)FBP 정보",
    "ja": "(J)FBP 정보"
  },
  "FBP 정보가 없습니다.": {
    "ko": "FBP 정보가 없습니다.",
    "en": "(E)FBP 정보가 없습니다.",
    "id": "(ID)FBP 정보가 없습니다.",
    "ja": "(J)FBP 정보가 없습니다."
  },
  "디바이스 설정 정보": {
    "ko": "디바이스 설정 정보",
    "en": "Device Setup Information",
    "id": "Informasi Pengaturan Perangkat",
    "ja": "(J)디바이스 설정 정보"
  },
  "디바이스를 등록 해제합니다.": {
    "ko": "디바이스를 등록 해제합니다.",
    "en": "Delist Device",
    "id": "Perangkat delist",
    "ja": "(J)디바이스를 등록 해제합니다."
  },
  "디바이스를 등록 해제하면 해당 자동차에서 등록된 디바이스가 등록해제 처리되고 더이상 관련 기능과 정보를 조회할 수 없게 됩니다.": {
    "ko": "디바이스를 등록 해제하면 해당 자동차에서 등록된 디바이스가 등록해제 처리되고 더이상 관련 기능과 정보를 조회할 수 없게 됩니다.",
    "en": "Once a device is delisted, it is removed from the car program. Then, you cannot search related features and information anymore.",
    "id": "Setelah perangkat dihapus, itu dihapus dari program mobil. Kemudian, Anda tidak dapat mencari fitur dan informasi terkait lagi.",
    "ja": "(J)디바이스를 등록 해제하면 해당 자동차에서 등록된 디바이스가 등록해제 처리되고 더이상 관련 기능과 정보를 조회할 수 없게 됩니다."
  },
  "고객의 자동차에 설치된 디바이스를 임의로 등록해제하는 경우 문제가 될 수 있으니 반드시 확인 후 등록을 해제하세요.": {
    "ko": "고객의 자동차에 설치된 디바이스를 임의로 등록해제하는 경우 문제가 될 수 있으니 반드시 확인 후 등록을 해제하세요.",
    "en": "If a device is randomly removed from the car program, a problem can occur. Ensure to check related matters prior to such removal.",
    "id": "Jika perangkat dihapus secara acak dari program mobil, masalah dapat terjadi. Pastikan untuk memeriksa hal -hal terkait sebelum penghapusan tersebut.",
    "ja": "(J)고객의 자동차에 설치된 디바이스를 임의로 등록해제하는 경우 문제가 될 수 있으니 반드시 확인 후 등록을 해제하세요."
  },
  "디바이스 등록 해제": {
    "ko": "디바이스 등록 해제",
    "en": "Delist Device",
    "id": "Perangkat delist",
    "ja": "(J)디바이스 등록 해제"
  },
  "출고 및 설치정보": {
    "ko": "출고 및 설치정보",
    "en": "Release and Setup Information",
    "id": "Informasi Rilis dan Setup",
    "ja": "(J)출고 및 설치정보"
  },
  "상세한 디바이스 정보는 디바이스 관리에서 Mac, 시리얼을 이용해서 조회후 확인할 수 있습니다.": {
    "ko": "상세한 디바이스 정보는 디바이스 관리에서 Mac, 시리얼을 이용해서 조회후 확인할 수 있습니다.",
    "en": "You can check more information about the device in the DEVICE MANAGEMENT, using MAC and serial number.",
    "id": "Anda dapat memeriksa informasi lebih lanjut tentang perangkat dalam manajemen perangkat, menggunakan Mac dan nomor seri.",
    "ja": "(J)상세한 디바이스 정보는 디바이스 관리에서 Mac, 시리얼을 이용해서 조회후 확인할 수 있습니다."
  },
  "디바이스 등록 해제하기": {
    "ko": "디바이스 등록 해제하기",
    "en": "Delist Device",
    "id": "Perangkat delist",
    "ja": "(J)디바이스 등록 해제하기"
  },
  "정보 수정하기": {
    "ko": "정보 수정하기",
    "en": "Edit Information",
    "id": "Edit informasi",
    "ja": "(J)정보 수정하기"
  },
  "공유일시": {
    "ko": "공유일시",
    "en": "Share Date",
    "id": "Tanggal Saham",
    "ja": "(J)공유일시"
  },
  "사용자 정보": {
    "ko": "사용자 정보",
    "en": "User Information",
    "id": "informasi pengguna",
    "ja": "(J)사용자 정보"
  },
  "사용자가 없습니다.": {
    "ko": "사용자가 없습니다.",
    "en": "Cannot find the user!",
    "id": "Tidak dapat menemukan pengguna!",
    "ja": "(J)사용자가 없습니다."
  },
  "아차키 설정": {
    "ko": "아차키 설정",
    "en": "Achakey Setup",
    "id": "Pengaturan Achakey",
    "ja": "(J)아차키 설정"
  },
  "주행이력 설정": {
    "ko": "주행 이력 설정",
    "en": "Driving Data Setup",
    "id": "Mengemudi Pengaturan Data",
    "ja": "(J)주행이력 설정"
  },
  "오토도어락": {
    "ko": "오토도어락",
    "en": "AUTO Door Lock",
    "id": "Kunci Pintu Otomatis",
    "ja": "(J)오토도어락"
  },
  "잠김동작횟수": {
    "ko": "잠김 동작 횟수",
    "en": "No. of Door Lock Attempts",
    "id": "Jumlah upaya kunci pintu",
    "ja": "(J)잠김동작횟수"
  },
  "잠김동작시간": {
    "ko": "잠김 동작 시간",
    "en": "Door Lock Time",
    "id": "Waktu kunci pintu",
    "ja": "(J)잠김동작시간"
  },
  "잠김감도": {
    "ko": "잠김 감도",
    "en": "Lock Sensitivity",
    "id": "Sensitivitas kunci",
    "ja": "(J)잠김감도"
  },
  "안심모드": {
    "ko": "안심 모드",
    "en": "Safety Mode",
    "id": "Mode keselamatan",
    "ja": "(J)안심모드"
  },
  "열림동작횟수": {
    "ko": "열림 동작 횟수",
    "en": "No. of Door Open Attempts",
    "id": "Jumlah Pintu Terbuka Upaya",
    "ja": "(J)열림동작횟수"
  },
  "열림동작시간": {
    "ko": "열림 동작 시간",
    "en": "Door Opening Time",
    "id": "Waktu pembukaan pintu",
    "ja": "(J)열림동작시간"
  },
  "열림감도": {
    "ko": "열림 감도",
    "en": "Open Sensitivity",
    "id": "Sensitivitas terbuka",
    "ja": "(J)열림감도"
  },
  "주행목적": {
    "ko": "주행 목적",
    "en": "Purpose",
    "id": "Tujuan",
    "ja": "(J)주행목적"
  },
  "주행이력기록여부": {
    "ko": "주행 이력 기록 여부",
    "en": "If driving data are recorded (Y/N)",
    "id": "Jika data mengemudi direkam (Y/T)",
    "ja": "(J)주행이력기록여부"
  },
  "저장주기": {
    "ko": "저장 주기",
    "en": "Saving Internal",
    "id": "Menyelamatkan internal",
    "ja": "(J)저장주기"
  },
  "현재 설정": {
    "ko": "현재 설정",
    "en": "Current Settings",
    "id": "Pengaturan saat ini",
    "ja": "(J)현재 설정"
  },
  "출고처": {
    "ko": "출고처",
    "en": "Released from",
    "id": "Dibebaskan dari",
    "ja": "(J)출고처"
  },
  "디바이스 등록이 완료되었습니다.": {
    "ko": "디바이스 등록이 완료되었습니다.",
    "en": "Successfully registered!",
    "id": "Pendaftaran berhasil!",
    "ja": "(J)디바이스 등록이 완료되었습니다."
  },
  "해당 자동차에 디바이스를 추가 등록할 수 있습니다.": {
    "ko": "해당 자동차에 디바이스를 추가 등록할 수 있습니다.",
    "en": "Able to add a device additionally!",
    "id": "Dapat menambahkan perangkat tambahan!",
    "ja": "(J)해당 자동차에 디바이스를 추가 등록할 수 있습니다."
  },
  "시리얼을 입력하세요": {
    "ko": "시리얼을 입력하세요",
    "en": "Enter the serial No.",
    "id": "Masukkan Serial No.",
    "ja": "(J)시리얼을 입력하세요"
  },
  "조회하기": {
    "ko": "조회하기",
    "en": "Search",
    "id": "Mencari",
    "ja": "(J)조회하기"
  },
  "등록가능여부": {
    "ko": "등록 가능 여부",
    "en": "Can be Added or Not",
    "id": "Dapat ditambahkan atau tidak",
    "ja": "(J)등록가능여부"
  },
  "등록하기": {
    "ko": "등록하기",
    "en": "Add",
    "id": "Menambahkan",
    "ja": "(J)등록하기"
  },
  "자동차정보상세": {
    "ko": "자동차 정보 상세",
    "en": "Car Information Details",
    "id": "Detail Informasi Mobil",
    "ja": "(J)자동차정보상세"
  },
  "자동차정보": {
    "ko": "자동차 정보",
    "en": "Car Information",
    "id": "Informasi mobil",
    "ja": "(J)자동차정보"
  },
  "자동차 일련번호": {
    "ko": "자동차 일련번호",
    "en": "VIN",
    "id": "Vin",
    "ja": "(J)자동차 일련번호"
  },
  "연식": {
    "ko": "연식",
    "en": "Model Year",
    "id": "Tahun model",
    "ja": "(J)연식"
  },
  "세부모델": {
    "ko": "세부 모델",
    "en": "Specific Model",
    "id": "Model spesifik",
    "ja": "(J)세부모델"
  },
  "차량위치": {
    "ko": "차량 위치",
    "en": "Car Location",
    "id": "Lokasi Mobil",
    "ja": "(J)차량위치"
  },
  "지도보기": {
    "ko": "지도 보기",
    "en": "View Map",
    "id": "Lihat peta",
    "ja": "(J)지도보기"
  },
  "ON(활성화)": {
    "ko": "ON(활성화)",
    "en": "ON",
    "id": "PADA",
    "ja": "(J)ON(활성화)"
  },
  "OFF(비활성화)": {
    "ko": "OFF(비활성화)",
    "en": "OFF",
    "id": "MATI",
    "ja": "(J)OFF(비활성화)"
  },
  "안심잠금": {
    "ko": "안심 잠금",
    "en": "Safety Lock",
    "id": "Kunci keamanan",
    "ja": "(J)안심잠금"
  },
  "Y(사용중)": {
    "ko": "Y(사용중)",
    "en": "Y (currently in use)",
    "id": "Y (saat ini digunakan)",
    "ja": "(J)Y(사용중)"
  },
  "N(미사용중)": {
    "ko": "N(미사용중)",
    "en": "N (currently unused)",
    "id": "N (saat ini tidak digunakan)",
    "ja": "(J)N(미사용중)"
  },
  "설치된 디바이스를 통한 제어,주행,공유 이력은 조회하여 확인 할 수 있습니다.": {
    "ko": "설치된 디바이스를 통한 제어,주행,공유 이력은 조회하여 확인 할 수 있습니다.",
    "en": "You are able to search and check Control, Drive and Share history through the installed device.",
    "id": "Anda dapat mencari dan memeriksa kontrol, mengemudi dan berbagi riwayat melalui perangkat yang diinstal.",
    "ja": "(J)설치된 디바이스를 통한 제어,주행,공유 이력은 조회하여 확인 할 수 있습니다."
  },
  "확인이 필요한 이력을 조회하세요.": {
    "ko": "확인이 필요한 이력을 조회하세요.",
    "en": "Please search the history.",
    "id": "Harap cari sejarahnya.",
    "ja": "(J)확인이 필요한 이력을 조회하세요."
  },
  "제어이력조회": {
    "ko": "제어 이력조회",
    "en": "Control History Search",
    "id": "Pencarian Sejarah Kontrol",
    "ja": "(J)제어이력조회"
  },
  "주행이력조회": {
    "ko": "주행 이력조회",
    "en": "Driving History Search",
    "id": "Pencarian riwayat mengemudi",
    "ja": "(J)주행이력조회"
  },
  "공유이력조회": {
    "ko": "공유 이력조회",
    "en": "Share History Search",
    "id": "Bagikan pencarian riwayat",
    "ja": "(J)공유이력조회"
  },
  "운전자정보": {
    "ko": "운전자 정보",
    "en": "Driver Information",
    "id": "Informasi Pengemudi",
    "ja": "(J)운전자정보"
  },
  "사용자 정보 확인하기": {
    "ko": "사용자 정보 확인하기",
    "en": "Check User Information",
    "id": "Periksa informasi pengguna",
    "ja": "(J)사용자 정보 확인하기"
  },
  "등록된 자동차정보가 없습니다.": {
    "ko": "등록된 자동차정보가 없습니다.",
    "en": "Cannot find the car information!",
    "id": "Tidak dapat menemukan informasi mobil!",
    "ja": "(J)등록된 자동차정보가 없습니다."
  },
  "일련번호": {
    "ko": "일련번호",
    "en": "VIN",
    "id": "Vin",
    "ja": "(J)일련번호"
  },
  "디바이스": {
    "ko": "디바이스",
    "en": "Device",
    "id": "Perangkat",
    "ja": "(J)디바이스"
  },
  "부가기능": {
    "ko": "부가기능",
    "en": "Options",
    "id": "Pilihan",
    "ja": "(J)부가기능"
  },
  "ACHAKEY 설치 여부": {
    "ko": "ACHAKEY 설치 여부",
    "en": "Achakey Installed or Not",
    "id": "Achakey terpasang atau tidak",
    "ja": "(J)ACHAKEY 설치 여부"
  },
  "INFO 설치 여부": {
    "ko": "INFO 설치 여부",
    "en": "INFO Installed or Not",
    "id": "Info yang diinstal atau tidak",
    "ja": "(J)INFO 설치 여부"
  },
  "CONNECTED 설치 여부": {
    "ko": "CONNECTED 설치 여부",
    "en": "CONNECTED Installed or Not",
    "id": "Terhubung terpasang atau tidak",
    "ja": "(J)CONNECTED 설치 여부"
  },
  "오토도어락 활성화 여부": {
    "ko": "오토도어락 활성화 여부",
    "en": "AUTO Door Lock ON or OFF",
    "id": "Kunci pintu otomatis menyala atau mati",
    "ja": "(J)오토도어락 활성화 여부"
  },
  "발렛키 사용 여부": {
    "ko": "발렛키 사용 여부",
    "en": "Valet Key Used or Not",
    "id": "Kunci valet digunakan atau tidak",
    "ja": "(J)발렛키 사용 여부"
  },
  "자동차 이관(To)": {
    "ko": "자동차 이관(To)",
    "en": "(E)자동차 이관(To)",
    "id": "(ID)자동차 이관(To)",
    "ja": "(J)자동차 이관(To)"
  },
  "변경할 기업": {
    "ko": "변경할 기업",
    "en": "(E)변경할 기업",
    "id": "(ID)변경할 기업",
    "ja": "(J)변경할 기업"
  },
  "변경할 HOST": {
    "ko": "변경할 HOST",
    "en": "(E)변경할 HOST",
    "id": "(ID)변경할 HOST",
    "ja": "(J)변경할 HOST"
  },
  "저장": {
    "ko": "저장",
    "en": "Save",
    "id": "Menyimpan",
    "ja": "(J)저장"
  },
  "자동차정보가 없습니다.": {
    "ko": "자동차정보가 없습니다.",
    "en": "(E)자동차정보가 없습니다.",
    "id": "(ID)자동차정보가 없습니다.",
    "ja": "(J)자동차정보가 없습니다."
  },
  "사용자": {
    "ko": "사용자",
    "en": "User",
    "id": "Pengguna",
    "ja": "(J)사용자"
  },
  "기업코드": {
    "ko": "기업 코드",
    "en": "Company Code",
    "id": "Kode perusahaan",
    "ja": "(J)기업코드"
  },
  "일반": {
    "ko": "일반",
    "en": "(E)일반",
    "id": "(ID)일반",
    "ja": "(J)일반"
  },
  "이름, 또는 기업코드": {
    "ko": "이름, 또는 기업코드",
    "en": "(E)이름, 또는 기업코드",
    "id": "(ID)이름, 또는 기업코드",
    "ja": "(J)이름, 또는 기업코드"
  },
  "검색": {
    "ko": "검색",
    "en": "Search",
    "id": "Mencari",
    "ja": "(J)검색"
  },
  "회원이름": {
    "ko": "회원 이름",
    "en": "Member Name",
    "id": "Nama anggota",
    "ja": "(J)회원이름"
  },
  "회원": {
    "ko": "회원",
    "en": "Member",
    "id": "Anggota",
    "ja": "(J)회원"
  },
  "위치": {
    "ko": "위치",
    "en": "Location",
    "id": "Lokasi",
    "ja": "(J)위치"
  },
  "반납일시": {
    "ko": "반납일시",
    "en": "Date and Time of Return",
    "id": "Tanggal dan waktu pengembalian",
    "ja": "(J)반납일시"
  },
  "이용시간": {
    "ko": "이용 시간",
    "en": "Time Used",
    "id": "Waktu digunakan",
    "ja": "(J)이용시간"
  },
  "운행정보": {
    "ko": "운행 정보",
    "en": "Driving Information",
    "id": "Informasi mengemudi",
    "ja": "(J)운행정보"
  },
  "운행중": {
    "ko": "운행 중",
    "en": "Driving…",
    "id": "Menyetir…",
    "ja": "(J)운행중"
  },
  "{value}회 주행": {
    "ko": "{value}회 주행",
    "en": "Used {value} times",
    "id": "Digunakan {value} kali",
    "ja": "(J){value}회 주행"
  },
  "위치정보": {
    "ko": "위치 정보",
    "en": "Location Information",
    "id": "informasi lokasi",
    "ja": "(J)위치정보"
  },
  "안전점수": {
    "ko": "안전 점수",
    "en": "Safety Scores",
    "id": "Skor keamanan",
    "ja": "(J)안전점수"
  },
  "속도 (km)": {
    "ko": "속도 (km)",
    "en": "(E)속도 (km)",
    "id": "(ID)속도 (km)",
    "ja": "(J)속도 (km)"
  },
  "RPM (회전수)": {
    "ko": "RPM (회전수)",
    "en": "(E)RPM (회전수)",
    "id": "(ID)RPM (회전수)",
    "ja": "(J)RPM (회전수)"
  },
  "잔여연료량 (L)": {
    "ko": "잔여연료량 (L)",
    "en": "(E)잔여연료량 (L)",
    "id": "(ID)잔여연료량 (L)",
    "ja": "(J)잔여연료량 (L)"
  },
  "배터리 전압 (V)": {
    "ko": "배터리 전압 (V)",
    "en": "(E)배터리 전압 (V)",
    "id": "(ID)배터리 전압 (V)",
    "ja": "(J)배터리 전압 (V)"
  },
  "엔진오일 온도 (°C)": {
    "ko": "엔진오일 온도 (°C)",
    "en": "(E)엔진오일 온도 (°C)",
    "id": "(ID)엔진오일 온도 (°C)",
    "ja": "(J)엔진오일 온도 (°C)"
  },
  "냉각수 온도 (°C)": {
    "ko": "냉각수 온도 (°C)",
    "en": "(E)냉각수 온도 (°C)",
    "id": "(ID)냉각수 온도 (°C)",
    "ja": "(J)냉각수 온도 (°C)"
  },
  "출발지": {
    "ko": "출발지",
    "en": "Departure",
    "id": "Keberangkatan",
    "ja": "(J)출발지"
  },
  "도착지": {
    "ko": "도착지",
    "en": "Arrival",
    "id": "Kedatangan",
    "ja": "(J)도착지"
  },
  "위도": {
    "ko": "위도",
    "en": "Latitude",
    "id": "Garis Lintang",
    "ja": "(J)위도"
  },
  "경도": {
    "ko": "경도",
    "en": "Longitude",
    "id": "Garis bujur",
    "ja": "(J)경도"
  },
  "시간": {
    "ko": "시간",
    "en": "Time",
    "id": "Waktu",
    "ja": "(J)시간"
  },
  "수집방법": {
    "ko": "수집방법",
    "en": "(E)수집방법",
    "id": "(ID)수집방법",
    "ja": "(J)수집방법"
  },
  "수집요청인": {
    "ko": "수집요청인",
    "en": "(E)수집요청인",
    "id": "(ID)수집요청인",
    "ja": "(J)수집요청인"
  },
  "출발일시": {
    "ko": "출발 일시",
    "en": "Departure Date and Time",
    "id": "Tanggal dan waktu keberangkatan",
    "ja": "(J)출발일시"
  },
  "도착일시": {
    "ko": "도착 일시",
    "en": "Arrival Date and Time",
    "id": "Tanggal dan Waktu Kedatangan",
    "ja": "(J)도착일시"
  },
  "거리": {
    "ko": "거리",
    "en": "(E)거리",
    "id": "(ID)거리",
    "ja": "(J)거리"
  },
  "주행경로": {
    "ko": "주행 경로",
    "en": "Driving Path",
    "id": "Jalur mengemudi",
    "ja": "(J)주행경로"
  },
  "시작시간": {
    "ko": "시작 시간",
    "en": "Start Time",
    "id": "Waktu mulai",
    "ja": "(J)시작시간"
  },
  "종료시간": {
    "ko": "종료 시간",
    "en": "End Time",
    "id": "Akhir waktu",
    "ja": "(J)종료시간"
  },
  "시도횟수": {
    "ko": "시도 횟수",
    "en": "No. of Attempts",
    "id": "Jumlah upaya",
    "ja": "(J)시도횟수"
  },
  "기준RSSI": {
    "ko": "기준 RSSI",
    "en": "Base RSSI",
    "id": "Basis rssi",
    "ja": "(J)기준RSSI"
  },
  "작동RSSI": {
    "ko": "작동 RSSI",
    "en": "Operating RSSI",
    "id": "Operasi RSSI",
    "ja": "(J)작동RSSI"
  },
  "작동data": {
    "ko": "작동 Data",
    "en": "Operating Data",
    "id": "Data operasi",
    "ja": "(J)작동data"
  },
  "엔드노드응답": {
    "ko": "엔드 노드 응답",
    "en": "End Node Response",
    "id": "Akhiri respons simpul",
    "ja": "(J)엔드노드응답"
  },
  "권한이동시간": {
    "ko": "권한 이동 시간",
    "en": "Permission Moving Time",
    "id": "Waktu pemindahan izin",
    "ja": "(J)권한이동시간"
  },
  "제어이력 상세정보": {
    "ko": "제어 이력 상세정보",
    "en": "Detailed Control History Information",
    "id": "Informasi Riwayat Kontrol Detail",
    "ja": "(J)제어이력 상세정보"
  },
  "차량일련번호": {
    "ko": "차량 일련번호",
    "en": "VIN",
    "id": "Vin",
    "ja": "(J)차량일련번호"
  },
  "실행항목": {
    "ko": "실행 항목",
    "en": "Execution Items",
    "id": "Item eksekusi",
    "ja": "(J)실행항목"
  },
  "에러코드": {
    "ko": "에러 코드",
    "en": "Error Code",
    "id": "Kode kesalahan",
    "ja": "(J)에러코드"
  },
  "검색어": {
    "ko": "검색어",
    "en": "Keyword",
    "id": "Kata kunci",
    "ja": "(J)검색어"
  },
  "입력하세요.": {
    "ko": "입력하세요.",
    "en": "Please enter.",
    "id": "Harap masuk.",
    "ja": "(J)입력하세요."
  },
  "검색어를 입력해주세요.": {
    "ko": "검색어를 입력해 주세요.",
    "en": "Please enter a keyword.",
    "id": "Harap masukkan kata kunci.",
    "ja": "(J)검색어를 입력해주세요."
  },
  "목록 수": {
    "ko": "목록 수",
    "en": "(E)목록 수",
    "id": "(ID)목록 수",
    "ja": "(J)목록 수"
  },
  "전체 항목": {
    "ko": "전체 항목",
    "en": "(E)전체 항목",
    "id": "(ID)전체 항목",
    "ja": "(J)전체 항목"
  },
  "제어항목을 선택해주세요.": {
    "ko": "제어항목을 선택해주세요.",
    "en": "(E)제어항목을 선택해주세요.",
    "id": "(ID)제어항목을 선택해주세요.",
    "ja": "(J)제어항목을 선택해주세요."
  },
  "Error Code / Debug Log 를 입력하세요.": {
    "ko": "Error Code / Debug Log 를 입력하세요.",
    "en": "(E)Error Code / Debug Log 를 입력하세요.",
    "id": "(ID)Error Code / Debug Log 를 입력하세요.",
    "ja": "(J)Error Code / Debug Log 를 입력하세요."
  },
  "내용": {
    "ko": "내용",
    "en": "(E)내용",
    "id": "(ID)내용",
    "ja": "(J)내용"
  },
  "알림일시": {
    "ko": "알림일시",
    "en": "(E)알림일시",
    "id": "(ID)알림일시",
    "ja": "(J)알림일시"
  },
  "가입": {
    "ko": "가입",
    "en": "Join",
    "id": "Bergabung",
    "ja": "(J)가입"
  },
  "탈퇴": {
    "ko": "탈퇴",
    "en": "Withdraw Membership",
    "id": "Tarik keanggotaan",
    "ja": "(J)탈퇴"
  },
  "엑셀 다운로드": {
    "ko": "엑셀 다운로드",
    "en": "Download Excel",
    "id": "Unduh Excel",
    "ja": "(J)엑셀 다운로드"
  },
  "날짜": {
    "ko": "날짜",
    "en": "Date",
    "id": "Tanggal",
    "ja": "(J)날짜"
  },
  "DAD 목록이 존재하지 않습니다.": {
    "ko": "DAD 목록이 존재하지 않습니다.",
    "en": "Cannot find the DAD list!",
    "id": "Tidak dapat menemukan daftar ayah!",
    "ja": "(J)DAD 목록이 존재하지 않습니다."
  },
  "API 이력 통계": {
    "ko": "API 이력 통계",
    "en": "API History Statics",
    "id": "Statika Sejarah API",
    "ja": "(J)API 이력 통계"
  },
  "API 이력이 존재하지 않습니다.": {
    "ko": "API 이력이 존재하지 않습니다.",
    "en": "Cannot find API history!",
    "id": "Tidak dapat menemukan sejarah API!",
    "ja": "(J)API 이력이 존재하지 않습니다."
  },
  "검색된 결과가 없습니다.": {
    "ko": "검색된 결과가 없습니다.",
    "en": "No results are found!",
    "id": "Tidak ada hasil yang ditemukan!",
    "ja": "(J)검색된 결과가 없습니다."
  },
  "MAU 목록이 존재하지 않습니다.": {
    "ko": "MAU 목록이 존재하지 않습니다.",
    "en": "Cannot find any MAU list!",
    "id": "Tidak dapat menemukan daftar MAU!",
    "ja": "(J)MAU 목록이 존재하지 않습니다."
  },
  "DAU 목록이 존재하지 않습니다.": {
    "ko": "DAU 목록이 존재하지 않습니다.",
    "en": "Cannot find any DAU list!",
    "id": "Tidak dapat menemukan daftar dau!",
    "ja": "(J)DAU 목록이 존재하지 않습니다."
  },
  "자동차": {
    "ko": "자동차",
    "en": "Car",
    "id": "Mobil",
    "ja": "(J)자동차"
  },
  "아차키": {
    "ko": "아차키",
    "en": "Achakey",
    "id": "Achakey",
    "ja": "(J)아차키"
  },
  "인포": {
    "ko": "인포",
    "en": "INFO",
    "id": "Info",
    "ja": "(J)인포"
  },
  "커넥티드": {
    "ko": "커넥티드",
    "en": "Connected",
    "id": "Terhubung",
    "ja": "(J)커넥티드"
  },
  "전체": {
    "ko": "전체",
    "en": "All",
    "id": "Semua",
    "ja": "(J)전체"
  },
  "약관 등록": {
    "ko": "약관 등록",
    "en": "Add Terms of Use",
    "id": "Tambahkan Ketentuan Penggunaan",
    "ja": "(J)약관 등록"
  },
  "조회": {
    "ko": "조회",
    "en": "Search",
    "id": "Mencari",
    "ja": "(J)조회"
  },
  "언어": {
    "ko": "언어",
    "en": "Language",
    "id": "Bahasa",
    "ja": "(J)언어"
  },
  "배포일시": {
    "ko": "배포 일시",
    "en": "Date Distributed",
    "id": "Tanggal didistribusikan",
    "ja": "(J)배포일시"
  },
  "작성중인 내용이 있습니다.": {
    "ko": "작성중인 내용이 있습니다.",
    "en": "A text which is being written is found.",
    "id": "Teks yang sedang ditulis ditemukan.",
    "ja": "(J)작성중인 내용이 있습니다."
  },
  "뒤로가기 시 작성중인 내용이 삭제됩니다.": {
    "ko": "뒤로가기 시 작성중인 내용이 삭제됩니다.",
    "en": "Once the PREVIOUS button is clicked, the text being written will be deleted.",
    "id": "Setelah tombol sebelumnya diklik, teks yang ditulis akan dihapus.",
    "ja": "(J)뒤로가기 시 작성중인 내용이 삭제됩니다."
  },
  "작성을 취소하시겠어요?": {
    "ko": "작성을 취소하시겠어요?",
    "en": "Do you want to cancel?",
    "id": "Apakah Anda ingin membatalkan?",
    "ja": "(J)작성을 취소하시겠어요?"
  },
  "지원언어는 한 가지 이상 선택해야합니다.": {
    "ko": "지원언어는 한 가지 이상 선택해야합니다.",
    "en": "Select at least one language.",
    "id": "Pilih setidaknya satu bahasa.",
    "ja": "(J)지원언어는 한 가지 이상 선택해야합니다."
  },
  "선택한 언어의 입력 상태를 확인해주세요.": {
    "ko": "선택한 언어의 입력 상태를 확인해 주세요.",
    "en": "Check the language you have chosen.",
    "id": "Periksa bahasa yang telah Anda pilih.",
    "ja": "(J)선택한 언어의 입력 상태를 확인해주세요."
  },
  "이용약관이 정상적으로 등록되었습니다.": {
    "ko": "이용약관이 정상적으로 등록되었습니다.",
    "en": "The Terms of Use is properly listed.",
    "id": "Ketentuan penggunaan terdaftar dengan benar.",
    "ja": "(J)이용약관이 정상적으로 등록되었습니다."
  },
  "이용약관 목록에서 확인하세요.": {
    "ko": "이용약관 목록에서 확인하세요.",
    "en": "Check the list of the terms of use.",
    "id": "Periksa daftar Ketentuan Penggunaan.",
    "ja": "(J)이용약관 목록에서 확인하세요."
  },
  "이용약관 등록하기": {
    "ko": "이용약관 등록하기",
    "en": "Add Terms of Use",
    "id": "Tambahkan Ketentuan Penggunaan",
    "ja": "(J)이용약관 등록하기"
  },
  "시작날짜": {
    "ko": "시작 날짜",
    "en": "Start Date",
    "id": "Mulai tanggal",
    "ja": "(J)시작날짜"
  },
  "지원언어": {
    "ko": "지원 언어",
    "en": "Language",
    "id": "Bahasa",
    "ja": "(J)지원언어"
  },
  "본문": {
    "ko": "본문",
    "en": "Text",
    "id": "Teks",
    "ja": "(J)본문"
  },
  "배포일시가 지난 글입니다.": {
    "ko": "배포일시가 지난 글입니다.",
    "en": "A writing whose distribution period has expired",
    "id": "Tulisan yang periode distribusinya telah kedaluwarsa",
    "ja": "(J)배포일시가 지난 글입니다."
  },
  "배포일시가 지난 글은 수정할 수 없습니다.": {
    "ko": "배포일시가 지난 글은 수정할 수 없습니다.",
    "en": "Cannot edit an expired writing!",
    "id": "Tidak dapat mengedit tulisan yang kedaluwarsa!",
    "ja": "(J)배포일시가 지난 글은 수정할 수 없습니다."
  },
  "약관이 정상적으로 수정되었습니다.": {
    "ko": "약관이 정상적으로 수정되었습니다.",
    "en": "Terms of Use is successfully edited.",
    "id": "Ketentuan Penggunaan berhasil diedit.",
    "ja": "(J)약관이 정상적으로 수정되었습니다."
  },
  "약관을 삭제합니다.": {
    "ko": "약관을 삭제합니다.",
    "en": "Deleting the Terms of Use",
    "id": "Menghapus Ketentuan Penggunaan",
    "ja": "(J)약관을 삭제합니다."
  },
  "약관을 삭제하면 이용약관 페이지에서 노출되지 않습니다.": {
    "ko": "약관을 삭제하면 이용약관 페이지에서 노출되지 않습니다.",
    "en": "Once deleted, the Terms of Use will not appear on the page.",
    "id": "Setelah dihapus, Ketentuan Penggunaan tidak akan muncul di halaman.",
    "ja": "(J)약관을 삭제하면 이용약관 페이지에서 노출되지 않습니다."
  },
  "해당 약관을 삭제하시겠어요?": {
    "ko": "해당 약관을 삭제하시겠어요?",
    "en": "Do you want to delete the Terms of Use?",
    "id": "Apakah Anda ingin menghapus ketentuan penggunaan?",
    "ja": "(J)해당 약관을 삭제하시겠어요?"
  },
  "수정하기": {
    "ko": "수정하기",
    "en": "Edit",
    "id": "Edit",
    "ja": "(J)수정하기"
  },
  "배포완료": {
    "ko": "배포 완료",
    "en": "Distribution completed!",
    "id": "Distribusi selesai!",
    "ja": "(J)배포완료"
  },
  "이용약관": {
    "ko": "이용약관",
    "en": "Terms of Use",
    "id": "Syarat Penggunaan",
    "ja": "(J)이용약관"
  },
  "불러올 수 있는 글이 없습니다.": {
    "ko": "불러올 수 있는 글이 없습니다.",
    "en": "Cannot find any writing to load!",
    "id": "Tidak dapat menemukan tulisan apa pun untuk dimuat!",
    "ja": "(J)불러올 수 있는 글이 없습니다."
  },
  "이전글": {
    "ko": "이전 글",
    "en": "Previous",
    "id": "Sebelumnya",
    "ja": "(J)이전글"
  },
  "다음글": {
    "ko": "다음 글",
    "en": "Next",
    "id": "Berikutnya",
    "ja": "(J)다음글"
  },
  "지원": {
    "ko": "지원",
    "en": "(E)지원",
    "id": "(ID)지원",
    "ja": "(J)지원"
  },
  "지원 불가": {
    "ko": "지원 불가",
    "en": "(E)지원 불가",
    "id": "(ID)지원 불가",
    "ja": "(J)지원 불가"
  },
  "제조사 추가": {
    "ko": "제조사 추가",
    "en": "(E)제조사 추가",
    "id": "(ID)제조사 추가",
    "ja": "(J)제조사 추가"
  },
  "차종 추가": {
    "ko": "차종 추가",
    "en": "(E)차종 추가",
    "id": "(ID)차종 추가",
    "ja": "(J)차종 추가"
  },
  "생산지": {
    "ko": "생산지",
    "en": "(E)생산지",
    "id": "(ID)생산지",
    "ja": "(J)생산지"
  },
  "지원여부": {
    "ko": "지원여부",
    "en": "(E)지원여부",
    "id": "(ID)지원여부",
    "ja": "(J)지원여부"
  },
  "차종 수정": {
    "ko": "차종 수정",
    "en": "(E)차종 수정",
    "id": "(ID)차종 수정",
    "ja": "(J)차종 수정"
  },
  "제조사 수정": {
    "ko": "제조사 수정",
    "en": "(E)제조사 수정",
    "id": "(ID)제조사 수정",
    "ja": "(J)제조사 수정"
  },
  "질문": {
    "ko": "질문",
    "en": "Question",
    "id": "Pertanyaan",
    "ja": "(J)질문"
  },
  "제목을 입력해주세요.": {
    "ko": "제목을 입력해 주세요.",
    "en": "Please enter the title.",
    "id": "Harap masukkan judulnya.",
    "ja": "(J)제목을 입력해주세요."
  },
  "자주묻는질문이 정상적으로 등록되었습니다.": {
    "ko": "자주 묻는 질문이 정상적으로 등록되었습니다.",
    "en": "FAQs are normally registered.",
    "id": "FAQ biasanya terdaftar.",
    "ja": "(J)자주묻는질문이 정상적으로 등록되었습니다."
  },
  "자주묻는질문 목록에서 확인하세요.": {
    "ko": "자주 묻는 질문 목록에서 확인하세요.",
    "en": "Please check on the FAQ list.",
    "id": "Silakan periksa daftar FAQ.",
    "ja": "(J)자주묻는질문 목록에서 확인하세요."
  },
  "자주묻는질문 등록": {
    "ko": "자주 묻는 질문 등록",
    "en": "Add content to FAQs",
    "id": "Tambahkan Konten ke FAQ",
    "ja": "(J)자주묻는질문 등록"
  },
  "답변": {
    "ko": "답변",
    "en": "Answer",
    "id": "Menjawab",
    "ja": "(J)답변"
  },
  "FAQ 등록": {
    "ko": "FAQ 등록",
    "en": "Add content to FAQs",
    "id": "Tambahkan Konten ke FAQ",
    "ja": "(J)FAQ 등록"
  },
  "등록된 파트너정보가 없습니다.": {
    "ko": "등록된 파트너정보가 없습니다.",
    "en": "Cannot find any partner information!",
    "id": "Tidak dapat menemukan informasi mitra apa pun!",
    "ja": "(J)등록된 파트너정보가 없습니다."
  },
  "지원 언어를 확인해주세요.": {
    "ko": "지원 언어를 확인해 주세요.",
    "en": "Please check the language.",
    "id": "Silakan periksa bahasanya.",
    "ja": "(J)지원 언어를 확인해주세요."
  },
  "다음 글이 없습니다.": {
    "ko": "다음 글이 없습니다.",
    "en": "Cannot find the next writing!",
    "id": "Tidak dapat menemukan tulisan berikutnya!",
    "ja": "(J)다음 글이 없습니다."
  },
  "자주묻는질문이 정상적으로 수정되었습니다.": {
    "ko": "자주 묻는 질문이 정상적으로 수정되었습니다.",
    "en": "FAQs were successfully edited.",
    "id": "FAQ berhasil diedit.",
    "ja": "(J)자주묻는질문이 정상적으로 수정되었습니다."
  },
  "자주묻는질문을 삭제합니다.": {
    "ko": "자주 묻는 질문을 삭제합니다.",
    "en": "Delete FAQs.",
    "id": "Hapus FAQ.",
    "ja": "(J)자주묻는질문을 삭제합니다."
  },
  "자주묻는질문을 삭제하면 자주묻는질문 페이지에서 노출되지 않습니다.": {
    "ko": "자주 묻는 질문을 삭제하면 자주묻는질문 페이지에서 노출되지 않습니다.",
    "en": "Once deleted, FAQs will not appear on the page.",
    "id": "Setelah dihapus, FAQ tidak akan muncul di halaman.",
    "ja": "(J)자주묻는질문을 삭제하면 자주묻는질문 페이지에서 노출되지 않습니다."
  },
  "해당 글을 삭제하시겠어요?": {
    "ko": "해당 글을 삭제하시겠어요?",
    "en": "Do you want to delete the writing?",
    "id": "Apakah Anda ingin menghapus tulisannya?",
    "ja": "(J)해당 글을 삭제하시겠어요?"
  },
  "자주묻는질문 상세": {
    "ko": "자주 묻는 질문 상세",
    "en": "FAQ Details",
    "id": "Detail FAQ",
    "ja": "(J)자주묻는질문 상세"
  },
  "공지사항 게시글 작성이 정상적으로 완료되었습니다.": {
    "ko": "공지사항 게시글 작성이 정상적으로 완료되었습니다.",
    "en": "Writing was successfully posted on the Notices.",
    "id": "Menulis berhasil diposting pada pemberitahuan.",
    "ja": "(J)공지사항 게시글 작성이 정상적으로 완료되었습니다."
  },
  "서비스관리 공지사항 목록에서 확인하세요.": {
    "ko": "서비스관리 공지사항 목록에서 확인하세요.",
    "en": "Please check on the list of Notices – Service Management.",
    "id": "Silakan periksa daftar pemberitahuan - Manajemen Layanan.",
    "ja": "(J)서비스관리 공지사항 목록에서 확인하세요."
  },
  "공지사항 게시글 작성": {
    "ko": "공지사항 게시글 작성",
    "en": "Write Post (posted on the Notices)",
    "id": "Tulis Posting (Diposting di Pemberitahuan)",
    "ja": "(J)공지사항 게시글 작성"
  },
  "게시글 작성": {
    "ko": "게시글 작성",
    "en": "Write Post",
    "id": "Tulis posting",
    "ja": "(J)게시글 작성"
  },
  "게시글을 삭제합니다.": {
    "ko": "게시글을 삭제합니다.",
    "en": "Deleting the post…",
    "id": "Menghapus postingan…",
    "ja": "(J)게시글을 삭제합니다."
  },
  "게시글을 삭제하면 공지사항 페이지에서 노출되지 않습니다.": {
    "ko": "게시글을 삭제하면 공지사항 페이지에서 노출되지 않습니다.",
    "en": "Once deleted, the notice will not appear on the Notices.",
    "id": "Setelah dihapus, pemberitahuan tidak akan muncul pada pemberitahuan.",
    "ja": "(J)게시글을 삭제하면 공지사항 페이지에서 노출되지 않습니다."
  },
  "게시글을 삭제하시겠어요?": {
    "ko": "게시글을 삭제하시겠어요?",
    "en": "Do you want to delete the post?",
    "id": "Apakah Anda ingin menghapus posting?",
    "ja": "(J)게시글을 삭제하시겠어요?"
  },
  "공지사항 게시글": {
    "ko": "공지사항 게시글",
    "en": "Post on the Notices",
    "id": "Posting di pemberitahuan",
    "ja": "(J)공지사항 게시글"
  },
  "요약": {
    "ko": "요약",
    "en": "Summary",
    "id": "Ringkasan",
    "ja": "(J)요약"
  },
  "뉴스게시글 목록에서 확인하세요.": {
    "ko": "뉴스 게시글 목록에서 확인하세요.",
    "en": "Please check on the list of posts on the News.",
    "id": "Silakan periksa daftar posting di berita.",
    "ja": "(J)뉴스게시글 목록에서 확인하세요."
  },
  "뉴스 등록": {
    "ko": "뉴스 등록",
    "en": "Add News",
    "id": "Tambahkan berita",
    "ja": "(J)뉴스 등록"
  },
  "뉴스게시글이 정상적으로 수정되었습니다.": {
    "ko": "뉴스 게시글이 정상적으로 수정되었습니다.",
    "en": "The posts on the News are successfully edited.",
    "id": "Posting di berita berhasil diedit.",
    "ja": "(J)뉴스게시글이 정상적으로 수정되었습니다."
  },
  "뉴스게시글을 삭제합니다.": {
    "ko": "뉴스 게시글을 삭제합니다.",
    "en": "Deleting posts on the News",
    "id": "Menghapus posting di berita",
    "ja": "(J)뉴스게시글을 삭제합니다."
  },
  "뉴스게시글을 삭제하면 뉴스게시판 페이지에서 노출되지 않습니다.": {
    "ko": "뉴스 게시글을 삭제하면 뉴스 게시판 페이지에서 노출되지 않습니다.",
    "en": "Once deleted, the posts will not appear on the News.",
    "id": "Setelah dihapus, posting tidak akan muncul di berita.",
    "ja": "(J)뉴스게시글을 삭제하면 뉴스게시판 페이지에서 노출되지 않습니다."
  },
  "해당 게시글을 삭제하시겠어요?": {
    "ko": "해당 게시글을 삭제하시겠어요?",
    "en": "Do you want to delete?",
    "id": "Apakah Anda ingin menghapus?",
    "ja": "(J)해당 게시글을 삭제하시겠어요?"
  },
  "뉴스게시글 정보": {
    "ko": "뉴스 게시글 정보",
    "en": "Posts on the News",
    "id": "Posting di berita",
    "ja": "(J)뉴스게시글 정보"
  },
  "사용메뉴얼이 등록되었습니다.": {
    "ko": "사용 메뉴얼이 등록되었습니다.",
    "en": "User Manual has been added.",
    "id": "Panduan Pengguna telah ditambahkan.",
    "ja": "(J)사용메뉴얼이 등록되었습니다."
  },
  "등록된 정보는 목록에서 확인하세요.": {
    "ko": "등록된 정보는 목록에서 확인하세요.",
    "en": "Please check on the list.",
    "id": "Silakan periksa daftarnya.",
    "ja": "(J)등록된 정보는 목록에서 확인하세요."
  },
  "사용메뉴얼 등록하기": {
    "ko": "사용 메뉴얼 등록하기",
    "en": "Add User Manual",
    "id": "Tambahkan manual pengguna",
    "ja": "(J)사용메뉴얼 등록하기"
  },
  "표기할 언어별로 파일을 등록해야 합니다.": {
    "ko": "표기할 언어별로 파일을 등록해야 합니다.",
    "en": "Files should be registered by language.",
    "id": "File harus didaftarkan oleh bahasa.",
    "ja": "(J)표기할 언어별로 파일을 등록해야 합니다."
  },
  "언어별 파일이 등록되지 않은 경우 KR이 기본으로 표기됩니다.": {
    "ko": "언어별 파일이 등록되지 않은 경우 KR이 기본으로 표기됩니다.",
    "en": "Unless registered by language, ‘KR’ is basically set.",
    "id": "Kecuali terdaftar berdasarkan bahasa, ‘KR’ pada dasarnya diatur.",
    "ja": "(J)언어별 파일이 등록되지 않은 경우 KR이 기본으로 표기됩니다."
  },
  "파일": {
    "ko": "파일",
    "en": "File",
    "id": "Mengajukan",
    "ja": "(J)파일"
  },
  "파일은 PDF만 가능합니다.": {
    "ko": "파일은 PDF만 가능합니다.",
    "en": "A PDF format is only allowed.",
    "id": "Format PDF hanya diperbolehkan.",
    "ja": "(J)파일은 PDF만 가능합니다."
  },
  "재등록": {
    "ko": "재등록",
    "en": "Add Again",
    "id": "Tambahkan lagi",
    "ja": "(J)재등록"
  },
  "파일 등록하기": {
    "ko": "파일 등록하기",
    "en": "Add File",
    "id": "Tambah berkas",
    "ja": "(J)파일 등록하기"
  },
  "파일을 등록하면 미리 볼 수 있습니다.": {
    "ko": "파일을 등록하면 미리 볼 수 있습니다.",
    "en": "Once added, the file can be previewed.",
    "id": "Setelah ditambahkan, file dapat dipratinjau.",
    "ja": "(J)파일을 등록하면 미리 볼 수 있습니다."
  },
  "파일을 불러오는 중입니다.": {
    "ko": "파일을 불러오는 중입니다.",
    "en": "Loading the file…",
    "id": "Memuat file…",
    "ja": "(J)파일을 불러오는 중입니다."
  },
  "메뉴얼 등록": {
    "ko": "매뉴얼 등록",
    "en": "Add Manual",
    "id": "Tambahkan manual",
    "ja": "(J)메뉴얼 등록"
  },
  "사용메뉴얼이 수정되었습니다.": {
    "ko": "사용 매뉴얼이 수정되었습니다.",
    "en": "User Manual is successfully edited.",
    "id": "Panduan Pengguna berhasil diedit.",
    "ja": "(J)사용메뉴얼이 수정되었습니다."
  },
  "수정된 내용은 사용메뉴얼 정보에서 확인하세요.": {
    "ko": "수정된 내용은 사용 매뉴얼 정보에서 확인하세요.",
    "en": "Check the revision on the User Manual Information.",
    "id": "Periksa revisi pada informasi manual pengguna.",
    "ja": "(J)수정된 내용은 사용메뉴얼 정보에서 확인하세요."
  },
  "등록한 사용메뉴얼을 삭제합니다.": {
    "ko": "등록한 사용 매뉴얼을 삭제합니다.",
    "en": "Deleting User Manual…",
    "id": "Menghapus Manual Pengguna…",
    "ja": "(J)등록한 사용메뉴얼을 삭제합니다."
  },
  "삭제 완료 시 해당 사용메뉴얼 삭제되고": {
    "ko": "삭제 완료 시 해당 사용 매뉴얼이 삭제되고",
    "en": "Once deleted, the User Manual will be deleted.",
    "id": "Setelah dihapus, manual pengguna akan dihapus.",
    "ja": "(J)삭제 완료 시 해당 사용메뉴얼 삭제되고"
  },
  "이전 버전의 사용메뉴얼이 노출됩니다.": {
    "ko": "이전 버전의 사용 매뉴얼이 노출됩니다.",
    "en": "Then, the old version of the User Manual appears.",
    "id": "Kemudian, versi lama manual pengguna muncul.",
    "ja": "(J)이전 버전의 사용메뉴얼이 노출됩니다."
  },
  "삭제를 진행하시겠어요?": {
    "ko": "삭제를 진행하시겠어요?",
    "en": "Do you want to delete?",
    "id": "Apakah Anda ingin menghapus?",
    "ja": "(J)삭제를 진행하시겠어요?"
  },
  "삭제하기": {
    "ko": "삭제하기",
    "en": "Delete",
    "id": "Menghapus",
    "ja": "(J)삭제하기"
  },
  "사용메뉴얼 정보": {
    "ko": "사용 매뉴얼 정보",
    "en": "User Manual Information",
    "id": "Informasi Manual Pengguna",
    "ja": "(J)사용메뉴얼 정보"
  },
  "파일을 불러오는 데 실패했습니다. 다시 선택해주세요.": {
    "ko": "파일을 불러오는 데 실패했습니다. 다시 선택해 주세요.",
    "en": "Failed to load a file! Please select again.",
    "id": "Gagal memuat file! Pilih lagi.",
    "ja": "(J)파일을 불러오는 데 실패했습니다. 다시 선택해주세요."
  },
  "강제 버전": {
    "ko": "필수 버전",
    "en": "Required Version",
    "id": "Versi yang diperlukan",
    "ja": "(J)강제 버전"
  },
  "권장 버전": {
    "ko": "권장 버전",
    "en": "Recommended Version",
    "id": "Versi yang disarankan",
    "ja": "(J)권장 버전"
  },
  "최신 버전": {
    "ko": "최신 버전",
    "en": "Latest Version",
    "id": "Versi terbaru",
    "ja": "(J)최신 버전"
  },
  "변경하기": {
    "ko": "변경하기",
    "en": "Change",
    "id": "Mengubah",
    "ja": "(J)변경하기"
  },
  "수정 완료": {
    "ko": "수정 완료",
    "en": "(E)수정 완료",
    "id": "(ID)수정 완료",
    "ja": "(J)수정 완료"
  },
  "앱버전 수정이 완료되었습니다.": {
    "ko": "앱버전 수정이 완료되었습니다.",
    "en": "The app was successfully updated.",
    "id": "Aplikasi ini berhasil diperbarui.",
    "ja": "(J)앱버전 수정이 완료되었습니다."
  },
  "BIZ전체": {
    "ko": "BIZ전체",
    "en": "(E)BIZ전체",
    "id": "(ID)BIZ전체",
    "ja": "(J)BIZ전체"
  },
  "특정BIZ": {
    "ko": "특정BIZ",
    "en": "(E)특정BIZ",
    "id": "(ID)특정BIZ",
    "ja": "(J)특정BIZ"
  },
  "BIZcode를 입력하세요.": {
    "ko": "BIZcode를 입력하세요.",
    "en": "Please enter the BIZ code.",
    "id": "Harap masukkan kode BIZ.",
    "ja": "(J)BIZcode를 입력하세요."
  },
  "링크": {
    "ko": "링크",
    "en": "Link",
    "id": "Tautan",
    "ja": "(J)링크"
  },
  "링크를 입력해주세요.": {
    "ko": "링크를 입력해 주세요.",
    "en": "Please enter a link.",
    "id": "Harap masukkan tautan.",
    "ja": "(J)링크를 입력해주세요."
  },
  "설명": {
    "ko": "설명",
    "en": "Description",
    "id": "Keterangan",
    "ja": "(J)설명"
  },
  "설명을 입력해주세요.": {
    "ko": "설명을 입력해 주세요.",
    "en": "Please enter a description.",
    "id": "Harap masukkan deskripsi.",
    "ja": "(J)설명을 입력해주세요."
  },
  "한국어를 포함한 한 가지 이상의 지원언어를 선택해야합니다.": {
    "ko": "한국어를 포함한 한 가지 이상의 지원언어를 선택해야합니다.",
    "en": "Please select at least one language including KR.",
    "id": "Pilih setidaknya satu bahasa termasuk KR.",
    "ja": "(J)한국어를 포함한 한 가지 이상의 지원언어를 선택해야합니다."
  },
  "앱푸시메시지가 정상적으로 등록되었습니다.": {
    "ko": "앱 푸시 메시지가 정상적으로 등록되었습니다.",
    "en": "A push notification is successfully registered.",
    "id": "Pemberitahuan push berhasil terdaftar.",
    "ja": "(J)앱푸시메시지가 정상적으로 등록되었습니다."
  },
  "앱푸시메시지 목록에서 확인하세요.": {
    "ko": "앱 푸시 메시지 목록에서 확인하세요.",
    "en": "Please check on the push notification list.",
    "id": "Silakan periksa daftar pemberitahuan push.",
    "ja": "(J)앱푸시메시지 목록에서 확인하세요."
  },
  "앱푸시메시지 등록하기": {
    "ko": "앱 푸시 메시지 등록하기",
    "en": "Add push notification",
    "id": "Tambahkan Pemberitahuan Push",
    "ja": "(J)앱푸시메시지 등록하기"
  },
  "앱푸시메시지가 정상적으로 수정되었습니다.": {
    "ko": "앱 푸시 메시지가 정상적으로 수정되었습니다.",
    "en": "The push notification was successfully edited.",
    "id": "Pemberitahuan push berhasil diedit.",
    "ja": "(J)앱푸시메시지가 정상적으로 수정되었습니다."
  },
  "등록한 앱푸시메시지를 삭제합니다.": {
    "ko": "등록한 앱 푸시 메시지를 삭제합니다.",
    "en": "Delete the push notification.",
    "id": "Hapus pemberitahuan push.",
    "ja": "(J)등록한 앱푸시메시지를 삭제합니다."
  },
  "삭제 완료 시 글이 목록에서 사라집니다.": {
    "ko": "삭제 완료 시 글이 목록에서 사라집니다.",
    "en": "Once deleted, the notification will be deleted from the list.",
    "id": "Setelah dihapus, pemberitahuan akan dihapus dari daftar.",
    "ja": "(J)삭제 완료 시 글이 목록에서 사라집니다."
  },
  "발송완료": {
    "ko": "발송 완료",
    "en": "Successfully forwarded!",
    "id": "Berhasil diteruskan!",
    "ja": "(J)발송완료"
  },
  "앱푸시메시지": {
    "ko": "앱 푸시 메시지",
    "en": "Push Notification",
    "id": "Pemberitahuan push",
    "ja": "(J)앱푸시메시지"
  },
  "푸시메시지 등록": {
    "ko": "푸시 메시지 등록",
    "en": "Add Push Notification",
    "id": "Tambahkan Pemberitahuan Push",
    "ja": "(J)푸시메시지 등록"
  },
  "타겟": {
    "ko": "타겟",
    "en": "Target",
    "id": "Target",
    "ja": "(J)타겟"
  },
  "OS 전체": {
    "ko": "OS 전체",
    "en": "(E)OS 전체",
    "id": "(ID)OS 전체",
    "ja": "(J)OS 전체"
  },
  "Member 전체": {
    "ko": "Member 전체",
    "en": "(E)Member 전체",
    "id": "(ID)Member 전체",
    "ja": "(J)Member 전체"
  },
  "Device 전체": {
    "ko": "Device 전체",
    "en": "(E)Device 전체",
    "id": "(ID)Device 전체",
    "ja": "(J)Device 전체"
  },
  "등록된 노드가 없습니다.": {
    "ko": "등록된 노드가 없습니다.",
    "en": "Cannot find a node!",
    "id": "Tidak dapat menemukan simpul!",
    "ja": "(J)등록된 노드가 없습니다."
  },
  "발송현황": {
    "ko": "발송 현황",
    "en": "Send Status",
    "id": "Kirim Status",
    "ja": "(J)발송현황"
  },
  "제목을 입력하세요.": {
    "ko": "제목을 입력하세요.",
    "en": "Please enter a title.",
    "id": "Harap masukkan judul.",
    "ja": "(J)제목을 입력하세요."
  },
  "종료일시보다 이전 일시를 선택해주세요.": {
    "ko": "종료일시보다 이전 일시를 선택해 주세요.",
    "en": "Please select the date and time earlier than the end date and time.",
    "id": "Pilih tanggal dan waktu lebih awal dari tanggal dan waktu akhir.",
    "ja": "(J)종료일시보다 이전 일시를 선택해주세요."
  },
  "링크URL": {
    "ko": "링크 URL",
    "en": "Link URL",
    "id": "Tautan URL",
    "ja": "(J)링크URL"
  },
  "URL을 입력하세요.": {
    "ko": "URL을 입력하세요.",
    "en": "Please enter a URL.",
    "id": "Harap masukkan URL.",
    "ja": "(J)URL을 입력하세요."
  },
  "미리보기 이미지": {
    "ko": "미리보기 이미지",
    "en": "(E)미리보기 이미지",
    "id": "(ID)미리보기 이미지",
    "ja": "(J)미리보기 이미지"
  },
  "이미지파일 JPG, PNG만 가능합니다. (750 X 40)": {
    "ko": "이미지파일은 JPG, PNG만 가능합니다. (750 X 40)",
    "en": "JPG and PNG files are only permitted (750x40).",
    "id": "File JPG dan PNG hanya diizinkan (750x40).",
    "ja": "(J)이미지파일 JPG, PNG만 가능합니다. (750 X 40)"
  },
  "이미지": {
    "ko": "이미지",
    "en": "Image",
    "id": "Gambar",
    "ja": "(J)이미지"
  },
  "게시일시보다 이후 일시를 선택해주세요.": {
    "ko": "게시일시보다 이후 일시를 선택해 주세요.",
    "en": "Please select the date and time earlier than the end date and time.",
    "id": "Pilih tanggal dan waktu lebih awal dari tanggal dan waktu akhir.",
    "ja": "(J)게시일시보다 이후 일시를 선택해주세요."
  },
  "배너타겟": {
    "ko": "배너 타겟",
    "en": "Banner Target",
    "id": "Target spanduk",
    "ja": "(J)배너타겟"
  },
  "제목은 500자 이내로 작성해주세요.": {
    "ko": "제목은 500자 이내로 작성해 주세요.",
    "en": "The maximum length of a title is 500 characters.",
    "id": "Panjang maksimum judul adalah 500 karakter.",
    "ja": "(J)제목은 500자 이내로 작성해주세요."
  },
  "메뉴배너 등록이 완료되었습니다.": {
    "ko": "메뉴 배너 등록이 완료되었습니다.",
    "en": "A menu banner was successfully registered.",
    "id": "Spanduk menu berhasil terdaftar.",
    "ja": "(J)메뉴배너 등록이 완료되었습니다."
  },
  "앱메뉴배너 등록하기": {
    "ko": "앱 메뉴 배너 등록하기",
    "en": "Add Menu Banner",
    "id": "Tambahkan spanduk menu",
    "ja": "(J)앱메뉴배너 등록하기"
  },
  "종료일시": {
    "ko": "종료 일시",
    "en": "End Date and Time",
    "id": "Tanggal dan Waktu Akhir",
    "ja": "(J)종료일시"
  },
  "배너를 표기할 언어별로 배너 이미지와 url을 등록해야 합니다.": {
    "ko": "배너를 표기할 언어별로 배너 이미지와 url을 등록해야 합니다.",
    "en": "You are required to add banner images and URL by language.",
    "id": "Anda diharuskan menambahkan gambar spanduk dan URL berdasarkan bahasa.",
    "ja": "(J)배너를 표기할 언어별로 배너 이미지와 url을 등록해야 합니다."
  },
  "이미지와 URL을 개별 등록하지 않은 언어의 사용자에게는 팝업이 표기되지 않습니다.": {
    "ko": "이미지와 URL을 개별 등록하지 않은 언어의 사용자에게는 팝업이 표기되지 않습니다.",
    "en": "Unless such images and URL are registered individually, a popup page will not appear.",
    "id": "Kecuali gambar dan URL tersebut terdaftar secara individual, halaman popup tidak akan muncul.",
    "ja": "(J)이미지와 URL을 개별 등록하지 않은 언어의 사용자에게는 팝업이 표기되지 않습니다."
  },
  "이미지의 크기 (750x40) 을 준수해주세요.": {
    "ko": "이미지의 크기(750x40)를 준수해 주세요.",
    "en": "Ensure to comply with the size of an image (750x40).",
    "id": "Pastikan untuk mematuhi ukuran gambar (750x40).",
    "ja": "(J)이미지의 크기 (750x40) 을 준수해주세요."
  },
  "네": {
    "ko": "네",
    "en": "Yes",
    "id": "Ya",
    "ja": "(J)네"
  },
  "메뉴배너가 수정되었습니다.": {
    "ko": "메뉴 배너가 수정되었습니다.",
    "en": "The menu banner was successfully edited.",
    "id": "Banner menu berhasil diedit.",
    "ja": "(J)메뉴배너가 수정되었습니다."
  },
  "수정된 정보는 목록에서 확인하세요.": {
    "ko": "수정된 정보는 목록에서 확인하세요.",
    "en": "Please check the modifications on the list.",
    "id": "Silakan periksa modifikasi pada daftar.",
    "ja": "(J)수정된 정보는 목록에서 확인하세요."
  },
  "등록한 앱메뉴배너를 삭제합니다.": {
    "ko": "등록한 앱 메뉴 배너를 삭제합니다.",
    "en": "Deleting the menu banner…",
    "id": "Menghapus spanduk menu…",
    "ja": "(J)등록한 앱메뉴배너를 삭제합니다."
  },
  "삭제 완료 시 배너 노출은 모두 중단됩니다.": {
    "ko": "삭제 완료 시 배너 노출은 모두 중단됩니다.",
    "en": "Once deleted, all banners will disappear.",
    "id": "Setelah dihapus, semua spanduk akan hilang.",
    "ja": "(J)삭제 완료 시 배너 노출은 모두 중단됩니다."
  },
  "배너 등록": {
    "ko": "배너 등록",
    "en": "Add Banner",
    "id": "Tambahkan spanduk",
    "ja": "(J)배너 등록"
  },
  "게시일시": {
    "ko": "게시 일시",
    "en": "Date and Time Posted",
    "id": "Tanggal dan waktu diposting",
    "ja": "(J)게시일시"
  },
  "이미지의 크기가 (1280x400) 을 넘지 않도록 해주세요.": {
    "ko": "이미지의 크기가 (1280x400) 을 넘지 않도록 해주세요.",
    "en": "(E)이미지의 크기가 (1280x400) 을 넘지 않도록 해주세요.",
    "id": "(ID)이미지의 크기가 (1280x400) 을 넘지 않도록 해주세요.",
    "ja": "(J)이미지의 크기가 (1280x400) 을 넘지 않도록 해주세요."
  },
  "이미지파일 JPG, PNG만 가능합니다.": {
    "ko": "이미지 파일은 JPG, PNG만 가능합니다.",
    "en": "JPG and PNG files are only permitted.",
    "id": "File JPG dan PNG hanya diizinkan.",
    "ja": "(J)이미지파일 JPG, PNG만 가능합니다."
  },
  "또는 가로세로 3.2:1 비율의 이미지를 준비하세요.": {
    "ko": "또는 가로세로 3.2:1 비율의 이미지를 준비하세요.",
    "en": "(E)또는 가로세로 3.2:1 비율의 이미지를 준비하세요.",
    "id": "(ID)또는 가로세로 3.2:1 비율의 이미지를 준비하세요.",
    "ja": "(J)또는 가로세로 3.2:1 비율의 이미지를 준비하세요."
  },
  "메인배너 등록이 완료되었습니다.": {
    "ko": "메인 배너 등록이 완료되었습니다.",
    "en": "The main manner was successfully added.",
    "id": "Cara utama berhasil ditambahkan.",
    "ja": "(J)메인배너 등록이 완료되었습니다."
  },
  "앱마케팅배너 등록하기": {
    "ko": "앱마케팅배너 등록하기",
    "en": "(E)앱마케팅배너 등록하기",
    "id": "(ID)앱마케팅배너 등록하기",
    "ja": "(J)앱마케팅배너 등록하기"
  },
  "메인배너가 수정되었습니다.": {
    "ko": "메인 배너가 수정되었습니다.",
    "en": "The main banner was successfully edited.",
    "id": "Spanduk utama berhasil diedit.",
    "ja": "(J)메인배너가 수정되었습니다."
  },
  "등록한 앱마케팅배너를 삭제합니다.": {
    "ko": "등록한 앱마케팅배너를 삭제합니다.",
    "en": "(E)등록한 앱마케팅배너를 삭제합니다.",
    "id": "(ID)등록한 앱마케팅배너를 삭제합니다.",
    "ja": "(J)등록한 앱마케팅배너를 삭제합니다."
  },
  "게시가 완료되었습니다.": {
    "ko": "게시가 완료되었습니다.",
    "en": "Successfully posted!",
    "id": "Berhasil diposting!",
    "ja": "(J)게시가 완료되었습니다."
  },
  "앱마케팅배너 정보": {
    "ko": "앱마케팅배너 정보",
    "en": "(E)앱마케팅배너 정보",
    "id": "(ID)앱마케팅배너 정보",
    "ja": "(J)앱마케팅배너 정보"
  },
  "이미지의 크기 (750x820) 을 준수해주세요.": {
    "ko": "이미지의 크기(750x820)를 준수해 주세요.",
    "en": "Please comply with the size of an image (750x820).",
    "id": "Harap patuhi ukuran gambar (750x820).",
    "ja": "(J)이미지의 크기 (750x820) 을 준수해주세요."
  },
  "앱메인배너 등록하기": {
    "ko": "앱 메인 배너 등록하기",
    "en": "Add Main Banner",
    "id": "Tambahkan spanduk utama",
    "ja": "(J)앱메인배너 등록하기"
  },
  "등록한 앱메인배너를 삭제합니다.": {
    "ko": "등록한 앱 메인 배너를 삭제합니다.",
    "en": "Deleting the main banner…",
    "id": "Menghapus spanduk utama…",
    "ja": "(J)등록한 앱메인배너를 삭제합니다."
  },
  "앱메인배너 정보": {
    "ko": "앱 메인 배너 정보",
    "en": "Main banner information",
    "id": "Informasi spanduk utama",
    "ja": "(J)앱메인배너 정보"
  },
  "비밀번호는 최소 8자, 영문, 숫자, 특수문자를 포함해야 합니다.": {
    "ko": "비밀번호는 최소 8자, 영문, 숫자, 특수문자를 포함해야 합니다.",
    "en": "A password must have 8 characters or more and contain 1 English letter, 1 number and 1 special character at least.",
    "id": "Kata sandi harus memiliki 8 karakter atau lebih dan berisi 1 surat bahasa Inggris, 1 nomor dan 1 karakter khusus setidaknya.",
    "ja": "(J)비밀번호는 최소 8자, 영문, 숫자, 특수문자를 포함해야 합니다."
  },
  "새 비밀번호가 일치하지 않습니다.": {
    "ko": "새 비밀번호가 일치하지 않습니다.",
    "en": "(E)새 비밀번호가 일치하지 않습니다.",
    "id": "(ID)새 비밀번호가 일치하지 않습니다.",
    "ja": "(J)새 비밀번호가 일치하지 않습니다."
  },
  "새 비밀번호가 현재 비밀번호와 동일합니다. 다시 입력해주세요.": {
    "ko": "새 비밀번호가 현재 비밀번호와 동일합니다. 다시 입력해주세요.",
    "en": "(E)새 비밀번호가 현재 비밀번호와 동일합니다. 다시 입력해주세요.",
    "id": "(ID)새 비밀번호가 현재 비밀번호와 동일합니다. 다시 입력해주세요.",
    "ja": "(J)새 비밀번호가 현재 비밀번호와 동일합니다. 다시 입력해주세요."
  },
  "변경 완료": {
    "ko": "변경 완료",
    "en": "(E)변경 완료",
    "id": "(ID)변경 완료",
    "ja": "(J)변경 완료"
  },
  "비밀번호 변경이 완료되었습니다.": {
    "ko": "비밀번호 변경이 완료되었습니다.",
    "en": "(E)비밀번호 변경이 완료되었습니다.",
    "id": "(ID)비밀번호 변경이 완료되었습니다.",
    "ja": "(J)비밀번호 변경이 완료되었습니다."
  },
  "비밀번호 변경": {
    "ko": "비밀번호 변경",
    "en": "Change Password",
    "id": "Ganti kata sandi",
    "ja": "(J)비밀번호 변경"
  },
  "아이디": {
    "ko": "아이디",
    "en": "ID",
    "id": "PENGENAL",
    "ja": "(J)아이디"
  },
  "현재 비밀번호": {
    "ko": "현재 비밀번호",
    "en": "(E)현재 비밀번호",
    "id": "(ID)현재 비밀번호",
    "ja": "(J)현재 비밀번호"
  },
  "현재 사용중인 비밀번호를 입력하세요.": {
    "ko": "현재 사용중인 비밀번호를 입력하세요.",
    "en": "(E)현재 사용중인 비밀번호를 입력하세요.",
    "id": "(ID)현재 사용중인 비밀번호를 입력하세요.",
    "ja": "(J)현재 사용중인 비밀번호를 입력하세요."
  },
  "비밀번호": {
    "ko": "비밀번호",
    "en": "Password",
    "id": "Kata sandi",
    "ja": "(J)비밀번호"
  },
  "새 비밀번호를 입력하세요.": {
    "ko": "새 비밀번호를 입력하세요.",
    "en": "(E)새 비밀번호를 입력하세요.",
    "id": "(ID)새 비밀번호를 입력하세요.",
    "ja": "(J)새 비밀번호를 입력하세요."
  },
  "비밀번호 확인": {
    "ko": "비밀번호 확인",
    "en": "Confirm Password",
    "id": "konfirmasi sandi",
    "ja": "(J)비밀번호 확인"
  },
  "입력한 비밀번호를 다시 입력해주세요.": {
    "ko": "입력한 비밀번호를 다시 입력해 주세요.",
    "en": "Please enter the password again.",
    "id": "Harap masukkan kata sandi lagi.",
    "ja": "(J)입력한 비밀번호를 다시 입력해주세요."
  },
  "비밀번호는 최소 8자리 이상 영문자, 숫자, 특수문자를 포함해야 합니다.": {
    "ko": "비밀번호는 최소 8자리 이상 영문자, 숫자, 특수문자를 포함해야 합니다.",
    "en": "A password must have 8 characters or more and contain 1 English letter, 1 number and 1 special character at least.",
    "id": "Kata sandi harus memiliki 8 karakter atau lebih dan berisi 1 surat bahasa Inggris, 1 nomor dan 1 karakter khusus setidaknya.",
    "ja": "(J)비밀번호는 최소 8자리 이상 영문자, 숫자, 특수문자를 포함해야 합니다."
  },
  "아이디는 영어, 숫자만 가능합니다.": {
    "ko": "아이디는 영어, 숫자만 가능합니다.",
    "en": "An ID can be created with a combination of English letters and numbers only.",
    "id": "ID dapat dibuat dengan kombinasi huruf dan angka bahasa Inggris saja.",
    "ja": "(J)아이디는 영어, 숫자만 가능합니다."
  },
  "사용 가능한 아이디입니다.": {
    "ko": "사용 가능한 아이디입니다.",
    "en": "The ID is available.",
    "id": "ID tersedia.",
    "ja": "(J)사용 가능한 아이디입니다."
  },
  "이미 등록된 내역이 있습니다.": {
    "ko": "이미 등록된 내역이 있습니다.",
    "en": "Already registered!",
    "id": "Sudah terdaftar!",
    "ja": "(J)이미 등록된 내역이 있습니다."
  },
  "사용 가능한 법인등록번호입니다.": {
    "ko": "사용 가능한 법인등록번호입니다.",
    "en": "The Corporation Registration No. is available.",
    "id": "No. Registrasi Korporasi tersedia.",
    "ja": "(J)사용 가능한 법인등록번호입니다."
  },
  "올바른 양식입니다.": {
    "ko": "올바른 양식입니다.",
    "en": "A correct form!",
    "id": "Bentuk yang benar!",
    "ja": "(J)올바른 양식입니다."
  },
  "숫자 10자리를 입력해주세요.": {
    "ko": "숫자 10자리를 입력해 주세요.",
    "en": "Please enter a 10-digit number.",
    "id": "Harap masukkan nomor 10 digit.",
    "ja": "(J)숫자 10자리를 입력해주세요."
  },
  "파트너유형": {
    "ko": "파트너 유형",
    "en": "Partner Type",
    "id": "Jenis pasangan",
    "ja": "(J)파트너유형"
  },
  "파트너 아이디": {
    "ko": "파트너 아이디",
    "en": "Partner ID",
    "id": "ID Mitra",
    "ja": "(J)파트너 아이디"
  },
  "파트너 아이디를 입력하세요.": {
    "ko": "파트너 아이디를 입력하세요.",
    "en": "Please enter a partner’s ID.",
    "id": "Harap masukkan ID mitra.",
    "ja": "(J)파트너 아이디를 입력하세요."
  },
  "기업명을 입력하세요.": {
    "ko": "기업명을 입력하세요.",
    "en": "Please enter the company name.",
    "id": "Harap masukkan nama perusahaan.",
    "ja": "(J)기업명을 입력하세요."
  },
  "법인등록번호 13자리를 입력하세요.": {
    "ko": "법인등록번호 13자리를 입력하세요.",
    "en": "Please enter the Corporation Registration No. (13 digits).",
    "id": "Harap masukkan No. Registrasi Korporasi (13 digit).",
    "ja": "(J)법인등록번호 13자리를 입력하세요."
  },
  "사업자등록번호 10자리를 입력하세요.": {
    "ko": "사업자등록번호 10자리를 입력하세요.",
    "en": "Please enter the Business Registration No. (10 digits).",
    "id": "Harap masukkan No. Pendaftaran Bisnis (10 digit).",
    "ja": "(J)사업자등록번호 10자리를 입력하세요."
  },
  "대표자": {
    "ko": "대표자",
    "en": "CEO",
    "id": "CEO",
    "ja": "(J)대표자"
  },
  "대표자를 입력하세요.": {
    "ko": "대표자를 입력하세요.",
    "en": "Please enter the CEO’s name.",
    "id": "Harap masukkan nama CEO.",
    "ja": "(J)대표자를 입력하세요."
  },
  "업태": {
    "ko": "업태",
    "en": "Business Type",
    "id": "Jenis bisnis",
    "ja": "(J)업태"
  },
  "업태를 입력하세요.": {
    "ko": "업태를 입력하세요.",
    "en": "Please select a business type.",
    "id": "Pilih jenis bisnis.",
    "ja": "(J)업태를 입력하세요."
  },
  "종목": {
    "ko": "종목",
    "en": "Business Item",
    "id": "Item bisnis",
    "ja": "(J)종목"
  },
  "종목을 입력하세요.": {
    "ko": "종목을 입력하세요.",
    "en": "Please select a business item.",
    "id": "Pilih item bisnis.",
    "ja": "(J)종목을 입력하세요."
  },
  "대표번호": {
    "ko": "대표번호",
    "en": "TEL",
    "id": "Tel",
    "ja": "(J)대표번호"
  },
  "대표번호를 입력해주세요.": {
    "ko": "대표번호를 입력해 주세요.",
    "en": "Please enter the phone No.",
    "id": "Harap masukkan No. Telepon",
    "ja": "(J)대표번호를 입력해주세요."
  },
  "팩스번호": {
    "ko": "팩스번호",
    "en": "FAX",
    "id": "FAX",
    "ja": "(J)팩스번호"
  },
  "팩스번호를 입력해주세요.": {
    "ko": "팩스번호를 입력해 주세요.",
    "en": "Please enter the fax No.",
    "id": "Harap masukkan Faks No.",
    "ja": "(J)팩스번호를 입력해주세요."
  },
  "문의번호": {
    "ko": "문의번호",
    "en": "Inquiry No.",
    "id": "PERTANYAAN No.",
    "ja": "(J)문의번호"
  },
  "문의번호를 입력해주세요.": {
    "ko": "문의번호를 입력해 주세요.",
    "en": "Please enter the inquiry No.",
    "id": "Harap masukkan pertanyaan No.",
    "ja": "(J)문의번호를 입력해주세요."
  },
  "주소 검색": {
    "ko": "주소 검색",
    "en": "Address Search",
    "id": "Pencarian alamat",
    "ja": "(J)주소 검색"
  },
  "파트너 유형을 선택해주세요.": {
    "ko": "파트너 유형을 선택해 주세요.",
    "en": "Please select a partner type.",
    "id": "Pilih jenis mitra.",
    "ja": "(J)파트너 유형을 선택해주세요."
  },
  "파트너정보 등록이 정상적으로 완료되었습니다.": {
    "ko": "파트너 정보 등록이 정상적으로 완료되었습니다.",
    "en": "The partner information is successfully registered.",
    "id": "Informasi mitra berhasil terdaftar.",
    "ja": "(J)파트너정보 등록이 정상적으로 완료되었습니다."
  },
  "파트너정보 목록에서 확인하세요.": {
    "ko": "파트너 정보 목록에서 확인하세요.",
    "en": "Please check on the partner information list.",
    "id": "Silakan periksa daftar informasi mitra.",
    "ja": "(J)파트너정보 목록에서 확인하세요."
  },
  "파트너아이디는 고유키로 등록 후 수정이 불가능합니다.": {
    "ko": "파트너 아이디는 고유키로 등록 후 수정이 불가능합니다.",
    "en": "A partner ID can be registered with its own key. Then, it cannot be edited.",
    "id": "ID mitra dapat didaftarkan dengan kuncinya sendiri. Kemudian, itu tidak dapat diedit.",
    "ja": "(J)파트너아이디는 고유키로 등록 후 수정이 불가능합니다."
  },
  "기업정보는 법인등록번호를 기준으로 중복 등록이 불가능합니다.": {
    "ko": "기업 정보는 법인등록번호를 기준으로 중복 등록이 불가능합니다.",
    "en": "The company information cannot be registered repeatedly under Corporation Registration No.",
    "id": "Informasi Perusahaan tidak dapat didaftarkan berulang kali di bawah No. Registrasi Korporasi",
    "ja": "(J)기업정보는 법인등록번호를 기준으로 중복 등록이 불가능합니다."
  },
  "국가": {
    "ko": "국가",
    "en": "Country",
    "id": "Negara",
    "ja": "(J)국가"
  },
  "시간대": {
    "ko": "시간대",
    "en": "Timezone",
    "id": "Zona waktu",
    "ja": "(J)시간대"
  },
  "파일 등록에 실패했습니다. 다시 선택해주세요.": {
    "ko": "파일 등록에 실패했습니다. 다시 선택해 주세요.",
    "en": "Failed to add a file! Please select again.",
    "id": "Gagal menambahkan file! Pilih lagi.",
    "ja": "(J)파일 등록에 실패했습니다. 다시 선택해주세요."
  },
  "고객사 사업자등록증": {
    "ko": "고객사 사업자등록증",
    "en": "Partner’s Certificate of Business Registration",
    "id": "Sertifikat Pendaftaran Bisnis Mitra",
    "ja": "(J)고객사 사업자등록증"
  },
  "사업자등록증": {
    "ko": "사업자등록증",
    "en": "Certificate of Business Registration",
    "id": "Sertifikat Pendaftaran Bisnis",
    "ja": "(J)사업자등록증"
  },
  "파일 없음": {
    "ko": "파일 없음",
    "en": "No file found!",
    "id": "Tidak ada file yang ditemukan!",
    "ja": "(J)파일 없음"
  },
  "파트너 사업자등록증을 PDF로 등록해주세요.": {
    "ko": "파트너 사업자등록증을 PDF로 등록해 주세요.",
    "en": "Please add a partner’s Certificate of Business Registration in PDF file format.",
    "id": "Harap tambahkan sertifikat pendaftaran bisnis mitra dalam format file PDF.",
    "ja": "(J)파트너 사업자등록증을 PDF로 등록해주세요."
  },
  "등록된 문서는 파트너 운영자가 어드민을 통해 확인할 수 있습니다.": {
    "ko": "등록된 문서는 파트너 운영자가 어드민을 통해 확인할 수 있습니다.",
    "en": "The partner operator is able to check the registered documents via the admin.",
    "id": "Operator mitra dapat memeriksa dokumen terdaftar melalui admin.",
    "ja": "(J)등록된 문서는 파트너 운영자가 어드민을 통해 확인할 수 있습니다."
  },
  "모든 정보를 입력해야 파트너 등록이 가능합니다.": {
    "ko": "모든 정보를 입력해야 파트너 등록이 가능합니다.",
    "en": "A partner cannot be registered unless all information is entered.",
    "id": "Mitra tidak dapat didaftarkan kecuali semua informasi dimasukkan.",
    "ja": "(J)모든 정보를 입력해야 파트너 등록이 가능합니다."
  },
  "기업정보를 한번에 입력하기 어려운 경우에는 임시저장을 이용해주세요.": {
    "ko": "기업 정보를 한 번에 입력하기 어려운 경우에는 임시저장을 이용해 주세요.",
    "en": "In case it is hard to enter company information at a time, use Temporary Save.",
    "id": "Jika sulit untuk memasukkan informasi perusahaan sekaligus, gunakan penyelamatan sementara.",
    "ja": "(J)기업정보를 한번에 입력하기 어려운 경우에는 임시저장을 이용해주세요."
  },
  "임시저장": {
    "ko": "임시저장",
    "en": "Temporary Save",
    "id": "Simpan sementara",
    "ja": "(J)임시저장"
  },
  "파트너 등록하기": {
    "ko": "파트너 등록하기",
    "en": "Add Partner",
    "id": "Tambahkan pasangan",
    "ja": "(J)파트너 등록하기"
  },
  "국가를 먼저 선택해주세요.": {
    "ko": "국가를 먼저 선택해주세요.",
    "en": "(E)국가를 먼저 선택해주세요.",
    "id": "(ID)국가를 먼저 선택해주세요.",
    "ja": "(J)국가를 먼저 선택해주세요."
  },
  "우편번호": {
    "ko": "우편번호",
    "en": "Zip Code",
    "id": "Kode Pos",
    "ja": "(J)우편번호"
  },
  "우편번호를 입력하세요.": {
    "ko": "우편번호를 입력하세요.",
    "en": "Please enter a zip code.",
    "id": "Harap masukkan kode pos.",
    "ja": "(J)우편번호를 입력하세요."
  },
  "주소": {
    "ko": "주소",
    "en": "Address",
    "id": "Alamat",
    "ja": "(J)주소"
  },
  "주소를 입력하세요.": {
    "ko": "주소를 입력하세요.",
    "en": "Please enter an address.",
    "id": "Harap masukkan alamat.",
    "ja": "(J)주소를 입력하세요."
  },
  "비밀번호가 틀립니다. 다시 입력하세요.": {
    "ko": "비밀번호가 틀립니다. 다시 입력하세요.",
    "en": "The password is incorrect. Please enter again.",
    "id": "Kata sandi salah. Harap masukkan lagi.",
    "ja": "(J)비밀번호가 틀립니다. 다시 입력하세요."
  },
  "이름은 한글 혹은 영어만 가능합니다.": {
    "ko": "이름은 한글 혹은 영어만 가능합니다.",
    "en": "A name can be in either Korean or English only.",
    "id": "Nama bisa dalam bahasa Korea atau bahasa Inggris saja.",
    "ja": "(J)이름은 한글 혹은 영어만 가능합니다."
  },
  "이메일을 정확하게 입력해주세요.": {
    "ko": "이메일을 정확하게 입력해 주세요.",
    "en": "Please enter the email address correctly.",
    "id": "Harap masukkan alamat email dengan benar.",
    "ja": "(J)이메일을 정확하게 입력해주세요."
  },
  "휴대폰 번호는 ": {
    "ko": "휴대폰 번호는",
    "en": "A mobile number is",
    "id": "Nomor ponsel adalah",
    "ja": "(J)휴대폰 번호는 "
  },
  "아이디는 영문, 숫자, 4글자 이상 설정해야 합니다.": {
    "ko": "아이디는 영문, 숫자, 4글자 이상 설정해야 합니다.",
    "en": "An ID should be at least 4 character-long in a combination of English letters and numbers.",
    "id": "ID harus setidaknya 4 karakter dalam kombinasi huruf dan angka bahasa Inggris.",
    "ja": "(J)아이디는 영문, 숫자, 4글자 이상 설정해야 합니다."
  },
  "관리자 등록이 완료되었습니다.": {
    "ko": "관리자 등록이 완료되었습니다.",
    "en": "Admin registration is completed.",
    "id": "Pendaftaran admin selesai.",
    "ja": "(J)관리자 등록이 완료되었습니다."
  },
  "관리자정보 메뉴에서 등록된 정보를 확인할 수 있습니다.": {
    "ko": "관리자 정보 메뉴에서 등록된 정보를 확인할 수 있습니다.",
    "en": "The information can be checked in the ADMIN INFORMATION.",
    "id": "Informasi dapat diperiksa dalam informasi admin.",
    "ja": "(J)관리자정보 메뉴에서 등록된 정보를 확인할 수 있습니다."
  },
  "파트너 어드민 접속시 사용할 아이디를 입력하세요.": {
    "ko": "파트너 어드민 접속 시 사용할 아이디를 입력하세요.",
    "en": "Please enter an ID to be used to access to the Partner Admin.",
    "id": "Harap masukkan ID untuk digunakan untuk mengakses admin mitra.",
    "ja": "(J)파트너 어드민 접속시 사용할 아이디를 입력하세요."
  },
  "파트너 어드민 접속시 사용할 비밀번호를 입력하세요.": {
    "ko": "파트너 어드민 접속 시 사용할 비밀번호를 입력하세요.",
    "en": "Please enter a password to be used to access to the Partner Admin.",
    "id": "Harap masukkan kata sandi yang akan digunakan untuk mengakses admin mitra.",
    "ja": "(J)파트너 어드민 접속시 사용할 비밀번호를 입력하세요."
  },
  "아이디는 최소 4자리 이상 설정해야 합니다.": {
    "ko": "아이디는 최소 4자리 이상 설정해야 합니다.",
    "en": "An ID should be at least 4-charater long.",
    "id": "ID harus memiliki panjang setidaknya 4-karakter.",
    "ja": "(J)아이디는 최소 4자리 이상 설정해야 합니다."
  },
  "*파트너에서 사용할 관리자 계정을 등록 생성합니다.": {
    "ko": "*파트너에서 사용할 관리자 계정을 등록 생성합니다.",
    "en": "An admin account to be used in the PARTNER is added.",
    "id": "Akun admin yang akan digunakan dalam mitra ditambahkan.",
    "ja": "(J)*파트너에서 사용할 관리자 계정을 등록 생성합니다."
  },
  "관리자 등록하기": {
    "ko": "관리자 등록하기",
    "en": "Add Admin",
    "id": "Tambahkan admin",
    "ja": "(J)관리자 등록하기"
  },
  "파트너명": {
    "ko": "파트너명",
    "en": "Partner Name",
    "id": "Nama pasangan",
    "ja": "(J)파트너명"
  },
  "검색하세요": {
    "ko": "검색하세요",
    "en": "Please search.",
    "id": "Harap cari.",
    "ja": "(J)검색하세요"
  },
  "이름을 입력하세요.": {
    "ko": "이름을 입력하세요.",
    "en": "Please enter the name.",
    "id": "Harap masukkan namanya.",
    "ja": "(J)이름을 입력하세요."
  },
  "부서": {
    "ko": "부서",
    "en": "Dept.",
    "id": "Yg berhubung dgn Departemen",
    "ja": "(J)부서"
  },
  "부서를 입력하세요.": {
    "ko": "부서를 입력하세요.",
    "en": "Please enter the department.",
    "id": "Harap masukkan departemen.",
    "ja": "(J)부서를 입력하세요."
  },
  "직책": {
    "ko": "직책",
    "en": "Job Title",
    "id": "Judul pekerjaan",
    "ja": "(J)직책"
  },
  "직책을 입력하세요.": {
    "ko": "직책을 입력하세요.",
    "en": "Please enter the job title.",
    "id": "Harap masukkan judul pekerjaan.",
    "ja": "(J)직책을 입력하세요."
  },
  "이메일을 입력하세요.": {
    "ko": "이메일을 입력하세요.",
    "en": "Please enter the email address.",
    "id": "Harap masukkan alamat email.",
    "ja": "(J)이메일을 입력하세요."
  },
  "휴대폰 번호": {
    "ko": "휴대폰 번호",
    "en": "Mobile No.",
    "id": "Nomor ponsel.",
    "ja": "(J)휴대폰 번호"
  },
  "휴대폰 번호를 입력하세요.": {
    "ko": "휴대폰 번호를 입력하세요.",
    "en": "Please enter the mobile number.",
    "id": "Harap masukkan nomor ponsel.",
    "ja": "(J)휴대폰 번호를 입력하세요."
  },
  "어드민": {
    "ko": "어드민",
    "en": "Admin",
    "id": "Admin",
    "ja": "(J)어드민"
  },
  "유통사": {
    "ko": "유통사",
    "en": "Distributor",
    "id": "Distributor",
    "ja": "(J)유통사"
  },
  "운영사": {
    "ko": "운영사",
    "en": "Operator",
    "id": "Operator",
    "ja": "(J)운영사"
  },
  "렌트카": {
    "ko": "렌트카",
    "en": "(E)렌트카",
    "id": "(ID)렌트카",
    "ja": "(J)렌트카"
  },
  "고객사": {
    "ko": "고객사",
    "en": "Client",
    "id": "Klien",
    "ja": "(J)고객사"
  },
  "정상": {
    "ko": "정상",
    "en": "Normal",
    "id": "Normal",
    "ja": "(J)정상"
  },
  "일시정지": {
    "ko": "일시정지",
    "en": "Pause",
    "id": "Berhenti sebentar",
    "ja": "(J)일시정지"
  },
  "해지": {
    "ko": "해지",
    "en": "Cancel",
    "id": "Membatalkan",
    "ja": "(J)해지"
  },
  "법인등록번호를 입력하세요.": {
    "ko": "법인등록번호를 입력하세요.",
    "en": "Please enter the Corporation Registration No.",
    "id": "Harap masukkan No. Registrasi Korporasi",
    "ja": "(J)법인등록번호를 입력하세요."
  },
  "사업자등록번호를 입력하세요.": {
    "ko": "사업자등록번호를 입력하세요.",
    "en": "Please enter the Business Registration No.",
    "id": "Harap masukkan No. Pendaftaran Bisnis",
    "ja": "(J)사업자등록번호를 입력하세요."
  },
  "파트너정보 수정이 정상적으로 완료되었습니다.": {
    "ko": "파트너정보 수정이 정상적으로 완료되었습니다.",
    "en": "The partner information is successfully edited.",
    "id": "Informasi mitra berhasil diedit.",
    "ja": "(J)파트너정보 수정이 정상적으로 완료되었습니다."
  },
  "파트너정보에서 확인하세요.": {
    "ko": "파트너정보에서 확인하세요.",
    "en": "Please check in the Partner Information.",
    "id": "Silakan periksa informasi mitra.",
    "ja": "(J)파트너정보에서 확인하세요."
  },
  "Bi 이미지가 없습니다.": {
    "ko": "Bi 이미지가 없습니다.",
    "en": "(E)Bi 이미지가 없습니다.",
    "id": "(ID)Bi 이미지가 없습니다.",
    "ja": "(J)Bi 이미지가 없습니다."
  },
  "Bi 이미지": {
    "ko": "Bi 이미지",
    "en": "(E)Bi 이미지",
    "id": "(ID)Bi 이미지",
    "ja": "(J)Bi 이미지"
  },
  "사업자등록증이 없습니다.": {
    "ko": "사업자등록증이 없습니다.",
    "en": "Cannot find the Certificate of Business Registration!",
    "id": "Tidak dapat menemukan sertifikat pendaftaran bisnis!",
    "ja": "(J)사업자등록증이 없습니다."
  },
  "기존 등록 정보가 수정되면 저장하기가 가능합니다.": {
    "ko": "기존 등록 정보가 수정되면 저장하기가 가능합니다.",
    "en": "Once the registered information is edited, it can be saved.",
    "id": "Setelah informasi terdaftar diedit, dapat disimpan.",
    "ja": "(J)기존 등록 정보가 수정되면 저장하기가 가능합니다."
  },
  "수정한 내용에 이상이 없는지 확인 후 저장을 진행하세요.": {
    "ko": "수정한 내용에 이상이 없는지 확인 후 저장을 진행하세요.",
    "en": "Check if the information is correctly edited and then save.",
    "id": "Periksa apakah informasi diedit dengan benar dan kemudian simpan.",
    "ja": "(J)수정한 내용에 이상이 없는지 확인 후 저장을 진행하세요."
  },
  "파트너 정보": {
    "ko": "파트너 정보",
    "en": "Partner Information",
    "id": "Informasi pasangan",
    "ja": "(J)파트너 정보"
  },
  "관리자등록하기": {
    "ko": "관리자 등록하기",
    "en": "Add Admin",
    "id": "Tambahkan admin",
    "ja": "(J)관리자등록하기"
  },
  "파트너ID": {
    "ko": "파트너 ID",
    "en": "Partner ID",
    "id": "ID Mitra",
    "ja": "(J)파트너ID"
  },
  "파트너 등록": {
    "ko": "파트너 등록",
    "en": "Add Partner",
    "id": "Tambahkan pasangan",
    "ja": "(J)파트너 등록"
  },
  "등록자동차 수": {
    "ko": "등록 자동차 수",
    "en": "No. of Vehicles Registered",
    "id": "Jumlah kendaraan terdaftar",
    "ja": "(J)등록자동차 수"
  },
  "중요": {
    "ko": "중요",
    "en": "Important",
    "id": "Penting",
    "ja": "(J)중요"
  },
  "신규": {
    "ko": "신규",
    "en": "New",
    "id": "Baru",
    "ja": "(J)신규"
  },
  "아니오": {
    "ko": "아니오",
    "en": "No",
    "id": "TIDAK",
    "ja": "(J)아니오"
  },
  "중요글 설정": {
    "ko": "중요글 설정",
    "en": "IMPORTANT Settings",
    "id": "Pengaturan penting",
    "ja": "(J)중요글 설정"
  },
  "중요글 설정 시 공지사항 목록 상단에 고정 노출됩니다.": {
    "ko": "중요글 설정 시 공지사항 목록 상단에 고정 노출됩니다.",
    "en": "Once IMPORTANT is set, it appears on top of the list of the Notices.",
    "id": "Setelah penting ditetapkan, muncul di atas daftar pemberitahuan.",
    "ja": "(J)중요글 설정 시 공지사항 목록 상단에 고정 노출됩니다."
  },
  "공지사항 전체": {
    "ko": "공지사항 전체",
    "en": "All",
    "id": "Semua",
    "ja": "(J)공지사항 전체"
  },
  "엔지니어 등록이 완료되었습니다.": {
    "ko": "엔지니어 등록이 완료되었습니다.",
    "en": "The engineer is successfully registered.",
    "id": "Insinyur berhasil terdaftar.",
    "ja": "(J)엔지니어 등록이 완료되었습니다."
  },
  "엔지니어 등록하기": {
    "ko": "엔지니어 등록하기",
    "en": "Add Engineer",
    "id": "Tambahkan insinyur",
    "ja": "(J)엔지니어 등록하기"
  },
  "- 를 제외한 휴대폰 번호를 입력하세요.": {
    "ko": "- 를 제외한 휴대폰 번호를 입력하세요.",
    "en": "Please enter your mobile number without hyphens.",
    "id": "Harap masukkan nomor ponsel Anda tanpa tanda hubung.",
    "ja": "(J)- 를 제외한 휴대폰 번호를 입력하세요."
  },
  "엔지니어 등록 후 사용자에게 엔지니어 앱을 다운로드하여 설치하도록 안내해주세요.": {
    "ko": "엔지니어 등록 후 사용자에게 엔지니어 앱을 다운로드하여 설치하도록 안내해 주세요.",
    "en": "Once the engineer is added, guide the user to download and install an Engineer app.",
    "id": "Setelah insinyur ditambahkan, bimbing pengguna untuk mengunduh dan menginstal aplikasi insinyur.",
    "ja": "(J)엔지니어 등록 후 사용자에게 엔지니어 앱을 다운로드하여 설치하도록 안내해주세요."
  },
  "사용자는 엔지니어 앱을 실행하여 본인인증을 완료하면 정상적으로 엔지니어 앱을 사용할 수 있습니다.": {
    "ko": "사용자는 엔지니어 앱을 실행하여 본인인증을 완료하면 정상적으로 엔지니어 앱을 사용할 수 있습니다.",
    "en": "Run the Engineer app and complete user authentication. Then, you are able to use the app.",
    "id": "Jalankan aplikasi insinyur dan selesaikan otentikasi pengguna. Kemudian, Anda dapat menggunakan aplikasi.",
    "ja": "(J)사용자는 엔지니어 앱을 실행하여 본인인증을 완료하면 정상적으로 엔지니어 앱을 사용할 수 있습니다."
  },
  "관리자이름": {
    "ko": "관리자 이름",
    "en": "Admin Name",
    "id": "Nama Admin",
    "ja": "(J)관리자이름"
  },
  "퇴사": {
    "ko": "퇴사",
    "en": "Resigned",
    "id": "Mengundurkan diri",
    "ja": "(J)퇴사"
  },
  "엔지니어 등록": {
    "ko": "엔지니어 등록",
    "en": "Add Engineer",
    "id": "Tambahkan insinyur",
    "ja": "(J)엔지니어 등록"
  },
  "인증": {
    "ko": "인증",
    "en": "Authentication",
    "id": "Autentikasi",
    "ja": "(J)인증"
  },
  "엔지니어정보 수정이 완료되었습니다.": {
    "ko": "엔지니어 정보 수정이 완료되었습니다.",
    "en": "The engineer information is successfully edited.",
    "id": "Informasi Insinyur berhasil diedit.",
    "ja": "(J)엔지니어정보 수정이 완료되었습니다."
  },
  "엔지니어 정보": {
    "ko": "엔지니어 정보",
    "en": "Engineer Information",
    "id": "Informasi Insinyur",
    "ja": "(J)엔지니어 정보"
  },
  "등록 일시": {
    "ko": "등록 일시",
    "en": "Date and Time Registered",
    "id": "Tanggal dan waktu terdaftar",
    "ja": "(J)등록 일시"
  },
  "인증 일시": {
    "ko": "인증 일시",
    "en": "Date and Time Authenticated",
    "id": "Tanggal dan waktu diautentikasi",
    "ja": "(J)인증 일시"
  },
  "중지": {
    "ko": "중지",
    "en": "Suspended",
    "id": "Tergantung",
    "ja": "(J)중지"
  },
  "*상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다.": {
    "ko": "*상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다.",
    "en": "*Once the status is set to ‘Resigned’, it cannot be recovered.",
    "id": "*Setelah status diatur untuk 'mengundurkan diri', itu tidak dapat dipulihkan.",
    "ja": "(J)*상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다."
  },
  "퇴사 처리되었습니다.": {
    "ko": "퇴사 처리되었습니다.",
    "en": "Successfully resigned!",
    "id": "Berhasil mengundurkan diri!",
    "ja": "(J)퇴사 처리되었습니다."
  },
  "파트너 검색": {
    "ko": "파트너 검색",
    "en": "Search Partner",
    "id": "Mitra pencarian",
    "ja": "(J)파트너 검색"
  },
  "파트너명으로 검색하세요.": {
    "ko": "파트너명으로 검색하세요.",
    "en": "Please search with a partner name.",
    "id": "Silakan cari dengan nama mitra.",
    "ja": "(J)파트너명으로 검색하세요."
  },
  "회사일련번호": {
    "ko": "회사일련번호",
    "en": "Company Serial No.",
    "id": "No. Serial Perusahaan No.",
    "ja": "(J)회사일련번호"
  },
  "선택하기": {
    "ko": "선택하기",
    "en": "Select",
    "id": "Pilih",
    "ja": "(J)선택하기"
  },
  "아이디는 4글자 이상 설정해야 합니다.": {
    "ko": "아이디는 4글자 이상 설정해야 합니다.",
    "en": "An ID should be at least 4-character long.",
    "id": "ID harus setidaknya 4 karakter.",
    "ja": "(J)아이디는 4글자 이상 설정해야 합니다."
  },
  "정지": {
    "ko": "정지",
    "en": "Stop",
    "id": "Berhenti",
    "ja": "(J)정지"
  },
  "관리자 정보 수정이 완료되었습니다.": {
    "ko": "관리자 정보 수정이 완료되었습니다.",
    "en": "The admin information is successfully edited.",
    "id": "Informasi admin berhasil diedit.",
    "ja": "(J)관리자 정보 수정이 완료되었습니다."
  },
  "관리자정보 메뉴에서 수정된 정보를 확인할 수 있습니다.": {
    "ko": "관리자정보 메뉴에서 수정된 정보를 확인할 수 있습니다.",
    "en": "The information can be checked in the ADMIN INFORMATION.",
    "id": "Informasi dapat diperiksa dalam informasi admin.",
    "ja": "(J)관리자정보 메뉴에서 수정된 정보를 확인할 수 있습니다."
  },
  "비밀번호 재설정 링크가 포함된 메일이 발송되었습니다.": {
    "ko": "비밀번호 재설정 링크가 포함된 메일이 발송되었습니다.",
    "en": "A mail with a password reset link has been sent.",
    "id": "Surat dengan tautan reset kata sandi telah dikirim.",
    "ja": "(J)비밀번호 재설정 링크가 포함된 메일이 발송되었습니다."
  },
  "해당 메일에 포함된 재설정 링크는 24시간만 유효합니다.": {
    "ko": "해당 메일에 포함된 재설정 링크는 24시간만 유효합니다.",
    "en": "The link is valid for 24 hours.",
    "id": "Tautan ini berlaku selama 24 jam.",
    "ja": "(J)해당 메일에 포함된 재설정 링크는 24시간만 유효합니다."
  },
  "일시적인 오류가 발생하였습니다. 잠시후 다시 시도해 주세요.": {
    "ko": "일시적인 오류가 발생하였습니다. 잠시후 다시 시도해 주세요.",
    "en": "A temporary error has occurred. Please try again later.",
    "id": "Kesalahan sementara telah terjadi. Silakan coba lagi nanti.",
    "ja": "(J)일시적인 오류가 발생하였습니다. 잠시후 다시 시도해 주세요."
  },
  "최근 로그인": {
    "ko": "최근 로그인",
    "en": "Recent Login",
    "id": "Login Terbaru",
    "ja": "(J)최근 로그인"
  },
  "비밀번호 재설정 링크는 발송 후 24시간만 유효합니다.": {
    "ko": "비밀번호 재설정 링크는 발송 후 24시간만 유효합니다.",
    "en": "The password reset link is valid for 24 hours.",
    "id": "Tautan reset kata sandi berlaku selama 24 jam.",
    "ja": "(J)비밀번호 재설정 링크는 발송 후 24시간만 유효합니다."
  },
  "담당자 정보 수정이 필요하면 수정하기 버튼을 클릭하세요.": {
    "ko": "담당자 정보 수정이 필요하면 수정하기 버튼을 클릭하세요.",
    "en": "To change the admin information, please click [Edit].",
    "id": "Untuk mengubah informasi admin, silakan klik [Edit].",
    "ja": "(J)담당자 정보 수정이 필요하면 수정하기 버튼을 클릭하세요."
  },
  "상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다.": {
    "ko": "상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다.",
    "en": "Once the status is set to ‘Resigned’, it cannot be recovered.",
    "id": "Setelah status diatur ke 'pasrah', itu tidak dapat dipulihkan.",
    "ja": "(J)상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다."
  },
  "파트너어드민 로그인": {
    "ko": "파트너 어드민 로그인",
    "en": "Partner Admin Login",
    "id": "Login Admin Mitra",
    "ja": "(J)파트너어드민 로그인"
  },
  "비밀번호 재설정(링크) 메일발송": {
    "ko": "비밀번호 재설정(링크) 메일발송",
    "en": "Send Password Reset (Link) Mail",
    "id": "Kirim Kata Sandi Reset (Tautan) Surat",
    "ja": "(J)비밀번호 재설정(링크) 메일발송"
  },
  "관리자 정보": {
    "ko": "관리자 정보",
    "en": "Admin Information",
    "id": "Informasi Admin",
    "ja": "(J)관리자 정보"
  },
  "관리자ID": {
    "ko": "관리자 ID",
    "en": "Admin ID",
    "id": "ID Admin",
    "ja": "(J)관리자ID"
  },
  "등록된 관리자 정보가 없습니다.": {
    "ko": "등록된 관리자 정보가 없습니다.",
    "en": "Cannot find the admin information!",
    "id": "Tidak dapat menemukan informasi admin!",
    "ja": "(J)등록된 관리자 정보가 없습니다."
  },
  "직급": {
    "ko": "직급",
    "en": "Job Title",
    "id": "Judul pekerjaan",
    "ja": "(J)직급"
  },
  "메뉴유형 선택!": {
    "ko": "메뉴 유형 선택!",
    "en": "Select Menu Type",
    "id": "Pilih jenis menu",
    "ja": "(J)메뉴유형 선택!"
  },
  "권한명 선택!": {
    "ko": "권한명 선택!",
    "en": "Select Permission Name",
    "id": "Pilih Nama Izin",
    "ja": "(J)권한명 선택!"
  },
  "권한 추가": {
    "ko": "권한 추가",
    "en": "Add Permission",
    "id": "Tambahkan izin",
    "ja": "(J)권한 추가"
  },
  "권한 유형": {
    "ko": "권한 유형",
    "en": "Permission Type",
    "id": "Jenis izin",
    "ja": "(J)권한 유형"
  },
  "권한 유형을 선택해주세요.": {
    "ko": "권한 유형을 선택해주세요.",
    "en": "(E)권한 유형을 선택해주세요.",
    "id": "(ID)권한 유형을 선택해주세요.",
    "ja": "(J)권한 유형을 선택해주세요."
  },
  "메뉴유형": {
    "ko": "메뉴 유형",
    "en": "Menu Type",
    "id": "Jenis Menu",
    "ja": "(J)메뉴유형"
  },
  "메뉴유형을 선택해주세요.": {
    "ko": "메뉴 유형을 선택해 주세요.",
    "en": "Please select a menu type.",
    "id": "Pilih jenis menu.",
    "ja": "(J)메뉴유형을 선택해주세요."
  },
  "권한코드": {
    "ko": "권한 코드",
    "en": "Permission Code",
    "id": "Kode izin",
    "ja": "(J)권한코드"
  },
  "권한명": {
    "ko": "권한명",
    "en": "Permission Name",
    "id": "Nama izin",
    "ja": "(J)권한명"
  },
  "권한명을 입력하세요": {
    "ko": "권한명을 입력하세요",
    "en": "Please enter a permission name.",
    "id": "Harap masukkan nama izin.",
    "ja": "(J)권한명을 입력하세요"
  },
  "권한유형": {
    "ko": "권한 유형",
    "en": "Permission Type",
    "id": "Jenis izin",
    "ja": "(J)권한유형"
  },
  "검색어를 입력하세요": {
    "ko": "검색어를 입력하세요",
    "en": "Please enter a keyword.",
    "id": "Harap masukkan kata kunci.",
    "ja": "(J)검색어를 입력하세요"
  },
  "등록된 권한이 없습니다.": {
    "ko": "등록된 권한이 없습니다.",
    "en": "Cannot find the permission!",
    "id": "Tidak dapat menemukan izinnya!",
    "ja": "(J)등록된 권한이 없습니다."
  },
  "권한을 삭제하시겠습니까?": {
    "ko": "권한을 삭제하시겠습니까?",
    "en": "Do you want to delete the permission?",
    "id": "Apakah Anda ingin menghapus izin?",
    "ja": "(J)권한을 삭제하시겠습니까?"
  },
  "권한 상세": {
    "ko": "권한 상세",
    "en": "Permission Details",
    "id": "Detail izin",
    "ja": "(J)권한 상세"
  },
  "리소스 추가": {
    "ko": "리소스 추가",
    "en": "Add Resource",
    "id": "Tambahkan sumber daya",
    "ja": "(J)리소스 추가"
  },
  "리소스 유형": {
    "ko": "리소스 유형",
    "en": "Resource Type",
    "id": "Jenis Sumber Daya",
    "ja": "(J)리소스 유형"
  },
  "리소스유형을 선택해주세요.": {
    "ko": "리소스 유형을 선택해 주세요.",
    "en": "Please select a resource type.",
    "id": "Pilih jenis sumber daya.",
    "ja": "(J)리소스유형을 선택해주세요."
  },
  "url을 입력하세요.": {
    "ko": "url을 입력하세요.",
    "en": "Please enter URL.",
    "id": "Harap masukkan URL.",
    "ja": "(J)url을 입력하세요."
  },
  "내용을 입력해주세요.": {
    "ko": "내용을 입력해 주세요.",
    "en": "Please enter texts.",
    "id": "Harap masukkan teks.",
    "ja": "(J)내용을 입력해주세요."
  },
  "Front URI를 입력하세요.": {
    "ko": "Front URI를 입력하세요.",
    "en": "Please enter front URL.",
    "id": "Harap masukkan URL depan.",
    "ja": "(J)Front URI를 입력하세요."
  },
  "설명을 입력하세요.": {
    "ko": "설명을 입력하세요.",
    "en": "Please enter descriptions.",
    "id": "Harap masukkan deskripsi.",
    "ja": "(J)설명을 입력하세요."
  },
  "개인정보 여부": {
    "ko": "개인정보 여부",
    "en": "Personal Information(Y/N)",
    "id": "Informasi Pribadi (Y/T)",
    "ja": "(J)개인정보 여부"
  },
  "추가하기": {
    "ko": "추가하기",
    "en": "Add",
    "id": "Menambahkan",
    "ja": "(J)추가하기"
  },
  "등록된 리소스가 없습니다.": {
    "ko": "등록된 리소스가 없습니다.",
    "en": "Cannot find the resource!",
    "id": "Tidak dapat menemukan sumber dayanya!",
    "ja": "(J)등록된 리소스가 없습니다."
  },
  "리소스 상세": {
    "ko": "리소스 상세",
    "en": "Resource Details",
    "id": "Detail Sumber Daya",
    "ja": "(J)리소스 상세"
  },
  "개인정보 조회 이력": {
    "ko": "개인정보 조회 이력",
    "en": "Personal Information Search History",
    "id": "Riwayat Pencarian Informasi Pribadi",
    "ja": "(J)개인정보 조회 이력"
  },
  "리소스명": {
    "ko": "리소스명",
    "en": "Resource Name",
    "id": "Nama Sumberdaya",
    "ja": "(J)리소스명"
  },
  "관리자": {
    "ko": "관리자",
    "en": "Admin",
    "id": "Admin",
    "ja": "(J)관리자"
  },
  "접속일시": {
    "ko": "접속 일시",
    "en": "Access Date and Time",
    "id": "Tanggal dan Waktu Akses",
    "ja": "(J)접속일시"
  },
  "Resource 선택": {
    "ko": "Resource 선택",
    "en": "Select Resource",
    "id": "Pilih Sumber Daya",
    "ja": "(J)Resource 선택"
  },
  "최상위 메뉴 추가": {
    "ko": "최상위 메뉴 추가",
    "en": "Add Uppermost Menu",
    "id": "Tambahkan menu paling atas",
    "ja": "(J)최상위 메뉴 추가"
  },
  "저장되었습니다.": {
    "ko": "저장되었습니다.",
    "en": "(E)저장되었습니다.",
    "id": "(ID)저장되었습니다.",
    "ja": "(J)저장되었습니다."
  },
  "를 삭제하시겠습니까?": {
    "ko": "를 삭제하시겠습니까?",
    "en": "(E)를 삭제하시겠습니까?",
    "id": "(ID)를 삭제하시겠습니까?",
    "ja": "(J)를 삭제하시겠습니까?"
  },
  "상위 메뉴": {
    "ko": "상위 메뉴",
    "en": "Upper Menu",
    "id": "Menu atas",
    "ja": "(J)상위 메뉴"
  },
  "메뉴명 (한글)": {
    "ko": "메뉴명 (한글)",
    "en": "Menu Name (Korean)",
    "id": "Nama Menu (Korea)",
    "ja": "(J)메뉴명 (한글)"
  },
  "메뉴명(한글)을 입력해주세요.": {
    "ko": "메뉴명(한글)을 입력해 주세요.",
    "en": "Please enter a menu name (Korean).",
    "id": "Harap masukkan nama menu (Korea).",
    "ja": "(J)메뉴명(한글)을 입력해주세요."
  },
  "한글 메뉴명을 입력해주세요.": {
    "ko": "한글 메뉴명을 입력해 주세요.",
    "en": "Please enter a Korean menu name.",
    "id": "Harap masukkan nama menu Korea.",
    "ja": "(J)한글 메뉴명을 입력해주세요."
  },
  "메뉴명 (영어)": {
    "ko": "메뉴명 (영어)",
    "en": "Menu Name (English)",
    "id": "Nama Menu (Bahasa Inggris)",
    "ja": "(J)메뉴명 (영어)"
  },
  "영어 메뉴명을 입력해주세요.": {
    "ko": "영어 메뉴명을 입력해 주세요.",
    "en": "Please enter an English menu name.",
    "id": "Harap masukkan nama menu bahasa Inggris.",
    "ja": "(J)영어 메뉴명을 입력해주세요."
  },
  "메뉴명 (일어)": {
    "ko": "메뉴명 (일어)",
    "en": "Menu Name (Japanese)",
    "id": "Nama Menu (Jepang)",
    "ja": "(J)메뉴명 (일어)"
  },
  "일어 메뉴명을 입력해주세요.": {
    "ko": "일어 메뉴명을 입력해 주세요.",
    "en": "Please enter a Japanese menu name.",
    "id": "Harap masukkan nama menu Jepang.",
    "ja": "(J)일어 메뉴명을 입력해주세요."
  },
  "메뉴명 (인도네시아어)": {
    "ko": "메뉴명 (인도네시아어)",
    "en": "(E)메뉴명 (인도네시아어)",
    "id": "(ID)메뉴명 (인도네시아어)",
    "ja": "(J)메뉴명 (인도네시아어)"
  },
  "인도네시아어 메뉴명을 입력해주세요.": {
    "ko": "인도네시아어 메뉴명을 입력해주세요.",
    "en": "(E)인도네시아어 메뉴명을 입력해주세요.",
    "id": "(ID)인도네시아어 메뉴명을 입력해주세요.",
    "ja": "(J)인도네시아어 메뉴명을 입력해주세요."
  },
  "리소스를 선택해주세요.": {
    "ko": "리소스를 선택해 주세요.",
    "en": "Please select a resource.",
    "id": "Pilih sumber daya.",
    "ja": "(J)리소스를 선택해주세요."
  },
  "리소스 선택시 자동적으로 입력됩니다.": {
    "ko": "리소스 선택시 자동적으로 입력됩니다.",
    "en": "When a resource is selected, it is automatically entered.",
    "id": "Ketika sumber daya dipilih, ia dimasukkan secara otomatis.",
    "ja": "(J)리소스 선택시 자동적으로 입력됩니다."
  },
  "순서": {
    "ko": "순서",
    "en": "Order",
    "id": "Memesan",
    "ja": "(J)순서"
  },
  "순서를 입력하세요.": {
    "ko": "순서를 입력하세요.",
    "en": "Please enter the order.",
    "id": "Harap masukkan pesanan.",
    "ja": "(J)순서를 입력하세요."
  },
  "메뉴의 순서를 입력해주세요.": {
    "ko": "메뉴의 순서를 입력해 주세요.",
    "en": "Please enter the order in the menu.",
    "id": "Harap masukkan pesanan di menu.",
    "ja": "(J)메뉴의 순서를 입력해주세요."
  },
  "하위 메뉴 추가": {
    "ko": "하위 메뉴 추가",
    "en": "Add Lower Menu",
    "id": "Tambahkan menu yang lebih rendah",
    "ja": "(J)하위 메뉴 추가"
  },
  "권한 설정": {
    "ko": "권한 설정",
    "en": "Permission Setup",
    "id": "Pengaturan Izin",
    "ja": "(J)권한 설정"
  },
  "*비밀번호가 일치하지 않습니다.": {
    "ko": "*비밀번호가 일치하지 않습니다.",
    "en": "The password is incorrect.",
    "id": "Kata sandi salah.",
    "ja": "(J)*비밀번호가 일치하지 않습니다."
  },
  "*이름은 한글 혹은 영어만 가능합니다.": {
    "ko": "*이름은 한글 혹은 영어만 가능합니다.",
    "en": "A name can be in either Korean or English only.",
    "id": "Nama bisa dalam bahasa Korea atau bahasa Inggris saja.",
    "ja": "(J)*이름은 한글 혹은 영어만 가능합니다."
  },
  "*이메일을 정확하게 입력해주세요.": {
    "ko": "*이메일을 정확하게 입력해 주세요.",
    "en": "Please enter a correct email address.",
    "id": "Harap masukkan alamat email yang benar.",
    "ja": "(J)*이메일을 정확하게 입력해주세요."
  },
  "*휴대폰 번호는 ": {
    "ko": "*휴대폰 번호는",
    "en": "Mobile number is",
    "id": "Nomor ponsel adalah",
    "ja": "(J)*휴대폰 번호는 "
  },
  "*아이디는 4글자 이상 설정해야 합니다.": {
    "ko": "*아이디는 4글자 이상 설정해야 합니다.",
    "en": "An ID should be at least 4-character long.",
    "id": "ID harus setidaknya 4 karakter.",
    "ja": "(J)*아이디는 4글자 이상 설정해야 합니다."
  },
  "*비밀번호는 최소 8자, 영문, 숫자, 특수문자를 포함해야 합니다.": {
    "ko": "*비밀번호는 최소 8자, 영문, 숫자, 특수문자를 포함해야 합니다.",
    "en": "A password must have 8 characters or more and contain 1 English letter, 1 number and 1 special character at least.",
    "id": "Kata sandi harus memiliki 8 karakter atau lebih dan berisi 1 surat bahasa Inggris, 1 nomor dan 1 karakter khusus setidaknya.",
    "ja": "(J)*비밀번호는 최소 8자, 영문, 숫자, 특수문자를 포함해야 합니다."
  },
  "운영자 등록이 완료되었습니다.": {
    "ko": "운영자 등록이 완료되었습니다.",
    "en": "Operator registration is completed.",
    "id": "Pendaftaran operator selesai.",
    "ja": "(J)운영자 등록이 완료되었습니다."
  },
  "운영자 정보 메뉴에서 등록된 정보를 확인할 수 있습니다.": {
    "ko": "운영자 정보 메뉴에서 등록된 정보를 확인할 수 있습니다.",
    "en": "The information can be checked in the OPERATOR INFORMATION.",
    "id": "Informasi dapat diperiksa dalam informasi operator.",
    "ja": "(J)운영자 정보 메뉴에서 등록된 정보를 확인할 수 있습니다."
  },
  "운영자 등록하기": {
    "ko": "운영자 등록하기",
    "en": "Add Operator",
    "id": "Tambahkan operator",
    "ja": "(J)운영자 등록하기"
  },
  "담당업무": {
    "ko": "담당업무",
    "en": "Tasks",
    "id": "Tugas",
    "ja": "(J)담당업무"
  },
  "*회사 직원이 아닌 외부인을 담당자로 등록할 수 없습니다.": {
    "ko": "*회사 직원이 아닌 외부인을 담당자로 등록할 수 없습니다.",
    "en": "An outsider cannot be set as the manager.",
    "id": "Orang luar tidak dapat ditetapkan sebagai manajer.",
    "ja": "(J)*회사 직원이 아닌 외부인을 담당자로 등록할 수 없습니다."
  },
  "권한 목록": {
    "ko": "권한 목록",
    "en": "Permission List",
    "id": "Daftar Izin",
    "ja": "(J)권한 목록"
  },
  "선택해주세요.": {
    "ko": "선택해 주세요.",
    "en": "Please select.",
    "id": "Silahkan pilih.",
    "ja": "(J)선택해주세요."
  },
  "해당 없음": {
    "ko": "해당 없음",
    "en": "N/A",
    "id": "N/a",
    "ja": "(J)해당 없음"
  },
  "운영자 정보": {
    "ko": "운영자 정보",
    "en": "Operator Information",
    "id": "Informasi operator",
    "ja": "(J)운영자 정보"
  },
  "구글 OTP": {
    "ko": "구글 OTP",
    "en": "Google OTP",
    "id": "Google OTP",
    "ja": "(J)구글 OTP"
  },
  "미등록": {
    "ko": "미등록",
    "en": "Unregistered",
    "id": "Tidak terdaftar",
    "ja": "(J)미등록"
  },
  "휴대폰": {
    "ko": "휴대폰",
    "en": "Mobile",
    "id": "Mobile",
    "ja": "(J)휴대폰"
  },
  "운영자등록": {
    "ko": "운영자 등록",
    "en": "Add Operator",
    "id": "Tambahkan operator",
    "ja": "(J)운영자등록"
  },
  "등록된 운영자 정보가 없습니다.": {
    "ko": "등록된 운영자 정보가 없습니다.",
    "en": "Cannot find the operator information!",
    "id": "Tidak dapat menemukan informasi operator!",
    "ja": "(J)등록된 운영자 정보가 없습니다."
  },
  "담당": {
    "ko": "담당",
    "en": "Manager",
    "id": "Pengelola",
    "ja": "(J)담당"
  },
  "코드 추가": {
    "ko": "코드 추가",
    "en": "Add Code",
    "id": "Tambahkan kode",
    "ja": "(J)코드 추가"
  },
  "그룹코드": {
    "ko": "그룹 코드",
    "en": "Group Code",
    "id": "Kode grup",
    "ja": "(J)그룹코드"
  },
  "그룹코드를 선택해주세요.": {
    "ko": "그룹코드를 선택해 주세요.",
    "en": "Please select a group code.",
    "id": "Pilih kode grup.",
    "ja": "(J)그룹코드를 선택해주세요."
  },
  "코드": {
    "ko": "코드",
    "en": "Code",
    "id": "Kode",
    "ja": "(J)코드"
  },
  "코드명을 입력하세요": {
    "ko": "코드명을 입력하세요",
    "en": "Please enter a code name.",
    "id": "Harap masukkan nama kode.",
    "ja": "(J)코드명을 입력하세요"
  },
  "코드명 (한글)": {
    "ko": "코드명 (한글)",
    "en": "Code Name (Korean)",
    "id": "Nama Kode (Korea)",
    "ja": "(J)코드명 (한글)"
  },
  "내용을 입력하세요": {
    "ko": "내용을 입력하세요",
    "en": "Please enter texts.",
    "id": "Harap masukkan teks.",
    "ja": "(J)내용을 입력하세요"
  },
  "코드명 (영어)": {
    "ko": "코드명 (영어)",
    "en": "Code Name (English)",
    "id": "Nama Kode (Bahasa Inggris)",
    "ja": "(J)코드명 (영어)"
  },
  "코드명 (일어)": {
    "ko": "코드명 (일어)",
    "en": "Code Name (Japanese)",
    "id": "Nama Kode (Jepang)",
    "ja": "(J)코드명 (일어)"
  },
  "코드명 (인도네시아어)": {
    "ko": "코드명 (인도네시아어)",
    "en": "(E)코드명 (인도네시아어)",
    "id": "(ID)코드명 (인도네시아어)",
    "ja": "(J)코드명 (인도네시아어)"
  },
  "상위 그룹코드": {
    "ko": "상위 그룹코드",
    "en": "Upper Group Code",
    "id": "Kode Grup Atas",
    "ja": "(J)상위 그룹코드"
  },
  "상위 코드": {
    "ko": "상위 코드",
    "en": "Upper Code",
    "id": "Kode atas",
    "ja": "(J)상위 코드"
  },
  "비고": {
    "ko": "비고",
    "en": "Remarks",
    "id": "Perkataan",
    "ja": "(J)비고"
  },
  "옵션1": {
    "ko": "옵션 1",
    "en": "Option 1",
    "id": "Pilihan 1",
    "ja": "(J)옵션1"
  },
  "옵션2": {
    "ko": "옵션 2",
    "en": "Option 2",
    "id": "pilihan 2",
    "ja": "(J)옵션2"
  },
  "내용을 입력해주세요": {
    "ko": "내용을 입력해 주세요",
    "en": "Please enter texts.",
    "id": "Harap masukkan teks.",
    "ja": "(J)내용을 입력해주세요"
  },
  "그룹코드명": {
    "ko": "그룹 코드명",
    "en": "Group Code Name",
    "id": "Nama kode grup",
    "ja": "(J)그룹코드명"
  },
  "코드명": {
    "ko": "코드명",
    "en": "Code Name",
    "id": "Nama kode",
    "ja": "(J)코드명"
  },
  "상위코드": {
    "ko": "상위 코드",
    "en": "Upper Code",
    "id": "Kode atas",
    "ja": "(J)상위코드"
  },
  "상위그룹코드": {
    "ko": "상위 그룹 코드",
    "en": "Upper Group Code",
    "id": "Kode Grup Atas",
    "ja": "(J)상위그룹코드"
  },
  "등록된 코드가 없습니다.": {
    "ko": "등록된 코드가 없습니다.",
    "en": "Cannot find the code!",
    "id": "Tidak dapat menemukan kodenya!",
    "ja": "(J)등록된 코드가 없습니다."
  },
  "상위그룹코드명": {
    "ko": "상위 그룹 코드명",
    "en": "Upper Group Code Name",
    "id": "Nama Kode Grup Atas",
    "ja": "(J)상위그룹코드명"
  },
  "상위코드명": {
    "ko": "상위 코드명",
    "en": "Upper Code Name",
    "id": "Nama Kode Atas",
    "ja": "(J)상위코드명"
  },
  "코드 수정": {
    "ko": "코드 수정",
    "en": "Edit Code",
    "id": "Edit kode",
    "ja": "(J)코드 수정"
  },
  "상위코드를 선택해주세요.": {
    "ko": "상위 코드를 선택해 주세요.",
    "en": "Please select an upper code.",
    "id": "Pilih kode atas.",
    "ja": "(J)상위코드를 선택해주세요."
  },
  "템플릿 ID": {
    "ko": "템플릿 ID",
    "en": "(E)템플릿 ID",
    "id": "(ID)템플릿 ID",
    "ja": "(J)템플릿 ID"
  },
  "TMT-00XXX 형식으로 ID를 입력하세요": {
    "ko": "TMT-00XXX 형식으로 ID를 입력하세요",
    "en": "(E)TMT-00XXX 형식으로 ID를 입력하세요",
    "id": "(ID)TMT-00XXX 형식으로 ID를 입력하세요",
    "ja": "(J)TMT-00XXX 형식으로 ID를 입력하세요"
  },
  "아이콘": {
    "ko": "아이콘",
    "en": "(E)아이콘",
    "id": "(ID)아이콘",
    "ja": "(J)아이콘"
  },
  "콘텐츠 유형": {
    "ko": "콘텐츠 유형",
    "en": "(E)콘텐츠 유형",
    "id": "(ID)콘텐츠 유형",
    "ja": "(J)콘텐츠 유형"
  },
  "수신 대상자": {
    "ko": "수신 대상자",
    "en": "(E)수신 대상자",
    "id": "(ID)수신 대상자",
    "ja": "(J)수신 대상자"
  },
  "알림": {
    "ko": "알림",
    "en": "(E)알림",
    "id": "(ID)알림",
    "ja": "(J)알림"
  },
  "링크를 입력하세요": {
    "ko": "링크를 입력하세요",
    "en": "(E)링크를 입력하세요",
    "id": "(ID)링크를 입력하세요",
    "ja": "(J)링크를 입력하세요"
  },
  "케어 메시지 템플릿 추가": {
    "ko": "케어 메시지 템플릿 추가",
    "en": "(E)케어 메시지 템플릿 추가",
    "id": "(ID)케어 메시지 템플릿 추가",
    "ja": "(J)케어 메시지 템플릿 추가"
  },
  "없음": {
    "ko": "없음",
    "en": "(E)없음",
    "id": "(ID)없음",
    "ja": "(J)없음"
  },
  "푸시 타이틀": {
    "ko": "푸시 타이틀",
    "en": "(E)푸시 타이틀",
    "id": "(ID)푸시 타이틀",
    "ja": "(J)푸시 타이틀"
  },
  "푸시 타이틀을 입력하세요": {
    "ko": "푸시 타이틀을 입력하세요",
    "en": "(E)푸시 타이틀을 입력하세요",
    "id": "(ID)푸시 타이틀을 입력하세요",
    "ja": "(J)푸시 타이틀을 입력하세요"
  },
  "푸시 메시지": {
    "ko": "푸시 메시지",
    "en": "(E)푸시 메시지",
    "id": "(ID)푸시 메시지",
    "ja": "(J)푸시 메시지"
  },
  "푸시 메시지를 입력하세요": {
    "ko": "푸시 메시지를 입력하세요",
    "en": "(E)푸시 메시지를 입력하세요",
    "id": "(ID)푸시 메시지를 입력하세요",
    "ja": "(J)푸시 메시지를 입력하세요"
  },
  "케어 메시지": {
    "ko": "케어 메시지",
    "en": "(E)케어 메시지",
    "id": "(ID)케어 메시지",
    "ja": "(J)케어 메시지"
  },
  "케어 메시지를 입력하세요": {
    "ko": "케어 메시지를 입력하세요",
    "en": "(E)케어 메시지를 입력하세요",
    "id": "(ID)케어 메시지를 입력하세요",
    "ja": "(J)케어 메시지를 입력하세요"
  },
  "팁": {
    "ko": "팁",
    "en": "(E)팁",
    "id": "(ID)팁",
    "ja": "(J)팁"
  },
  "팁을 입력하세요": {
    "ko": "팁을 입력하세요",
    "en": "(E)팁을 입력하세요",
    "id": "(ID)팁을 입력하세요",
    "ja": "(J)팁을 입력하세요"
  },
  "추천 메시지": {
    "ko": "추천 메시지",
    "en": "(E)추천 메시지",
    "id": "(ID)추천 메시지",
    "ja": "(J)추천 메시지"
  },
  "추천 메시지를 입력하세요": {
    "ko": "추천 메시지를 입력하세요",
    "en": "(E)추천 메시지를 입력하세요",
    "id": "(ID)추천 메시지를 입력하세요",
    "ja": "(J)추천 메시지를 입력하세요"
  },
  "서비스 페이지 메시지": {
    "ko": "서비스 페이지 메시지",
    "en": "(E)서비스 페이지 메시지",
    "id": "(ID)서비스 페이지 메시지",
    "ja": "(J)서비스 페이지 메시지"
  },
  "서비스 페이지 메시지를 입력하세요": {
    "ko": "서비스 페이지 메시지를 입력하세요",
    "en": "(E)서비스 페이지 메시지를 입력하세요",
    "id": "(ID)서비스 페이지 메시지를 입력하세요",
    "ja": "(J)서비스 페이지 메시지를 입력하세요"
  },
  "2자 이상 입력하세요.": {
    "ko": "2자 이상 입력하세요.",
    "en": "Please enter at least 2 characters.",
    "id": "Harap masukkan setidaknya 2 karakter.",
    "ja": "(J)2자 이상 입력하세요."
  },
  "차량번호를 입력하세요.": {
    "ko": "차량번호를 입력하세요.",
    "en": "Please enter the license plate number.",
    "id": "Harap masukkan nomor plat.",
    "ja": "(J)차량번호를 입력하세요."
  },
  "발송차량": {
    "ko": "발송차량",
    "en": "(E)발송차량",
    "id": "(ID)발송차량",
    "ja": "(J)발송차량"
  },
  "어드민 알림": {
    "ko": "어드민 알림",
    "en": "(E)어드민 알림",
    "id": "(ID)어드민 알림",
    "ja": "(J)어드민 알림"
  },
  "발송": {
    "ko": "발송",
    "en": "(E)발송",
    "id": "(ID)발송",
    "ja": "(J)발송"
  },
  "시험 발송": {
    "ko": "시험 발송",
    "en": "(E)시험 발송",
    "id": "(ID)시험 발송",
    "ja": "(J)시험 발송"
  },
  "케어 메시지 템플릿 정보": {
    "ko": "케어 메시지 템플릿 정보",
    "en": "(E)케어 메시지 템플릿 정보",
    "id": "(ID)케어 메시지 템플릿 정보",
    "ja": "(J)케어 메시지 템플릿 정보"
  },
  "템플릿 추가": {
    "ko": "템플릿 추가",
    "en": "(E)템플릿 추가",
    "id": "(ID)템플릿 추가",
    "ja": "(J)템플릿 추가"
  },
  "수신자": {
    "ko": "수신자",
    "en": "(E)수신자",
    "id": "(ID)수신자",
    "ja": "(J)수신자"
  },
  "타이틀": {
    "ko": "타이틀",
    "en": "(E)타이틀",
    "id": "(ID)타이틀",
    "ja": "(J)타이틀"
  },
  "메시지": {
    "ko": "메시지",
    "en": "(E)메시지",
    "id": "(ID)메시지",
    "ja": "(J)메시지"
  },
  "소유": {
    "ko": "소유",
    "en": "Owned",
    "id": "Dimiliki",
    "ja": "(J)소유"
  },
  "오토 도어락": {
    "ko": "오토도어락",
    "en": "AUTO Door Lock",
    "id": "Kunci Pintu Otomatis",
    "ja": "(J)오토 도어락"
  },
  "잠금 버튼 동작 시간": {
    "ko": "잠금 버튼 동작 시간",
    "en": "Lock Button ON Time",
    "id": "Tombol kunci tepat waktu",
    "ja": "(J)잠금 버튼 동작 시간"
  },
  "잠금 버튼 동작 횟수": {
    "ko": "잠금 버튼 동작 횟수",
    "en": "No. of Lock Button Pressed",
    "id": "Jumlah tombol kunci ditekan",
    "ja": "(J)잠금 버튼 동작 횟수"
  },
  "잠금 버튼 동작 감도": {
    "ko": "잠금 버튼 동작 감도",
    "en": "Lock Button ON Sensitivity",
    "id": "Tombol kunci pada sensitivitas",
    "ja": "(J)잠금 버튼 동작 감도"
  },
  "잠금 해제 버튼 동작 시간": {
    "ko": "잠금 해제 버튼 동작 시간",
    "en": "Unlock Button ON Time",
    "id": "Buka tombol Tepat Waktu",
    "ja": "(J)잠금 해제 버튼 동작 시간"
  },
  "잠금 해제 버튼 동작 횟수": {
    "ko": "잠금 해제 버튼 동작 횟수",
    "en": "No. of Unlock Button Pressed",
    "id": "Jumlah tombol buka kunci ditekan",
    "ja": "(J)잠금 해제 버튼 동작 횟수"
  },
  "잠금 해제 버튼 동작 감도": {
    "ko": "잠금 해제 버튼 동작 감도",
    "en": "Unlock Button ON Sensitivity",
    "id": "Buka kunci tombol pada sensitivitas",
    "ja": "(J)잠금 해제 버튼 동작 감도"
  },
  "발렛키 등록": {
    "ko": "발렛키 등록",
    "en": "Add Valet Key",
    "id": "Tambahkan Kunci Valet",
    "ja": "(J)발렛키 등록"
  },
  "Y(등록)": {
    "ko": "Y (등록)",
    "en": "Y (Added)",
    "id": "Y (Ditambahkan)",
    "ja": "(J)Y(등록)"
  },
  "N(미등록)": {
    "ko": "N (미등록)",
    "en": "N (Not added)",
    "id": "N (Tidak ditambahkan)",
    "ja": "(J)N(미등록)"
  },
  "발렛 설정": {
    "ko": "발렛 설정",
    "en": "Valet Setup",
    "id": "Pengaturan Valet",
    "ja": "(J)발렛 설정"
  },
  "아차키 1.0": {
    "ko": "아차키 1.0",
    "en": "Achakey 1.0",
    "id": "Achakey 1.0",
    "ja": "(J)아차키 1.0"
  },
  "아차키 2.0": {
    "ko": "아차키 2.0",
    "en": "Achakey 2.0",
    "id": "Achakey 2.0",
    "ja": "(J)아차키 2.0"
  },
  "발렛키": {
    "ko": "발렛키",
    "en": "Valet Key",
    "id": "Kunci Valet",
    "ja": "(J)발렛키"
  },
  "등록 자동차 정보": {
    "ko": "등록 자동차 정보",
    "en": "Car Information",
    "id": "Informasi mobil",
    "ja": "(J)등록 자동차 정보"
  },
  "위치권한": {
    "ko": "위치 권한",
    "en": "Location Permission",
    "id": "Izin lokasi",
    "ja": "(J)위치권한"
  },
  "Y(항상)": {
    "ko": "Y (항상)",
    "en": "Y (Always)",
    "id": "Y (Selalu)",
    "ja": "(J)Y(항상)"
  },
  "N(앱 사용중에만, 항상확인, 허용안함)": {
    "ko": "N(앱 사용중에만, 항상확인, 허용안함)",
    "en": "N (while the app is in use, check all the time, not allowed)",
    "id": "N (saat aplikasi sedang digunakan, periksa sepanjang waktu, tidak diizinkan)",
    "ja": "(J)N(앱 사용중에만, 항상확인, 허용안함)"
  },
  "블루투스 설정": {
    "ko": "블루투스 설정",
    "en": "Bluetooth Setup",
    "id": "Pengaturan Bluetooth",
    "ja": "(J)블루투스 설정"
  },
  "등록 디바이스": {
    "ko": "등록 디바이스",
    "en": "Registered Device",
    "id": "Perangkat terdaftar",
    "ja": "(J)등록 디바이스"
  },
  "배터리최적화": {
    "ko": "배터리 최적화",
    "en": "Battery Optimization",
    "id": "Optimasi baterai",
    "ja": "(J)배터리최적화"
  },
  "휴대폰 설정": {
    "ko": "휴대폰 설정",
    "en": "Mobile Phone Setup",
    "id": "Pengaturan Ponsel",
    "ja": "(J)휴대폰 설정"
  },
  "0에 가까울수록 신호의 강도가 강함": {
    "ko": "0에 가까울수록 신호의 강도가 강함",
    "en": "The closer the number is to 0, the stronger your signal.",
    "id": "Semakin dekat angkanya ke 0, semakin kuat sinyal Anda.",
    "ja": "(J)0에 가까울수록 신호의 강도가 강함"
  },
  "* 배터리최적화는 안드로이드OS를 사용하는 휴대폰만 수집되는 정보입니다.": {
    "ko": "* 배터리최적화는 안드로이드OS를 사용하는 휴대폰만 수집되는 정보입니다.",
    "en": "Battery optimization refers to the information about Android phones.",
    "id": "Optimalisasi baterai mengacu pada informasi tentang ponsel Android.",
    "ja": "(J)* 배터리최적화는 안드로이드OS를 사용하는 휴대폰만 수집되는 정보입니다."
  },
  "휴대폰 OS": {
    "ko": "휴대폰 OS",
    "en": "Mobile OS",
    "id": "OS seluler",
    "ja": "(J)휴대폰 OS"
  },
  "OS버젼": {
    "ko": "OS 버젼",
    "en": "OS Version",
    "id": "Versi OS",
    "ja": "(J)OS버젼"
  },
  "휴대폰 모델명": {
    "ko": "휴대폰 모델명",
    "en": "Mobile Model No.",
    "id": "Model Seluler No.",
    "ja": "(J)휴대폰 모델명"
  },
  "앱 및 기타정보": {
    "ko": "앱 및 기타정보",
    "en": "App and Other Information",
    "id": "Aplikasi dan informasi lainnya",
    "ja": "(J)앱 및 기타정보"
  },
  "상태점검 상세 정보": {
    "ko": "상태 점검 상세 정보",
    "en": "Status Information",
    "id": "Informasi status",
    "ja": "(J)상태점검 상세 정보"
  },
  "회원정보수정": {
    "ko": "회원정보 수정",
    "en": "Edit Member Information",
    "id": "Edit informasi anggota",
    "ja": "(J)회원정보수정"
  },
  "TYPE 구 시스템의 회원정보에서 회원 / 개발자 / 인스톨러 / 일본테스터를 분류하던 개념입니다.": {
    "ko": "TYPE 구 시스템의 회원정보에서 회원 / 개발자 / 인스톨러 / 일본테스터를 분류하던 개념입니다.",
    "en": "Classification of members, developers, installers and Japanese testers in MEMBER INFORMATION on the old system",
    "id": "Klasifikasi Anggota, Pengembang, Pemasang dan Penguji Jepang dalam Informasi Anggota tentang Sistem Lama",
    "ja": "(J)TYPE 구 시스템의 회원정보에서 회원 / 개발자 / 인스톨러 / 일본테스터를 분류하던 개념입니다."
  },
  "BIZcode는 아차키BIZ를 이용하는 기업 고객 사용자를 기업별로 분류하는 코드입니다.": {
    "ko": "BIZcode는 아차키BIZ를 이용하는 기업 고객 사용자를 기업별로 분류하는 코드입니다.",
    "en": "BIZ Code is a code classifying business clients using Achakey BIZ by company.",
    "id": "Biz Code adalah kode yang mengklasifikasikan klien bisnis menggunakan Achakey Biz oleh perusahaan.",
    "ja": "(J)BIZcode는 아차키BIZ를 이용하는 기업 고객 사용자를 기업별로 분류하는 코드입니다."
  },
  "기업아이디": {
    "ko": "기업 아이디",
    "en": "Company ID",
    "id": "identitas perusahaan",
    "ja": "(J)기업아이디"
  },
  "서비스유형": {
    "ko": "서비스 유형",
    "en": "Service Type",
    "id": "Jenis Layanan",
    "ja": "(J)서비스유형"
  },
  "계약일": {
    "ko": "계약일",
    "en": "Contract Date",
    "id": "Tanggal kontrak",
    "ja": "(J)계약일"
  },
  "기업 정보": {
    "ko": "기업 정보",
    "en": "Company Information",
    "id": "informasi perusahaan",
    "ja": "(J)기업 정보"
  },
  "아차키Biz 상세 정보는 아차키Biz 마스터 어드민에서 확인하세요.": {
    "ko": "아차키Biz 상세 정보는 아차키Biz 마스터 어드민에서 확인하세요.",
    "en": "Check Achakey BIZ information in the ACHAKEY BIZ MASTER ADMIN.",
    "id": "Periksa informasi Achakey Biz di Achakey Biz Master Admin.",
    "ja": "(J)아차키Biz 상세 정보는 아차키Biz 마스터 어드민에서 확인하세요."
  },
  "Biz 소속 정보": {
    "ko": "Biz 소속 정보",
    "en": "BIZ Information",
    "id": "Informasi Biz",
    "ja": "(J)Biz 소속 정보"
  },
  "등록된 회원정보가 없습니다.": {
    "ko": "등록된 회원정보가 없습니다.",
    "en": "Cannot find the member information!",
    "id": "Tidak dapat menemukan informasi anggota!",
    "ja": "(J)등록된 회원정보가 없습니다."
  },
  "가입일시": {
    "ko": "가입 일시",
    "en": "Signup Date and Time",
    "id": "Tanggal dan Waktu Pendaftaran",
    "ja": "(J)가입일시"
  },
  "최종로그인": {
    "ko": "최종 로그인",
    "en": "Last Login",
    "id": "Login terakhir",
    "ja": "(J)최종로그인"
  },
  "회원 목록 다운로드": {
    "ko": "회원 목록 다운로드",
    "en": "(E)회원 목록 다운로드",
    "id": "(ID)회원 목록 다운로드",
    "ja": "(J)회원 목록 다운로드"
  },
  "내차": {
    "ko": "내 차",
    "en": "My Car",
    "id": "Mobil saya",
    "ja": "(J)내차"
  },
  "공유차": {
    "ko": "공유 차",
    "en": "Share Car",
    "id": "Bagikan Mobil",
    "ja": "(J)공유차"
  },
  "회사차": {
    "ko": "회사 차",
    "en": "Company Car",
    "id": "Mobil perusahaan",
    "ja": "(J)회사차"
  },
  "등록된 차량이 없습니다.": {
    "ko": "등록된 차량이 없습니다.",
    "en": "Cannot find the car!",
    "id": "Tidak dapat menemukan mobilnya!",
    "ja": "(J)등록된 차량이 없습니다."
  },
  "QR 코드": {
    "ko": "QR 코드",
    "en": "QR Code",
    "id": "Kode QR",
    "ja": "(J)QR 코드"
  },
  "닫기": {
    "ko": "닫기",
    "en": "Close",
    "id": "Menutup",
    "ja": "(J)닫기"
  },
  "삭제 내역이 없습니다.": {
    "ko": "삭제 내역이 없습니다.",
    "en": "(E)삭제 내역이 없습니다.",
    "id": "(ID)삭제 내역이 없습니다.",
    "ja": "(J)삭제 내역이 없습니다."
  },
  "QR코드": {
    "ko": "QR코드",
    "en": "QR Code",
    "id": "Kode QR",
    "ja": "(J)QR코드"
  },
  "삭제 내역": {
    "ko": "삭제 내역",
    "en": "Deletion Details",
    "id": "Detail penghapusan",
    "ja": "(J)삭제 내역"
  },
  "상태점검": {
    "ko": "상태 점검",
    "en": "Status Check",
    "id": "Pemeriksaan status",
    "ja": "(J)상태점검"
  },
  "등록된 상태점검이 없습니다.": {
    "ko": "등록된 상태 점검이 없습니다.",
    "en": "Cannot find the Status Check!",
    "id": "Tidak dapat menemukan pemeriksaan statusnya!",
    "ja": "(J)등록된 상태점검이 없습니다."
  },
  "휴대폰정보": {
    "ko": "휴대폰 정보",
    "en": "Mobile Information",
    "id": "Informasi seluler",
    "ja": "(J)휴대폰정보"
  },
  "점검일시": {
    "ko": "점검 일시",
    "en": "Check Date and Time",
    "id": "Periksa Tanggal dan Waktu",
    "ja": "(J)점검일시"
  },
  "Biz 기업 정보가 없습니다.": {
    "ko": "Biz 기업 정보가 없습니다.",
    "en": "Cannot find the BIZ information!",
    "id": "Tidak dapat menemukan informasi bisnis!",
    "ja": "(J)Biz 기업 정보가 없습니다."
  },
  "발송 완료": {
    "ko": "발송 완료",
    "en": "(E)발송 완료",
    "id": "(ID)발송 완료",
    "ja": "(J)발송 완료"
  },
  "임시비밀번호가 발송되었습니다.": {
    "ko": "임시 비밀번호가 발송되었습니다.",
    "en": "A temporary password has been sent.",
    "id": "Kata sandi sementara telah dikirim.",
    "ja": "(J)임시비밀번호가 발송되었습니다."
  },
  "국가번호": {
    "ko": "국가번호",
    "en": "Country Code",
    "id": "Kode negara",
    "ja": "(J)국가번호"
  },
  "회원인증유형": {
    "ko": "회원인증유형",
    "en": "(E)회원인증유형",
    "id": "(ID)회원인증유형",
    "ja": "(J)회원인증유형"
  },
  "휴대전화": {
    "ko": "휴대전화",
    "en": "(E)휴대전화",
    "id": "(ID)휴대전화",
    "ja": "(J)휴대전화"
  },
  "본인인증": {
    "ko": "본인인증",
    "en": "(E)본인인증",
    "id": "(ID)본인인증",
    "ja": "(J)본인인증"
  },
  "기업정보": {
    "ko": "기업 정보",
    "en": "Company Information",
    "id": "informasi perusahaan",
    "ja": "(J)기업정보"
  },
  "회원정보": {
    "ko": "회원정보",
    "en": "Member Information",
    "id": "Informasi anggota",
    "ja": "(J)회원정보"
  },
  "회원정보수정하기": {
    "ko": "회원정보 수정하기",
    "en": "Edit Member Information",
    "id": "Edit informasi anggota",
    "ja": "(J)회원정보수정하기"
  },
  "통신사인증 완료": {
    "ko": "통신사인증 완료",
    "en": "(E)통신사인증 완료",
    "id": "(ID)통신사인증 완료",
    "ja": "(J)통신사인증 완료"
  },
  "미인증": {
    "ko": "미인증",
    "en": "Unauthenticated",
    "id": "Tidak otentikasi",
    "ja": "(J)미인증"
  },
  "기업 정보 확인하기": {
    "ko": "기업 정보 확인하기",
    "en": "Check Company Information",
    "id": "Periksa informasi perusahaan",
    "ja": "(J)기업 정보 확인하기"
  },
  "회원인증완료": {
    "ko": "회원인증완료",
    "en": "(E)회원인증완료",
    "id": "(ID)회원인증완료",
    "ja": "(J)회원인증완료"
  },
  "재시도": {
    "ko": "재시도",
    "en": "(E)재시도",
    "id": "(ID)재시도",
    "ja": "(J)재시도"
  },
  "회원정보상세": {
    "ko": "회원정보 상세",
    "en": "Member Information Details",
    "id": "Detail Informasi Anggota",
    "ja": "(J)회원정보상세"
  },
  "위치명": {
    "ko": "위치명",
    "en": "(E)위치명",
    "id": "(ID)위치명",
    "ja": "(J)위치명"
  },
  "등록일": {
    "ko": "등록일",
    "en": "(E)등록일",
    "id": "(ID)등록일",
    "ja": "(J)등록일"
  },
  "블록존 목록": {
    "ko": "블록존 목록",
    "en": "(E)블록존 목록",
    "id": "(ID)블록존 목록",
    "ja": "(J)블록존 목록"
  },
  "설정된 블록존이 없습니다.": {
    "ko": "설정된 블록존이 없습니다.",
    "en": "(E)설정된 블록존이 없습니다.",
    "id": "(ID)설정된 블록존이 없습니다.",
    "ja": "(J)설정된 블록존이 없습니다."
  },
  "성별": {
    "ko": "성별",
    "en": "Gender",
    "id": "Jenis kelamin",
    "ja": "(J)성별"
  },
  "남자": {
    "ko": "남자",
    "en": "Male",
    "id": "Pria",
    "ja": "(J)남자"
  },
  "여자": {
    "ko": "여자",
    "en": "Female",
    "id": "Perempuan",
    "ja": "(J)여자"
  },
  "나이": {
    "ko": "나이",
    "en": "Age",
    "id": "Usia",
    "ja": "(J)나이"
  },
  "생일": {
    "ko": "생일",
    "en": "Date of Birth",
    "id": "Tanggal lahir",
    "ja": "(J)생일"
  },
  "비밀번호 재설정": {
    "ko": "비밀번호 재설정",
    "en": "Reset Password",
    "id": "Setel ulang kata sandi",
    "ja": "(J)비밀번호 재설정"
  },
  "신규 비밀번호 설정": {
    "ko": "신규 비밀번호 설정",
    "en": "New Password Setup",
    "id": "Pengaturan Kata Sandi Baru",
    "ja": "(J)신규 비밀번호 설정"
  },
  "신규 비밀번호": {
    "ko": "신규 비밀번호",
    "en": "New Password",
    "id": "kata sandi baru",
    "ja": "(J)신규 비밀번호"
  },
  "비밀번호를 입력하세요.": {
    "ko": "비밀번호를 입력하세요.",
    "en": "Please enter a password.",
    "id": "Harap masukkan kata sandi.",
    "ja": "(J)비밀번호를 입력하세요."
  },
  "영문, 숫자, 특수문자 포함 최소 8자리 이상을 입력하세요.": {
    "ko": "영문, 숫자, 특수문자 포함 최소 8자리 이상을 입력하세요.",
    "en": "A password must have 8 characters or more and contain 1 English letter, 1 number and 1 special character at least.",
    "id": "Kata sandi harus memiliki 8 karakter atau lebih dan berisi 1 surat bahasa Inggris, 1 nomor dan 1 karakter khusus setidaknya.",
    "ja": "(J)영문, 숫자, 특수문자 포함 최소 8자리 이상을 입력하세요."
  },
  "영문, 숫자, 특수문자 포함 최소 8자리 이상": {
    "ko": "영문, 숫자, 특수문자 포함 최소 8자리 이상",
    "en": "8 characters or more including 1 English letter, 1 number and 1 special character at least",
    "id": "8 karakter atau lebih termasuk 1 surat bahasa Inggris, 1 nomor dan 1 karakter khusus setidaknya",
    "ja": "(J)영문, 숫자, 특수문자 포함 최소 8자리 이상"
  },
  "비밀번호가 일치하지 않습니다. 다시 입력하세요.": {
    "ko": "비밀번호가 일치하지 않습니다. 다시 입력하세요.",
    "en": "The password is incorrect. Please enter again.",
    "id": "Kata sandi salah. Harap masukkan lagi.",
    "ja": "(J)비밀번호가 일치하지 않습니다. 다시 입력하세요."
  },
  "비밀번호를 다시 입력하세요.": {
    "ko": "비밀번호를 다시 입력하세요.",
    "en": "Please enter the password again.",
    "id": "Harap masukkan kata sandi lagi.",
    "ja": "(J)비밀번호를 다시 입력하세요."
  },
  "인증코드를 재발송하였습니다.": {
    "ko": "인증코드를 재발송하였습니다.",
    "en": "(E)인증코드를 재발송하였습니다.",
    "id": "(ID)인증코드를 재발송하였습니다.",
    "ja": "(J)인증코드를 재발송하였습니다."
  },
  "이메일 인증": {
    "ko": "이메일 인증",
    "en": "Email Authentication",
    "id": "Otentikasi Email",
    "ja": "(J)이메일 인증"
  },
  "이메일 인증코드": {
    "ko": "이메일 인증코드",
    "en": "Email Authentication Code",
    "id": "Kode otentikasi email",
    "ja": "(J)이메일 인증코드"
  },
  "이메일 인증코드를 입력하세요.": {
    "ko": "이메일 인증코드를 입력하세요.",
    "en": "Please enter an email authentication code.",
    "id": "Harap masukkan kode otentikasi email.",
    "ja": "(J)이메일 인증코드를 입력하세요."
  },
  "인증코드 재발송": {
    "ko": "인증코드 재발송",
    "en": "Resend Authentication Code",
    "id": "Ulang Kode Otentikasi",
    "ja": "(J)인증코드 재발송"
  },
  "남은 시간": {
    "ko": "남은 시간",
    "en": "Remaining Time",
    "id": "Waktu yang tersisa",
    "ja": "(J)남은 시간"
  },
  "다음": {
    "ko": "다음",
    "en": "Next",
    "id": "Berikutnya",
    "ja": "(J)다음"
  },
  "현재 비밀번호를 입력하세요.": {
    "ko": "현재 비밀번호를 입력하세요.",
    "en": "Please enter the current password.",
    "id": "Harap masukkan kata sandi saat ini.",
    "ja": "(J)현재 비밀번호를 입력하세요."
  },
  "현재 비밀번호를 다시 사용할 수 없습니다.": {
    "ko": "현재 비밀번호를 다시 사용할 수 없습니다.",
    "en": "Cannot use the current password anymore!",
    "id": "Tidak dapat menggunakan kata sandi saat ini lagi!",
    "ja": "(J)현재 비밀번호를 다시 사용할 수 없습니다."
  },
  "로그인": {
    "ko": "로그인",
    "en": "Login",
    "id": "Gabung",
    "ja": "(J)로그인"
  },
  "해당 사이트는 허가된 사람만 접속할 수 있습니다.": {
    "ko": "해당 사이트는 허가된 사람만 접속할 수 있습니다.",
    "en": "An authorized person is only permitted to access to the site.",
    "id": "Orang yang berwenang hanya diizinkan untuk mengakses Situs.",
    "ja": "(J)해당 사이트는 허가된 사람만 접속할 수 있습니다."
  },
  "아이디, 비밀번호를 분실한 경우 관리자에게 문의해주세요.": {
    "ko": "아이디, 비밀번호를 분실한 경우 관리자에게 문의해 주세요.",
    "en": "If ID or password is lost, please contact the admin.",
    "id": "Jika ID atau kata sandi hilang, silakan hubungi admin.",
    "ja": "(J)아이디, 비밀번호를 분실한 경우 관리자에게 문의해주세요."
  },
  "운영자 인증 및 등록 완료": {
    "ko": "운영자 인증 및 등록 완료",
    "en": "Operator authentication and registration completed!",
    "id": "Otentikasi dan pendaftaran operator selesai!",
    "ja": "(J)운영자 인증 및 등록 완료"
  },
  "시스템에 등록된 회사 이메일로 발송된 인증코드와 구글OTP에서 생성된 인증코드를 입력해주세요.": {
    "ko": "시스템에 등록된 회사 이메일로 발송된 인증코드와 구글 OTP에서 생성된 인증코드를 입력해 주세요.",
    "en": "Please enter the authentication code sent to the company email and the Google OTP authentication code.",
    "id": "Harap masukkan kode otentikasi yang dikirim ke email perusahaan dan kode otentikasi Google OTP.",
    "ja": "(J)시스템에 등록된 회사 이메일로 발송된 인증코드와 구글OTP에서 생성된 인증코드를 입력해주세요."
  },
  "구글OTP 인증코드": {
    "ko": "구글 OTP 인증코드",
    "en": "Google OTP Authentication Code",
    "id": "Kode otentikasi Google OTP",
    "ja": "(J)구글OTP 인증코드"
  },
  "구글OTP 인증코드를 입력하세요.": {
    "ko": "구글 OTP 인증코드를 입력하세요.",
    "en": "Please enter the Google OTP authentication code.",
    "id": "Harap masukkan kode otentikasi Google OTP.",
    "ja": "(J)구글OTP 인증코드를 입력하세요."
  },
  "시크릿키가 복사되었습니다.": {
    "ko": "시크릿키가 복사되었습니다.",
    "en": "A secret key has been copied.",
    "id": "Kunci rahasia telah disalin.",
    "ja": "(J)시크릿키가 복사되었습니다."
  },
  "복구코드 저장": {
    "ko": "복구코드 저장",
    "en": "Save Recovery Code",
    "id": "Simpan Kode Pemulihan",
    "ja": "(J)복구코드 저장"
  },
  "발급된 16자리 코드를 저장해주세요.": {
    "ko": "발급된 16자리 코드를 저장해 주세요.",
    "en": "Please save the 16-digit code.",
    "id": "Harap simpan kode 16 digit.",
    "ja": "(J)발급된 16자리 코드를 저장해주세요."
  },
  "휴대전화 교체/분실 및 구글 OTP앱 삭제 후 구글 OTP를 새로 설치하여 복구할때 필요합니다.": {
    "ko": "휴대전화 교체/분실 및 구글 OTP 앱 삭제 후 구글 OTP를 새로 설치하여 복구할때 필요합니다.",
    "en": "It is needed to recover your Google OTP when you changed or lost your mobile phone or deleted the Google OTP app.",
    "id": "Diperlukan untuk memulihkan Google OTP Anda saat Anda mengubah atau kehilangan ponsel atau menghapus aplikasi Google OTP.",
    "ja": "(J)휴대전화 교체/분실 및 구글 OTP앱 삭제 후 구글 OTP를 새로 설치하여 복구할때 필요합니다."
  },
  "Google OTP 계정 등록": {
    "ko": "Google OTP 계정 등록",
    "en": "Register Google OTP Account",
    "id": "Daftarkan akun Google OTP",
    "ja": "(J)Google OTP 계정 등록"
  },
  "다운로드 받은 구글 OTP 앱으로 아래 QR코드를 스캔하거나 수동으로 아래 코드를 직접 입력해주세요.": {
    "ko": "다운로드 받은 구글 OTP 앱으로 아래 QR코드를 스캔하거나 수동으로 아래 코드를 직접 입력해 주세요.",
    "en": "Scan the QR code below with the Google OTP app you downloaded or enter the code in person.",
    "id": "Pindai kode QR di bawah ini dengan aplikasi Google OTP yang Anda unduh atau masukkan kode secara langsung.",
    "ja": "(J)다운로드 받은 구글 OTP 앱으로 아래 QR코드를 스캔하거나 수동으로 아래 코드를 직접 입력해주세요."
  },
  "Google OTP 앱 설치": {
    "ko": "Google OTP 앱 설치",
    "en": "Install Google OTP",
    "id": "Instal Google OTP",
    "ja": "(J)Google OTP 앱 설치"
  },
  "시스템 운영 어드민 로그인을 위해 2차 인증이 필요합니다. 우선 구글 OTP를 등록해야합니다.": {
    "ko": "시스템 운영 어드민 로그인을 위해 2차 인증이 필요합니다. 우선 구글 OTP를 등록해야합니다.",
    "en": "You need secondary authentication to log in to the System Operation Admin. For this, you have to register Google OTP first.",
    "id": "Anda memerlukan otentikasi sekunder untuk masuk ke admin operasi sistem. Untuk ini, Anda harus mendaftarkan Google OTP terlebih dahulu.",
    "ja": "(J)시스템 운영 어드민 로그인을 위해 2차 인증이 필요합니다. 우선 구글 OTP를 등록해야합니다."
  },
  "앱스토어 접속하여 구글OTP를 검색 후 앱을 설치해주세요.": {
    "ko": "앱스토어 접속하여 구글 OTP를 검색 후 앱을 설치해 주세요.",
    "en": "Go to an app store and search Google OTP. Then, install the app.",
    "id": "Pergi ke toko aplikasi dan cari Google OTP. Kemudian, instal aplikasi.",
    "ja": "(J)앱스토어 접속하여 구글OTP를 검색 후 앱을 설치해주세요."
  },
  "Google OTP 등록": {
    "ko": "Google OTP 등록",
    "en": "Register Google OTP",
    "id": "Daftarkan Google OTP",
    "ja": "(J)Google OTP 등록"
  },
  "숫자만 입력가능합니다": {
    "ko": "숫자만 입력가능합니다",
    "en": "Able to enter numbers only!",
    "id": "Mampu memasukkan angka saja!",
    "ja": "(J)숫자만 입력가능합니다"
  },
  "Google OTP 인증": {
    "ko": "Google OTP 인증",
    "en": "Google OTP Authentication",
    "id": "Otentikasi Google OTP",
    "ja": "(J)Google OTP 인증"
  },
  "인증코드를 입력해주세요.": {
    "ko": "인증코드를 입력해 주세요.",
    "en": "Please enter the authentication code.",
    "id": "Harap masukkan kode otentikasi.",
    "ja": "(J)인증코드를 입력해주세요."
  },
  "인증코드를 입력하세요.": {
    "ko": "인증코드를 입력하세요.",
    "en": "Please enter the authentication code.",
    "id": "Harap masukkan kode otentikasi.",
    "ja": "(J)인증코드를 입력하세요."
  },
  "운영자 아이디": {
    "ko": "운영자 아이디",
    "en": "Operator ID",
    "id": "ID Operator",
    "ja": "(J)운영자 아이디"
  },
  "아이디를 입력해주세요.": {
    "ko": "아이디를 입력해 주세요.",
    "en": "Please enter the ID.",
    "id": "Harap masukkan ID.",
    "ja": "(J)아이디를 입력해주세요."
  },
  "운영자 아이디를 입력하세요.": {
    "ko": "운영자 아이디를 입력하세요.",
    "en": "Please enter the operator ID.",
    "id": "Harap masukkan ID operator.",
    "ja": "(J)운영자 아이디를 입력하세요."
  },
  "비밀번호를 입력해주세요.": {
    "ko": "비밀번호를 입력해 주세요.",
    "en": "Please enter the password.",
    "id": "Harap masukkan kata sandi.",
    "ja": "(J)비밀번호를 입력해주세요."
  },
  "비밀번호를 입력해주세요": {
    "ko": "비밀번호를 입력해 주세요",
    "en": "Please enter the password.",
    "id": "Harap masukkan kata sandi.",
    "ja": "(J)비밀번호를 입력해주세요"
  },
  "Chrome 브라우저 설치하기": {
    "ko": "Chrome 브라우저 설치하기",
    "en": "Install Chrome Browser",
    "id": "Instal Browser Chrome",
    "ja": "(J)Chrome 브라우저 설치하기"
  },
  "서비스 운영 어드민": {
    "ko": "서비스 운영 어드민",
    "en": "Service Operation Admin",
    "id": "Admin Operasi Layanan",
    "ja": "(J)서비스 운영 어드민"
  },
  "급가속률": {
    "ko": "급가속률",
    "en": "Sudden Acceleration Rate",
    "id": "Tingkat percepatan mendadak",
    "ja": "(J)급가속률"
  },
  "급감속률": {
    "ko": "급감속률",
    "en": "Sudden Deceleration Rate",
    "id": "Tingkat perlambatan mendadak",
    "ja": "(J)급감속률"
  },
  "과속률": {
    "ko": "과속률",
    "en": "Speeding Rate",
    "id": "Laju ngebut",
    "ja": "(J)과속률"
  },
  "최저전압": {
    "ko": "최저 전압",
    "en": "Minimum Voltage",
    "id": "Tegangan minimum",
    "ja": "(J)최저전압"
  },
  "최대전압": {
    "ko": "최대 전압",
    "en": "Maximum Voltage",
    "id": "Tegangan maksimum",
    "ja": "(J)최대전압"
  },
  "주행거리(km)": {
    "ko": "주행거리 (km)",
    "en": "Mileage (km)",
    "id": "Jarak tempuh (km)",
    "ja": "(J)주행거리(km)"
  },
  "주행시간(분)": {
    "ko": "주행시간 (분)",
    "en": "Travel Time (min.)",
    "id": "Waktu perjalanan (min.)",
    "ja": "(J)주행시간(분)"
  },
  "안전점수(점)": {
    "ko": "안전점수 (점)",
    "en": "Safety Scores (points)",
    "id": "Skor keselamatan (poin)",
    "ja": "(J)안전점수(점)"
  },
  "과속률/급가속률/급감속률(%)": {
    "ko": "과속률/급가속률/급감속률(%)",
    "en": "Speeding Rate | Sudden Acceleration Rate | Sudden Deceleration Rate (%)",
    "id": "Laju ngebut | Tingkat percepatan mendadak | Tingkat perlambatan mendadak (%)",
    "ja": "(J)과속률/급가속률/급감속률(%)"
  },
  "브레이킹 횟수(회)": {
    "ko": "브레이킹 횟수 (회)",
    "en": "Braking Frequency (times)",
    "id": "Frekuensi pengereman (waktu)",
    "ja": "(J)브레이킹 횟수(회)"
  },
  "연료소비량(L)": {
    "ko": "연료소비량 (L)",
    "en": "Fuel Consumption (L)",
    "id": "Konsumsi Bahan Bakar (L)",
    "ja": "(J)연료소비량(L)"
  },
  "배터리 최대/최저전압(v)": {
    "ko": "배터리 최대/최저전압 (v)",
    "en": "Maximum/Minimum Battery Voltage (V)",
    "id": "Tegangan baterai maksimum/minimum (v)",
    "ja": "(J)배터리 최대/최저전압(v)"
  },
  "다운로드": {
    "ko": "다운로드",
    "en": "Download",
    "id": "Unduh",
    "ja": "(J)다운로드"
  },
  "그래프 그리기": {
    "ko": "그래프 그리기",
    "en": "Draw Graph",
    "id": "Gambar grafik",
    "ja": "(J)그래프 그리기"
  },
  "급가속회수": {
    "ko": "급가속 횟수",
    "en": "Sudden Acceleration Frequency",
    "id": "Frekuensi akselerasi mendadak",
    "ja": "(J)급가속회수"
  },
  "급가속율(%)": {
    "ko": "급가속율 (%)",
    "en": "Sudden Acceleration Rate (%)",
    "id": "Tingkat percepatan mendadak (%)",
    "ja": "(J)급가속율(%)"
  },
  "급감속회수": {
    "ko": "급감속 횟수",
    "en": "Sudden Acceleration Frequency",
    "id": "Frekuensi akselerasi mendadak",
    "ja": "(J)급감속회수"
  },
  "급감속율(%)": {
    "ko": "급감속율 (%)",
    "en": "Sudden Acceleration Rate (%)",
    "id": "Tingkat percepatan mendadak (%)",
    "ja": "(J)급감속율(%)"
  },
  "과속율(%)": {
    "ko": "과속율 (%)",
    "en": "Speeding Rate (%)",
    "id": "Tingkat ngebut (%)",
    "ja": "(J)과속율(%)"
  },
  "브레이크회수": {
    "ko": "브레이크 횟수",
    "en": "Breaking Frequency",
    "id": "Frekuensi pecah",
    "ja": "(J)브레이크회수"
  },
  "연료소비량(리터)": {
    "ko": "연료소비량 (리터)",
    "en": "Fuel Consumption (liter)",
    "id": "Konsumsi Bahan Bakar (Liter)",
    "ja": "(J)연료소비량(리터)"
  },
  "최대전압(볼트)": {
    "ko": "최대 전압 (볼트)",
    "en": "Maximum Voltage (V)",
    "id": "Tegangan maksimum (V)",
    "ja": "(J)최대전압(볼트)"
  },
  "최저전압(볼트)": {
    "ko": "최저 전압 (볼트)",
    "en": "Minimum Voltage (V)",
    "id": "Tegangan minimum (V)",
    "ja": "(J)최저전압(볼트)"
  },
  "자료가 없습니다.": {
    "ko": "자료가 없습니다.",
    "en": "Cannot find data!",
    "id": "Tidak dapat menemukan data!",
    "ja": "(J)자료가 없습니다."
  },
  "ACCEL_COUNT 증가치": {
    "ko": "ACCEL_COUNT 증가치",
    "en": "(E)ACCEL_COUNT 증가치",
    "id": "(ID)ACCEL_COUNT 증가치",
    "ja": "(J)ACCEL_COUNT 증가치"
  },
  "DECEL_COUNT 증가치": {
    "ko": "DECEL_COUNT 증가치",
    "en": "(E)DECEL_COUNT 증가치",
    "id": "(ID)DECEL_COUNT 증가치",
    "ja": "(J)DECEL_COUNT 증가치"
  },
  "안전운전 점수": {
    "ko": "안전운전 점수",
    "en": "(E)안전운전 점수",
    "id": "(ID)안전운전 점수",
    "ja": "(J)안전운전 점수"
  },
  "칼럼선택": {
    "ko": "칼럼 선택",
    "en": "Select Column",
    "id": "Pilih kolom",
    "ja": "(J)칼럼선택"
  },
  "초기화": {
    "ko": "초기화",
    "en": "Reset",
    "id": "Mengatur ulang",
    "ja": "(J)초기화"
  },
  "적용": {
    "ko": "적용",
    "en": "Apply",
    "id": "Menerapkan",
    "ja": "(J)적용"
  },
  "내연기관": {
    "ko": "내연기관",
    "en": "Internal Combustion Engine (ICE)",
    "id": "Mesin pembakaran internal (ICE)",
    "ja": "(J)내연기관"
  },
  "전기차": {
    "ko": "전기차",
    "en": "Electric Vehicle",
    "id": "Kendaraan listrik",
    "ja": "(J)전기차"
  },
  "등록된 디바이스가 없습니다.": {
    "ko": "등록된 디바이스가 없습니다.",
    "en": "Cannot find the device!",
    "id": "Tidak dapat menemukan perangkatnya!",
    "ja": "(J)등록된 디바이스가 없습니다."
  },
  "EDGE 파워상태": {
    "ko": "EDGE 파워상태",
    "en": "(E)EDGE 파워상태",
    "id": "(ID)EDGE 파워상태",
    "ja": "(J)EDGE 파워상태"
  },
  "EDGE->아차키 연결상태": {
    "ko": "EDGE->아차키 연결상태",
    "en": "(E)EDGE->아차키 연결상태",
    "id": "(ID)EDGE->아차키 연결상태",
    "ja": "(J)EDGE->아차키 연결상태"
  },
  "EDGE->OBD 연결상태": {
    "ko": "EDGE->OBD 연결상태",
    "en": "(E)EDGE->OBD 연결상태",
    "id": "(ID)EDGE->OBD 연결상태",
    "ja": "(J)EDGE->OBD 연결상태"
  },
  "EDGE->Iot 네트워크 연결상태": {
    "ko": "EDGE->Iot 네트워크 연결상태",
    "en": "(E)EDGE->Iot 네트워크 연결상태",
    "id": "(ID)EDGE->Iot 네트워크 연결상태",
    "ja": "(J)EDGE->Iot 네트워크 연결상태"
  },
  "배터리 전압": {
    "ko": "배터리 전압",
    "en": "(E)배터리 전압",
    "id": "(ID)배터리 전압",
    "ja": "(J)배터리 전압"
  },
  "OBD 최근 데이터 업데이트 시간": {
    "ko": "OBD 최근 데이터 업데이트 시간",
    "en": "(E)OBD 최근 데이터 업데이트 시간",
    "id": "(ID)OBD 최근 데이터 업데이트 시간",
    "ja": "(J)OBD 최근 데이터 업데이트 시간"
  },
  "OBD 고장코드": {
    "ko": "OBD 고장코드",
    "en": "(E)OBD 고장코드",
    "id": "(ID)OBD 고장코드",
    "ja": "(J)OBD 고장코드"
  },
  "OBD->앱 연결상태": {
    "ko": "OBD->앱 연결상태",
    "en": "(E)OBD->앱 연결상태",
    "id": "(ID)OBD->앱 연결상태",
    "ja": "(J)OBD->앱 연결상태"
  },
  "OBD->Iot 네트워크 연결상태": {
    "ko": "OBD->Iot 네트워크 연결상태",
    "en": "(E)OBD->Iot 네트워크 연결상태",
    "id": "(ID)OBD->Iot 네트워크 연결상태",
    "ja": "(J)OBD->Iot 네트워크 연결상태"
  },
  "OBD->Iot 네트워크 버전": {
    "ko": "OBD->Iot 네트워크 버전",
    "en": "(E)OBD->Iot 네트워크 버전",
    "id": "(ID)OBD->Iot 네트워크 버전",
    "ja": "(J)OBD->Iot 네트워크 버전"
  },
  "아차키 Mac Addr": {
    "ko": "아차키 Mac Addr",
    "en": "(E)아차키 Mac Addr",
    "id": "(ID)아차키 Mac Addr",
    "ja": "(J)아차키 Mac Addr"
  },
  "아차키->앱 연결상태": {
    "ko": "아차키->앱 연결상태",
    "en": "(E)아차키->앱 연결상태",
    "id": "(ID)아차키->앱 연결상태",
    "ja": "(J)아차키->앱 연결상태"
  },
  "아차키->앱 액션": {
    "ko": "아차키->앱 액션",
    "en": "(E)아차키->앱 액션",
    "id": "(ID)아차키->앱 액션",
    "ja": "(J)아차키->앱 액션"
  },
  "앱->Iot 네트워크 연결상태": {
    "ko": "앱->Iot 네트워크 연결상태",
    "en": "(E)앱->Iot 네트워크 연결상태",
    "id": "(ID)앱->Iot 네트워크 연결상태",
    "ja": "(J)앱->Iot 네트워크 연결상태"
  },
  "앱->Iot 네트워크 버전": {
    "ko": "앱->Iot 네트워크 버전",
    "en": "(E)앱->Iot 네트워크 버전",
    "id": "(ID)앱->Iot 네트워크 버전",
    "ja": "(J)앱->Iot 네트워크 버전"
  },
  "앱->Iot 네트워크 연결끊김 사유": {
    "ko": "앱->Iot 네트워크 연결끊김 사유",
    "en": "(E)앱->Iot 네트워크 연결끊김 사유",
    "id": "(ID)앱->Iot 네트워크 연결끊김 사유",
    "ja": "(J)앱->Iot 네트워크 연결끊김 사유"
  },
  "비즈": {
    "ko": "비즈",
    "en": "(E)비즈",
    "id": "(ID)비즈",
    "ja": "(J)비즈"
  },
  "게시": {
    "ko": "게시",
    "en": "(E)게시",
    "id": "(ID)게시",
    "ja": "(J)게시"
  },
  "TOPIC을 게시하였습니다.": {
    "ko": "TOPIC을 게시하였습니다.",
    "en": "TOPIC has been posted.",
    "id": "Topik telah diposting.",
    "ja": "(J)TOPIC을 게시하였습니다."
  },
  "DEBUG TOPIC 게시": {
    "ko": "DEBUG TOPIC 게시",
    "en": "Post DEBUG TOPIC",
    "id": "Posting topik debug",
    "ja": "(J)DEBUG TOPIC 게시"
  },
  "생성 완료되었습니다.": {
    "ko": "생성 완료되었습니다.",
    "en": "(E)생성 완료되었습니다.",
    "id": "(ID)생성 완료되었습니다.",
    "ja": "(J)생성 완료되었습니다."
  },
  "Topic 게시": {
    "ko": "Topic 게시",
    "en": "(E)Topic 게시",
    "id": "(ID)Topic 게시",
    "ja": "(J)Topic 게시"
  },
  "변경되었습니다.": {
    "ko": "변경되었습니다.",
    "en": "(E)변경되었습니다.",
    "id": "(ID)변경되었습니다.",
    "ja": "(J)변경되었습니다."
  },
  "기능": {
    "ko": "기능",
    "en": "Features",
    "id": "Fitur",
    "ja": "(J)기능"
  },
  "데이터가 없습니다.": {
    "ko": "데이터가 없습니다.",
    "en": "Cannot find data!",
    "id": "Tidak dapat menemukan data!",
    "ja": "(J)데이터가 없습니다."
  },
  "시작일자 또는 종료일자를 입력해주세요": {
    "ko": "시작 일자 또는 종료 일자를 입력해 주세요",
    "en": "Please enter the start date or end date.",
    "id": "Harap masukkan tanggal mulai atau tanggal akhir.",
    "ja": "(J)시작일자 또는 종료일자를 입력해주세요"
  },
  "시작일자": {
    "ko": "시작 일자",
    "en": "Start Date",
    "id": "Mulai tanggal",
    "ja": "(J)시작일자"
  },
  "종료일자": {
    "ko": "종료 일자",
    "en": "End Date",
    "id": "Tanggal akhir",
    "ja": "(J)종료일자"
  },
  "검색중입니다.": {
    "ko": "검색중입니다.",
    "en": "(E)검색중입니다.",
    "id": "(ID)검색중입니다.",
    "ja": "(J)검색중입니다."
  },
  "등록된 history가 없습니다.": {
    "ko": "등록된 history가 없습니다.",
    "en": "(E)등록된 history가 없습니다.",
    "id": "(ID)등록된 history가 없습니다.",
    "ja": "(J)등록된 history가 없습니다."
  },
  "최소 2자 이상 입력하세요.": {
    "ko": "최소 2자 이상 입력하세요.",
    "en": "Please enter at least 2 characters.",
    "id": "Harap masukkan setidaknya 2 karakter.",
    "ja": "(J)최소 2자 이상 입력하세요."
  },
  "커넥트": {
    "ko": "커넥트",
    "en": "Connect",
    "id": "Menghubung",
    "ja": "(J)커넥트"
  },
  "1번부터 4번까지의 커스텀 버튼의 연결 여부를 설정합니다.": {
    "ko": "1번부터 4번까지의 커스텀 버튼의 연결 여부를 설정합니다.",
    "en": "Set the custom button connections from #1 to #4.",
    "id": "Atur koneksi tombol khusus dari #1 ke #4.",
    "ja": "(J)1번부터 4번까지의 커스텀 버튼의 연결 여부를 설정합니다."
  },
  "현재 설치된 아차키의 버튼 연결 상태를 확인한 다음에 변경하세요.": {
    "ko": "현재 설치된 아차키의 버튼 연결 상태를 확인한 다음에 변경하세요.",
    "en": "Check current Achakey button connections and change later.",
    "id": "Periksa koneksi tombol Achakey saat ini dan ubah nanti.",
    "ja": "(J)현재 설치된 아차키의 버튼 연결 상태를 확인한 다음에 변경하세요."
  },
  "(변경 요청시, 기존의 변경 요청사항은 취소 처리됩니다)": {
    "ko": "(변경 요청시, 기존의 변경 요청사항은 취소 처리됩니다)",
    "en": "(If requested for change, the previous change request will be canceled.)",
    "id": "(Jika diminta untuk perubahan, permintaan perubahan sebelumnya akan dibatalkan.)",
    "ja": "(J)(변경 요청시, 기존의 변경 요청사항은 취소 처리됩니다)"
  },
  "고객명": {
    "ko": "고객명",
    "en": "Client Name",
    "id": "Nama Klien",
    "ja": "(J)고객명"
  },
  "연락처": {
    "ko": "연락처",
    "en": "TEL",
    "id": "Tel",
    "ja": "(J)연락처"
  },
  "등록 완료": {
    "ko": "등록 완료",
    "en": "Successfully registered!",
    "id": "Pendaftaran berhasil!",
    "ja": "(J)등록 완료"
  },
  "Wire Connect 등록이 완료되었습니다.": {
    "ko": "버튼 연결 재설정 작업 등록이 완료되었습니다.",
    "en": "WIRE CONNECT successfully registered!",
    "id": "Wire Connect berhasil terdaftar!",
    "ja": "(J)Wire Connect 등록이 완료되었습니다."
  },
  "WireConnect 등록": {
    "ko": "버튼 연결 재설정 작업 등록",
    "en": "Register WireConnect",
    "id": "Daftarkan Wireconnect",
    "ja": "(J)WireConnect 등록"
  },
  "변경 요청 완료": {
    "ko": "변경 요청 완료",
    "en": "The change request completed!",
    "id": "Permintaan perubahan selesai!",
    "ja": "(J)변경 요청 완료"
  },
  "Wire Connect 변경 요청이 완료되었습니다.": {
    "ko": "버튼 연결 재설정 작업 요청이 완료되었습니다.",
    "en": "A request for WIRE CONNECT change is completed!",
    "id": "Permintaan untuk perubahan koneksi kawat selesai!",
    "ja": "(J)Wire Connect 변경 요청이 완료되었습니다."
  },
  "요청 취소 완료": {
    "ko": "요청 취소 완료",
    "en": "The request successfully canceled!",
    "id": "Permintaan berhasil dibatalkan!",
    "ja": "(J)요청 취소 완료"
  },
  "Wire Connect 요청 취소가 완료되었습니다.": {
    "ko": "버튼 연결 재설정 작업 취소가 완료되었습니다.",
    "en": "The WIRE CONNECT request successfully canceled!",
    "id": "Permintaan Wire Connect berhasil dibatalkan!",
    "ja": "(J)Wire Connect 요청 취소가 완료되었습니다."
  },
  "변경요청": {
    "ko": "변경 요청",
    "en": "Request for Change",
    "id": "Permintaan perubahan",
    "ja": "(J)변경요청"
  },
  "요청취소": {
    "ko": "요청취소",
    "en": "Cancel Request",
    "id": "Batalkan permintaan",
    "ja": "(J)요청취소"
  },
  "요청일": {
    "ko": "요청일",
    "en": "Request Date",
    "id": "Tanggal Permintaan",
    "ja": "(J)요청일"
  },
  "완료일": {
    "ko": "완료일",
    "en": "Date of Completion",
    "id": "Tanggal penyelesaian",
    "ja": "(J)완료일"
  },
  "Version 유형": {
    "ko": "Version 유형",
    "en": "Version Type",
    "id": "Jenis Versi",
    "ja": "(J)Version 유형"
  },
  "숫자만": {
    "ko": "숫자만",
    "en": "Numbers ONLY",
    "id": "Hanya angka-angka",
    "ja": "(J)숫자만"
  },
  "성공": {
    "ko": "성공",
    "en": "Success",
    "id": "Kesuksesan",
    "ja": "(J)성공"
  },
  "펌웨어 등록": {
    "ko": "펌웨어 등록",
    "en": "Register Firmware",
    "id": "Mendaftar firmware",
    "ja": "(J)펌웨어 등록"
  },
  "펌웨어 파일": {
    "ko": "펌웨어 파일",
    "en": "Firmware File",
    "id": "File firmware",
    "ja": "(J)펌웨어 파일"
  },
  "AOS 필수 APP Version (이상)": {
    "ko": "AOS 필수 APP Version (이상)",
    "en": "AOS Required App Version (or higher)",
    "id": "AOS Versi aplikasi yang diperlukan (atau lebih tinggi)",
    "ja": "(J)AOS 필수 APP Version (이상)"
  },
  "iOS 필수 APP Version (이상": {
    "ko": "iOS 필수 APP Version (이상)",
    "en": "iOS Required App Version (or higher)",
    "id": "iOS yang diperlukan versi aplikasi (atau lebih tinggi)",
    "ja": "(J)iOS 필수 APP Version (이상"
  },
  "삭제하면 원복할 수 없습니다. 정말 삭제하시겠습니까?": {
    "ko": "삭제하면 원복 할 수 없습니다. 정말 삭제하시겠습니까?",
    "en": "Once deleted, it will not be recovered. Do you really want to delete?",
    "id": "Setelah dihapus, itu tidak akan dipulihkan. Apakah Anda benar -benar ingin menghapus?",
    "ja": "(J)삭제하면 원복할 수 없습니다. 정말 삭제하시겠습니까?"
  },
  "펌웨어 수정": {
    "ko": "펌웨어 수정",
    "en": "Edit Firmware",
    "id": "Edit firmware",
    "ja": "(J)펌웨어 수정"
  },
  "기존파일명": {
    "ko": "기존파일명",
    "en": "Current File Name",
    "id": "Nama file saat ini",
    "ja": "(J)기존파일명"
  },
  "iOS 필수 APP Version (이상)": {
    "ko": "iOS 필수 APP Version (이상)",
    "en": "iOS Required App Version (or higher)",
    "id": "iOS yang diperlukan versi aplikasi (atau lebih tinggi)",
    "ja": "(J)iOS 필수 APP Version (이상)"
  },
  "등록된 펌웨어가 없습니다.": {
    "ko": "등록된 펌웨어가 없습니다.",
    "en": "Cannot find the firmware!",
    "id": "Tidak dapat menemukan firmware!",
    "ja": "(J)등록된 펌웨어가 없습니다."
  },
  "실제파일명": {
    "ko": "실제 파일명",
    "en": "Actual File Name",
    "id": "Nama file aktual",
    "ja": "(J)실제파일명"
  },
  "등록날짜": {
    "ko": "등록 날짜",
    "en": "Date of Registration",
    "id": "Tanggal pendaftaran",
    "ja": "(J)등록날짜"
  },
  "담당자": {
    "ko": "담당자",
    "en": "Manager",
    "id": "Pengelola",
    "ja": "(J)담당자"
  },
  "튠잇": {
    "ko": "튠잇",
    "en": "Tuneit",
    "id": "Tuneit",
    "ja": "(J)튠잇"
  },
  "블루포인트": {
    "ko": "블루포인트",
    "en": "Bluepoint",
    "id": "Bluepoint",
    "ja": "(J)블루포인트"
  },
  "고객연락처": {
    "ko": "고객 연락처",
    "en": "Client Contact No.",
    "id": "Kontak Klien No.",
    "ja": "(J)고객연락처"
  },
  "VOC 등록하기": {
    "ko": "VOC 등록하기",
    "en": "Add VOC",
    "id": "Tambahkan VOC",
    "ja": "(J)VOC 등록하기"
  },
  "파일 선택": {
    "ko": "파일 선택",
    "en": "Select File",
    "id": "Pilih file",
    "ja": "(J)파일 선택"
  },
  "증상": {
    "ko": "증상",
    "en": "Symptom",
    "id": "Gejala",
    "ja": "(J)증상"
  },
  "차량모델 정보": {
    "ko": "차량 모델 정보",
    "en": "Model Information",
    "id": "Informasi model",
    "ja": "(J)차량모델 정보"
  },
  "배기량": {
    "ko": "배기량",
    "en": "CC",
    "id": "CC",
    "ja": "(J)배기량"
  },
  "미션": {
    "ko": "미션",
    "en": "Transmission",
    "id": "Penularan",
    "ja": "(J)미션"
  },
  "유종": {
    "ko": "유종",
    "en": "Fuel Type",
    "id": "Jenis bahan bakar",
    "ja": "(J)유종"
  },
  "승차정원": {
    "ko": "승차 정원",
    "en": "Riding Capacity",
    "id": "Kapasitas berkuda",
    "ja": "(J)승차정원"
  },
  "OBD 차량모델 정보": {
    "ko": "OBD 차량 모델 정보",
    "en": "OBD Model Information",
    "id": "Informasi Model OBD",
    "ja": "(J)OBD 차량모델 정보"
  },
  "FW 버전": {
    "ko": "FW 버전",
    "en": "FW Version",
    "id": "Versi FW",
    "ja": "(J)FW 버전"
  },
  "DB 버전": {
    "ko": "DB 버전",
    "en": "DB Version",
    "id": "Versi DB",
    "ja": "(J)DB 버전"
  },
  "연비": {
    "ko": "연비",
    "en": "Fuel Economy",
    "id": "Penghematan bahan bakar",
    "ja": "(J)연비"
  },
  "DEVICE 정보": {
    "ko": "DEVICE 정보",
    "en": "Device Information",
    "id": "Informasi perangkat",
    "ja": "(J)DEVICE 정보"
  },
  "펌웨어 버전": {
    "ko": "펌웨어 버전",
    "en": "Firmware Version",
    "id": "Versi firmware",
    "ja": "(J)펌웨어 버전"
  },
  "펌웨어 DISPLAY 버전": {
    "ko": "펌웨어 DISPLAY 버전",
    "en": "Firmware Display Version",
    "id": "Versi tampilan firmware",
    "ja": "(J)펌웨어 DISPLAY 버전"
  },
  "준비": {
    "ko": "준비",
    "en": "Prepare",
    "id": "Mempersiapkan",
    "ja": "(J)준비"
  },
  "완료": {
    "ko": "완료",
    "en": "Complete",
    "id": "Menyelesaikan",
    "ja": "(J)완료"
  },
  "Task 등록": {
    "ko": "Task 등록",
    "en": "Add Task",
    "id": "Tambahkan tugas",
    "ja": "(J)Task 등록"
  },
  "Task 유형": {
    "ko": "Task 유형",
    "en": "Task Type",
    "id": "Jenis tugas",
    "ja": "(J)Task 유형"
  },
  "최신 Release 펌웨어 설치": {
    "ko": "최신 Release 펌웨어 설치",
    "en": "Install the Latest Firmware",
    "id": "Instal firmware terbaru",
    "ja": "(J)최신 Release 펌웨어 설치"
  },
  "신규설치": {
    "ko": "신규 설치",
    "en": "New Installation",
    "id": "Instalasi baru",
    "ja": "(J)신규설치"
  },
  "펌웨어 추가": {
    "ko": "펌웨어 추가",
    "en": "Add Firmware",
    "id": "Tambahkan firmware",
    "ja": "(J)펌웨어 추가"
  },
  "FBP 추가": {
    "ko": "FBP 추가",
    "en": "Add FBP",
    "id": "Tambahkan FBP",
    "ja": "(J)FBP 추가"
  },
  "푸시 재전송": {
    "ko": "푸시 재전송",
    "en": "Resend Push",
    "id": "Kirimkan kembali dorongan",
    "ja": "(J)푸시 재전송"
  },
  "푸시 알람이 재전송되었습니다.": {
    "ko": "푸시 알람이 재전송되었습니다.",
    "en": "A push notification has been sent again.",
    "id": "Pemberitahuan push telah dikirim lagi.",
    "ja": "(J)푸시 알람이 재전송되었습니다."
  },
  "Task 삭제": {
    "ko": "Task 삭제",
    "en": "Delete Task",
    "id": "Hapus tugas",
    "ja": "(J)Task 삭제"
  },
  "Task 가 삭제되었습니다.": {
    "ko": "Task가 삭제되었습니다.",
    "en": "The task has been deleted.",
    "id": "Tugas telah dihapus.",
    "ja": "(J)Task 가 삭제되었습니다."
  },
  "Task 상태 변경": {
    "ko": "Task 상태 변경",
    "en": "Change Task Status",
    "id": "Ubah status tugas",
    "ja": "(J)Task 상태 변경"
  },
  "Task 상태가 변경되었습니다.": {
    "ko": "Task 상태가 변경되었습니다.",
    "en": "The task status has changed.",
    "id": "Status tugas telah berubah.",
    "ja": "(J)Task 상태가 변경되었습니다."
  },
  "등록된 Task 가 없습니다.": {
    "ko": "등록된 Task가 없습니다.",
    "en": "Cannot find the task!",
    "id": "Tidak dapat menemukan tugasnya!",
    "ja": "(J)등록된 Task 가 없습니다."
  },
  "펌웨어파일": {
    "ko": "펌웨어 파일",
    "en": "Firmware File",
    "id": "File firmware",
    "ja": "(J)펌웨어파일"
  },
  "FBP파일": {
    "ko": "FBP 파일",
    "en": "FBP File",
    "id": "File fbp",
    "ja": "(J)FBP파일"
  },
  "로그파일": {
    "ko": "로그 파일",
    "en": "Log File",
    "id": "File log",
    "ja": "(J)로그파일"
  },
  "푸시발송일시": {
    "ko": "푸시 발송 일시",
    "en": "Date and Time of Push Notification Sent",
    "id": "Tanggal dan waktu pemberitahuan dorong dikirim",
    "ja": "(J)푸시발송일시"
  },
  "푸시": {
    "ko": "푸시",
    "en": "Push",
    "id": "Dorongan",
    "ja": "(J)푸시"
  },
  "재발송": {
    "ko": "재발송",
    "en": "Resend",
    "id": "Ulang",
    "ja": "(J)재발송"
  },
  "FBP 파일 등록이 불가능합니다.": {
    "ko": "FBP 파일 등록이 불가능합니다.",
    "en": "Cannot add an FBP file!",
    "id": "Tidak dapat menambahkan file FBP!",
    "ja": "(J)FBP 파일 등록이 불가능합니다."
  },
  "펌웨어 파일 등록이 불가능합니다.": {
    "ko": "펌웨어 파일 등록이 불가능합니다.",
    "en": "Cannot add a firmware file!",
    "id": "Tidak dapat menambahkan file firmware!",
    "ja": "(J)펌웨어 파일 등록이 불가능합니다."
  },
  "VOC 삭제": {
    "ko": "VOC 삭제",
    "en": "Delete VOC",
    "id": "Hapus VOC",
    "ja": "(J)VOC 삭제"
  },
  "VOC 가 삭제되었습니다.": {
    "ko": "VOC가 삭제되었습니다.",
    "en": "The VOC has been deleted.",
    "id": "VOC telah dihapus.",
    "ja": "(J)VOC 가 삭제되었습니다."
  },
  "VOC 수정": {
    "ko": "VOC 수정",
    "en": "Edit VOC",
    "id": "Edit VOC",
    "ja": "(J)VOC 수정"
  },
  "VOC 가 수정되었습니다.": {
    "ko": "VOC가 수정되었습니다.",
    "en": "The VOC has been edited.",
    "id": "VOC telah diedit.",
    "ja": "(J)VOC 가 수정되었습니다."
  },
  "VOC 상태변경": {
    "ko": "VOC 상태변경",
    "en": "Change VOC Status",
    "id": "Ubah status VOC",
    "ja": "(J)VOC 상태변경"
  },
  "VOC 상태가 변경되었습니다.": {
    "ko": "VOC 상태가 변경되었습니다.",
    "en": "The VOC status has changed.",
    "id": "Status VOC telah berubah.",
    "ja": "(J)VOC 상태가 변경되었습니다."
  },
  "VOC 정보": {
    "ko": "VOC 정보",
    "en": "VOC Information",
    "id": "Informasi VOC",
    "ja": "(J)VOC 정보"
  },
  "진행중": {
    "ko": "진행중",
    "en": "Processing…",
    "id": "Pengolahan…",
    "ja": "(J)진행중"
  },
  "파일 삭제": {
    "ko": "파일 삭제",
    "en": "Delete File",
    "id": "Menghapus berkas",
    "ja": "(J)파일 삭제"
  },
  "파일이 삭제되었습니다.": {
    "ko": "파일이 삭제되었습니다.",
    "en": "A file has been deleted.",
    "id": "File telah dihapus.",
    "ja": "(J)파일이 삭제되었습니다."
  },
  "첨부파일": {
    "ko": "첨부파일",
    "en": "Attachment",
    "id": "Lampiran",
    "ja": "(J)첨부파일"
  },
  "조치": {
    "ko": "조치",
    "en": "Action",
    "id": "Tindakan",
    "ja": "(J)조치"
  },
  "고객 연락처": {
    "ko": "고객 연락처",
    "en": "Client Contact No.",
    "id": "Kontak Klien No.",
    "ja": "(J)고객 연락처"
  },
  "담당업체": {
    "ko": "담당업체",
    "en": "Partner",
    "id": "Mitra",
    "ja": "(J)담당업체"
  },
  "접수일": {
    "ko": "접수일",
    "en": "Date Received",
    "id": "Tanggal Diterima",
    "ja": "(J)접수일"
  },
  "배포 대상은 최대 ${TARGET_COUNT_LIMIT}대로 제한됩니다.": {
    "ko": "배포 대상은 최대 ${TARGET_COUNT_LIMIT}대로 제한됩니다.",
    "en": "(E)배포 대상은 최대 ${TARGET_COUNT_LIMIT}대로 제한됩니다.",
    "id": "(ID)배포 대상은 최대 ${TARGET_COUNT_LIMIT}대로 제한됩니다.",
    "ja": "(J)배포 대상은 최대 ${TARGET_COUNT_LIMIT}대로 제한됩니다."
  },
  "차량 검색": {
    "ko": "차량 검색",
    "en": "Vehicle Search",
    "id": "Pencarian Kendaraan",
    "ja": "(J)차량 검색"
  },
  "차량소유": {
    "ko": "차량 소유",
    "en": "Car Ownership",
    "id": "Kepemilikan mobil",
    "ja": "(J)차량소유"
  },
  "소유주": {
    "ko": "소유주",
    "en": "Owner",
    "id": "Pemilik",
    "ja": "(J)소유주"
  },
  "BIZ 서비스": {
    "ko": "BIZ 서비스",
    "en": "BIZ Services",
    "id": "Layanan Biz",
    "ja": "(J)BIZ 서비스"
  },
  "펌웨어를 선택해주세요.": {
    "ko": "펌웨어를 선택해 주세요.",
    "en": "Please select firmware.",
    "id": "Pilih firmware.",
    "ja": "(J)펌웨어를 선택해주세요."
  },
  "법인": {
    "ko": "법인",
    "en": "Corporation",
    "id": "Perusahaan",
    "ja": "(J)법인"
  },
  "선택한 차량 수": {
    "ko": "선택한 차량 수",
    "en": "(E)선택한 차량 수",
    "id": "(ID)선택한 차량 수",
    "ja": "(J)선택한 차량 수"
  },
  "DEVICE를 선택해주세요.": {
    "ko": "디바이스를 선택해 주세요.",
    "en": "Please select a device.",
    "id": "Pilih perangkat.",
    "ja": "(J)DEVICE를 선택해주세요."
  },
  "FEATURE를 선택해주세요.": {
    "ko": "FEATURE를 선택해 주세요.",
    "en": "Please select a feature.",
    "id": "Pilih fitur.",
    "ja": "(J)FEATURE를 선택해주세요."
  },
  "전체 차량 수": {
    "ko": "전체 차량 수",
    "en": "(E)전체 차량 수",
    "id": "(ID)전체 차량 수",
    "ja": "(J)전체 차량 수"
  },
  "차량 삭제": {
    "ko": "차량 삭제",
    "en": "Delete Vehicle",
    "id": "Hapus kendaraan",
    "ja": "(J)차량 삭제"
  },
  "차량 선택": {
    "ko": "차량 선택",
    "en": "Select Vehicle",
    "id": "Pilih Kendaraan",
    "ja": "(J)차량 선택"
  },
  "차량을 선택해주세요.": {
    "ko": "차량을 선택해 주세요.",
    "en": "Please select a vehicle.",
    "id": "Pilih kendaraan.",
    "ja": "(J)차량을 선택해주세요."
  },
  "DEVICE를 먼저 선택해주세요.": {
    "ko": "디바이스를 먼저 선택해 주세요.",
    "en": "Select a device first.",
    "id": "Pilih perangkat terlebih dahulu.",
    "ja": "(J)DEVICE를 먼저 선택해주세요."
  },
  "커넥티드는 일괄을 선택할 수 없습니다.": {
    "ko": "커넥티드는 일괄을 선택할 수 없습니다.",
    "en": "Cannot select ‘Collective’ for CONNECTED!",
    "id": "Tidak dapat memilih ‘Collective’ untuk Connected!",
    "ja": "(J)커넥티드는 일괄을 선택할 수 없습니다."
  },
  "점진적": {
    "ko": "점진적",
    "en": "Gradual",
    "id": "Secara bertahap",
    "ja": "(J)점진적"
  },
  "일괄": {
    "ko": "일괄",
    "en": "Collective",
    "id": "Kolektif",
    "ja": "(J)일괄"
  },
  "배포대상구분": {
    "ko": "배포 대상 구분",
    "en": "Target Classification",
    "id": "Klasifikasi target",
    "ja": "(J)배포대상구분"
  },
  "배포대상 차량을 선택해주세요.": {
    "ko": "배포 대상 차량을 선택해 주세요.",
    "en": "Please select a target vehicle.",
    "id": "Pilih kendaraan target.",
    "ja": "(J)배포대상 차량을 선택해주세요."
  },
  "최대 ${TARGET_COUNT_LIMIT}대까지 배포 대상을 등록할 수 있습니다.": {
    "ko": "최대 ${TARGET_COUNT_LIMIT}대까지 배포 대상을 등록할 수 있습니다.",
    "en": "(E)최대 ${TARGET_COUNT_LIMIT}대까지 배포 대상을 등록할 수 있습니다.",
    "id": "(ID)최대 ${TARGET_COUNT_LIMIT}대까지 배포 대상을 등록할 수 있습니다.",
    "ja": "(J)최대 ${TARGET_COUNT_LIMIT}대까지 배포 대상을 등록할 수 있습니다."
  },
  "배포 등록이 완료되었습니다.": {
    "ko": "배포 등록이 완료되었습니다.",
    "en": "Distribution successfully registered!",
    "id": "Distribusi berhasil terdaftar!",
    "ja": "(J)배포 등록이 완료되었습니다."
  },
  "펌웨어 배포 등록": {
    "ko": "펌웨어 배포 등록",
    "en": "Register Firmware Distribution",
    "id": "Daftar distribusi firmware",
    "ja": "(J)펌웨어 배포 등록"
  },
  "펌웨어 선택": {
    "ko": "펌웨어 선택",
    "en": "Select Firmware",
    "id": "Pilih firmware",
    "ja": "(J)펌웨어 선택"
  },
  "VERSION 유형": {
    "ko": "VERSION 유형",
    "en": "Version Type",
    "id": "Jenis Versi",
    "ja": "(J)VERSION 유형"
  },
  "대기됨": {
    "ko": "대기됨",
    "en": "Waiting…",
    "id": "Menunggu…",
    "ja": "(J)대기됨"
  },
  "실패": {
    "ko": "실패",
    "en": "Failure",
    "id": "Kegagalan",
    "ja": "(J)실패"
  },
  "시간초과": {
    "ko": "시간 초과",
    "en": "Timed Out",
    "id": "Waktu habis",
    "ja": "(J)시간초과"
  },
  "거부됨": {
    "ko": "거부됨",
    "en": "Rejected",
    "id": "Ditolak",
    "ja": "(J)거부됨"
  },
  "취소됨": {
    "ko": "취소됨",
    "en": "Canceled",
    "id": "Dibatalkan",
    "ja": "(J)취소됨"
  },
  "제거됨": {
    "ko": "제거됨",
    "en": "Removed",
    "id": "DIHAPUS",
    "ja": "(J)제거됨"
  },
  "QUEUED 상태인 대상을 선택해주세요.": {
    "ko": "QUEUED 상태인 대상을 선택해주세요.",
    "en": "(E)QUEUED 상태인 대상을 선택해주세요.",
    "id": "(ID)QUEUED 상태인 대상을 선택해주세요.",
    "ja": "(J)QUEUED 상태인 대상을 선택해주세요."
  },
  "취소하면 원복할 수 없습니다. 정말 취소하시겠습니까?": {
    "ko": "취소하면 원복할 수 없습니다. 정말 취소하시겠습니까?",
    "en": "Unable to recover once canceled! Do you really want to cancel?",
    "id": "Tidak dapat pulih setelah dibatalkan! Apakah Anda benar -benar ingin membatalkan?",
    "ja": "(J)취소하면 원복할 수 없습니다. 정말 취소하시겠습니까?"
  },
  "배포취소": {
    "ko": "배포 취소",
    "en": "Cancel Distribution",
    "id": "Batalkan distribusi",
    "ja": "(J)배포취소"
  },
  "실행유형": {
    "ko": "실행 유형",
    "en": "Execution Type",
    "id": "Jenis eksekusi",
    "ja": "(J)실행유형"
  },
  "배포대상": {
    "ko": "배포 대상",
    "en": "Target",
    "id": "Target",
    "ja": "(J)배포대상"
  },
  "THING NAME FULL 입력": {
    "ko": "THING NAME FULL 입력",
    "en": "Enter Full Name",
    "id": "Masukkan nama lengkap",
    "ja": "(J)THING NAME FULL 입력"
  },
  "대상 취소": {
    "ko": "대상 취소",
    "en": "Cancel Target",
    "id": "Batalkan target",
    "ja": "(J)대상 취소"
  },
  "등록된 THING이 없습니다.": {
    "ko": "등록된 THING이 없습니다.",
    "en": "Cannot find the thing!",
    "id": "Tidak dapat menemukannya!",
    "ja": "(J)등록된 THING이 없습니다."
  },
  "대기일시": {
    "ko": "대기 일시",
    "en": "Waiting Date and Time",
    "id": "Tanggal dan Waktu Menunggu",
    "ja": "(J)대기일시"
  },
  "마지막 업데이트 일시": {
    "ko": "마지막 업데이트 일시",
    "en": "Date and Time of Last Update",
    "id": "Tanggal dan waktu pembaruan terakhir",
    "ja": "(J)마지막 업데이트 일시"
  },
  "진행중인 상태에만 가능합니다.": {
    "ko": "진행중인 상태에만 가능합니다.",
    "en": "Enabled while being processed only!",
    "id": "Diaktifkan saat diproses saja!",
    "ja": "(J)진행중인 상태에만 가능합니다."
  },
  "펌웨어 배포 상세": {
    "ko": "펌웨어 배포 상세",
    "en": "Firmware Distribution Details",
    "id": "Detail Distribusi Firmware",
    "ja": "(J)펌웨어 배포 상세"
  },
  "세부정보": {
    "ko": "세부정보",
    "en": "Specific Information",
    "id": "Informasi spesifik",
    "ja": "(J)세부정보"
  },
  "AOS APP 버전": {
    "ko": "AOS APP 버전",
    "en": "AOS App Version",
    "id": "Versi Aplikasi AOS",
    "ja": "(J)AOS APP 버전"
  },
  "IOS APP 버전": {
    "ko": "IOS APP 버전",
    "en": "IOS App Version",
    "id": "Versi aplikasi iOS",
    "ja": "(J)IOS APP 버전"
  },
  "저장파일명": {
    "ko": "저장 파일명",
    "en": "Filename",
    "id": "Nama file",
    "ja": "(J)저장파일명"
  },
  "배포등록": {
    "ko": "배포 등록",
    "en": "Register Distribution",
    "id": "Daftar Distribusi",
    "ja": "(J)배포등록"
  },
  "배포NO": {
    "ko": "배포 NO",
    "en": "No Distribution",
    "id": "Tidak ada distribusi",
    "ja": "(J)배포NO"
  },
  "롤아웃": {
    "ko": "롤아웃",
    "en": "Rollout",
    "id": "Mulai tersedia",
    "ja": "(J)롤아웃"
  },
  "등록일자": {
    "ko": "등록일자",
    "en": "Date of Registration",
    "id": "Tanggal pendaftaran",
    "ja": "(J)등록일자"
  },
  "전장": {
    "ko": "전장",
    "en": "Electronics",
    "id": "Elektronik",
    "ja": "(J)전장"
  },
  "FBP를 선택해주세요.": {
    "ko": "FBP를 선택해 주세요.",
    "en": "Select FBP.",
    "id": "Pilih FBP.",
    "ja": "(J)FBP를 선택해주세요."
  },
  "FBP 배포 등록": {
    "ko": "FBP 배포 등록",
    "en": "Register FBP Distribution",
    "id": "Daftar distribusi FBP",
    "ja": "(J)FBP 배포 등록"
  },
  "위의 카테고리를 선택해주세요": {
    "ko": "위의 카테고리를 선택해 주세요",
    "en": "Please select the above category.",
    "id": "Pilih kategori di atas.",
    "ja": "(J)위의 카테고리를 선택해주세요"
  },
  "FBP를 선택해주세요": {
    "ko": "FBP를 선택해 주세요",
    "en": "Please select FBP.",
    "id": "Pilih FBP.",
    "ja": "(J)FBP를 선택해주세요"
  },
  "FBP 선택": {
    "ko": "FBP 선택",
    "en": "Select FBP.",
    "id": "Pilih FBP.",
    "ja": "(J)FBP 선택"
  },
  "FBP 검색": {
    "ko": "FBP 검색",
    "en": "FBP Search",
    "id": "Pencarian FBP",
    "ja": "(J)FBP 검색"
  },
  "카테고리를 선택해주세요.": {
    "ko": "카테고리를 선택해 주세요.",
    "en": "Please select a category.",
    "id": "Pilih kategori.",
    "ja": "(J)카테고리를 선택해주세요."
  },
  "FBP 배포 상세": {
    "ko": "FBP 배포 상세",
    "en": "FBP Distribution Details",
    "id": "Detail Distribusi FBP",
    "ja": "(J)FBP 배포 상세"
  },
  "승차전원": {
    "ko": "승차 전원",
    "en": "Riding Capacity",
    "id": "Kapasitas berkuda",
    "ja": "(J)승차전원"
  },
  "펌웨어": {
    "ko": "펌웨어",
    "en": "Firmware",
    "id": "Firmware",
    "ja": "(J)펌웨어"
  },
  "출고 및 설치 정보": {
    "ko": "출고 및 설치 정보",
    "en": "Release and Installation Information",
    "id": "Informasi Rilis dan Instalasi",
    "ja": "(J)출고 및 설치 정보"
  },
  "부품번호": {
    "ko": "부품 번호",
    "en": "Parts No.",
    "id": "Bagian No.",
    "ja": "(J)부품번호"
  },
  "사용횟수": {
    "ko": "사용 횟수",
    "en": "No. of Uses",
    "id": "Jumlah Penggunaan",
    "ja": "(J)사용횟수"
  },
  "설치차량": {
    "ko": "설치 차량",
    "en": "Vehicle Installed",
    "id": "Kendaraan terpasang",
    "ja": "(J)설치차량"
  },
  "설치 후 디바이스 등록 과정에 업데이트 됩니다.": {
    "ko": "설치 후 디바이스 등록 과정에 업데이트 됩니다.",
    "en": "Once installed, it will be updated during device registration.",
    "id": "Setelah diinstal, itu akan diperbarui selama pendaftaran perangkat.",
    "ja": "(J)설치 후 디바이스 등록 과정에 업데이트 됩니다."
  },
  "설치일시": {
    "ko": "설치 일시",
    "en": "Setup Date",
    "id": "Tanggal Pengaturan",
    "ja": "(J)설치일시"
  },
  "등록 생성 정보": {
    "ko": "등록 생성 정보",
    "en": "Registration Creation Information",
    "id": "Informasi Pembuatan Pendaftaran",
    "ja": "(J)등록 생성 정보"
  },
  "등록시 생성됩니다.": {
    "ko": "등록시 생성됩니다.",
    "en": "Being created during registration!",
    "id": "Dibuat selama pendaftaran!",
    "ja": "(J)등록시 생성됩니다."
  },
  "대문자로 입력하세요.": {
    "ko": "대문자로 입력하세요.",
    "en": "Please enter capital letters.",
    "id": "Harap masukkan huruf kapital.",
    "ja": "(J)대문자로 입력하세요."
  },
  "선택하세요": {
    "ko": "선택하세요",
    "en": "Select",
    "id": "Pilih",
    "ja": "(J)선택하세요"
  },
  "Mac Address를 입력해주세요.": {
    "ko": "Mac Address를 입력해 주세요.",
    "en": "Please enter an MAC address.",
    "id": "Harap masukkan alamat MAC.",
    "ja": "(J)Mac Address를 입력해주세요."
  },
  "Device를 선택해주세요": {
    "ko": "디바이스를 선택해 주세요",
    "en": "Please select a device.",
    "id": "Pilih perangkat.",
    "ja": "(J)Device를 선택해주세요"
  },
  "Feature를 선택해주세요": {
    "ko": "Feature를 선택해 주세요",
    "en": "Please select a feature.",
    "id": "Pilih fitur.",
    "ja": "(J)Feature를 선택해주세요"
  },
  "노드 등록하기": {
    "ko": "노드 등록하기",
    "en": "Add Node",
    "id": "Tambahkan Node",
    "ja": "(J)노드 등록하기"
  },
  "출고 과정에 설정됩니다.": {
    "ko": "출고 과정에 설정됩니다.",
    "en": "Set to the release process!",
    "id": "Setel ke proses rilis!",
    "ja": "(J)출고 과정에 설정됩니다."
  },
  "노드등록": {
    "ko": "노드 등록",
    "en": "Add Node",
    "id": "Tambahkan Node",
    "ja": "(J)노드등록"
  },
  "설치로그": {
    "ko": "설치로그",
    "en": "(E)설치로그",
    "id": "(ID)설치로그",
    "ja": "(J)설치로그"
  },
  "설치 로그": {
    "ko": "설치 로그",
    "en": "(E)설치 로그",
    "id": "(ID)설치 로그",
    "ja": "(J)설치 로그"
  },
  "설치 로그 없습니다.": {
    "ko": "설치 로그 없습니다.",
    "en": "(E)설치 로그 없습니다.",
    "id": "(ID)설치 로그 없습니다.",
    "ja": "(J)설치 로그 없습니다."
  },
  "로그 ID": {
    "ko": "로그 ID",
    "en": "(E)로그 ID",
    "id": "(ID)로그 ID",
    "ja": "(J)로그 ID"
  },
  "결과유형": {
    "ko": "결과유형",
    "en": "(E)결과유형",
    "id": "(ID)결과유형",
    "ja": "(J)결과유형"
  },
  "결과코드": {
    "ko": "결과코드",
    "en": "(E)결과코드",
    "id": "(ID)결과코드",
    "ja": "(J)결과코드"
  },
  "설치자": {
    "ko": "설치자",
    "en": "(E)설치자",
    "id": "(ID)설치자",
    "ja": "(J)설치자"
  },
  "차량 일련번호": {
    "ko": "차량 일련번호",
    "en": "(E)차량 일련번호",
    "id": "(ID)차량 일련번호",
    "ja": "(J)차량 일련번호"
  },
  "출고 과정에 설정됩니다": {
    "ko": "출고 과정에 설정됩니다",
    "en": "Set to the release process!",
    "id": "Setel ke proses rilis!",
    "ja": "(J)출고 과정에 설정됩니다"
  },
  "노드정보 수정이 완료되었습니다.": {
    "ko": "노드 정보 수정이 완료되었습니다.",
    "en": "Node information is successfully edited!",
    "id": "Informasi simpul berhasil diedit!",
    "ja": "(J)노드정보 수정이 완료되었습니다."
  },
  "노드관리 메뉴에서 수정된 정보를 확인할 수 있습니다.": {
    "ko": "노드 관리 메뉴에서 수정된 정보를 확인할 수 있습니다.",
    "en": "Able to check the revisions in the NODE MANAGEMENT menu.",
    "id": "Dapat memeriksa revisi di menu manajemen Node.",
    "ja": "(J)노드관리 메뉴에서 수정된 정보를 확인할 수 있습니다."
  },
  "노드 정보": {
    "ko": "노드 정보",
    "en": "Node Information",
    "id": "Informasi Node",
    "ja": "(J)노드 정보"
  },
  "선택<": {
    "ko": "선택<",
    "en": "Select<",
    "id": "Pilih <",
    "ja": "(J)선택<"
  },
  "상품 번호": {
    "ko": "상품 번호",
    "en": "Product No.",
    "id": "Nomor produk.",
    "ja": "(J)상품 번호"
  },
  "상품 번호를 입력하세요.": {
    "ko": "상품 번호를 입력하세요.",
    "en": "Please enter the product No.",
    "id": "Harap masukkan No. Produk No.",
    "ja": "(J)상품 번호를 입력하세요."
  },
  "개통완료일시보다 이전 일시를 선택해주세요.": {
    "ko": "개통 완료 일시보다 이전 일시를 선택해 주세요.",
    "en": "Please select the date and time earlier than the date and time of the services launched.",
    "id": "Pilih tanggal dan waktu lebih awal dari tanggal dan waktu layanan yang diluncurkan.",
    "ja": "(J)개통완료일시보다 이전 일시를 선택해주세요."
  },
  "예약개통일시를 선택해주세요.": {
    "ko": "예약 개통 일시를 선택해 주세요.",
    "en": "Please select the date and time of the services to be launched.",
    "id": "Pilih tanggal dan waktu layanan yang akan diluncurkan.",
    "ja": "(J)예약개통일시를 선택해주세요."
  },
  "예약개통일시보다 이후 일시를 선택해주세요.": {
    "ko": "예약 개통 일시보다 이후 일시를 선택해 주세요.",
    "en": "Please select the date and time later than the date and time of the services to be launched.",
    "id": "Pilih tanggal dan waktu lebih lambat dari tanggal dan waktu layanan yang akan diluncurkan.",
    "ja": "(J)예약개통일시보다 이후 일시를 선택해주세요."
  },
  "개통이 완료되지 않았습니다.": {
    "ko": "개통이 완료되지 않았습니다.",
    "en": "Not launched yet!",
    "id": "Belum diluncurkan!",
    "ja": "(J)개통이 완료되지 않았습니다."
  },
  "개통 정보 등록이 완료되었습니다.": {
    "ko": "개통 정보 등록이 완료되었습니다.",
    "en": "The launch information is successfully registered.",
    "id": "Informasi peluncuran berhasil terdaftar.",
    "ja": "(J)개통 정보 등록이 완료되었습니다."
  },
  "개통관리 메뉴에서 등록된 정보를 확인할 수 있습니다.": {
    "ko": "개통 관리 메뉴에서 등록된 정보를 확인할 수 있습니다.",
    "en": "Able to check the information in the LAUNCH MANAGEMENT menu!",
    "id": "Dapat memeriksa informasi di menu manajemen peluncuran!",
    "ja": "(J)개통관리 메뉴에서 등록된 정보를 확인할 수 있습니다."
  },
  "해지하시겠습니까?": {
    "ko": "해지하시겠습니까?",
    "en": "Do you want to cancel?",
    "id": "Apakah Anda ingin membatalkan?",
    "ja": "(J)해지하시겠습니까?"
  },
  "해지시 상품번호 및 개통 정보는 모두 삭제됩니다.": {
    "ko": "해지시 상품번호 및 개통 정보는 모두 삭제됩니다.",
    "en": "Once canceled, the product No. and launch information are all being deleted.",
    "id": "Setelah dibatalkan, No. Produk dan informasi peluncuran semuanya dihapus.",
    "ja": "(J)해지시 상품번호 및 개통 정보는 모두 삭제됩니다."
  },
  "해지하기": {
    "ko": "해지하기",
    "en": "Cancel",
    "id": "Membatalkan",
    "ja": "(J)해지하기"
  },
  "개통 정보": {
    "ko": "개통 정보",
    "en": "Launch Information",
    "id": "Luncurkan informasi",
    "ja": "(J)개통 정보"
  },
  "개통": {
    "ko": "개통",
    "en": "Launch",
    "id": "Meluncurkan",
    "ja": "(J)개통"
  },
  "요금제": {
    "ko": "요금제",
    "en": "Pricing Plan",
    "id": "Rencana penetapan harga",
    "ja": "(J)요금제"
  },
  "예약개통일시": {
    "ko": "예약 개통 일시",
    "en": "Date and Time to be Launched",
    "id": "Tanggal dan waktu untuk diluncurkan",
    "ja": "(J)예약개통일시"
  },
  "개통완료일시": {
    "ko": "개통 완료 일시",
    "en": "Date and Time Launched",
    "id": "Tanggal dan waktu diluncurkan",
    "ja": "(J)개통완료일시"
  },
  "해지일시": {
    "ko": "해지 일시",
    "en": "Date and Time Canceled",
    "id": "Tanggal dan waktu dibatalkan",
    "ja": "(J)해지일시"
  },
  "차량 설치 완료": {
    "ko": "차량 설치 완료",
    "en": "Vehicle successfully installed!",
    "id": "Kendaraan berhasil dipasang!",
    "ja": "(J)차량 설치 완료"
  },
  "차량 미설치": {
    "ko": "차량 미설치",
    "en": "Vehicle Not Installed",
    "id": "Kendaraan tidak terpasang",
    "ja": "(J)차량 미설치"
  },
  "미개통": {
    "ko": "미개통",
    "en": "Not Launched",
    "id": "Tidak diluncurkan",
    "ja": "(J)미개통"
  },
  "선개통": {
    "ko": "선개통",
    "en": "Pre-launched",
    "id": "Pra-peluncuran",
    "ja": "(J)선개통"
  },
  "개통 정보 목록을 엑셀 파일로 업로드 하시겠습니까?": {
    "ko": "개통 정보 목록을 엑셀 파일로 업로드 하시겠습니까?",
    "en": "Do you want to upload the launch information list in Excel format?",
    "id": "Apakah Anda ingin mengunggah daftar informasi peluncuran dalam format Excel?",
    "ja": "(J)개통 정보 목록을 엑셀 파일로 업로드 하시겠습니까?"
  },
  "이미 등록된 개통 정보 중에서 시리얼 번호가 일치하는 경우,": {
    "ko": "이미 등록된 개통 정보 중에서 시리얼 번호가 일치하는 경우,",
    "en": "In the registered launch information, if the serial number is matched,",
    "id": "Dalam informasi peluncuran terdaftar, jika nomor seri dicocokkan,",
    "ja": "(J)이미 등록된 개통 정보 중에서 시리얼 번호가 일치하는 경우,"
  },
  "업로드된 정보로 갱신됩니다.": {
    "ko": "업로드된 정보로 갱신됩니다.",
    "en": "It is updated with uploaded information.",
    "id": "Ini diperbarui dengan informasi yang diunggah.",
    "ja": "(J)업로드된 정보로 갱신됩니다."
  },
  "일괄등록_결과_목록": {
    "ko": "일괄등록_결과_목록",
    "en": "(E)일괄등록_결과_목록",
    "id": "(ID)일괄등록_결과_목록",
    "ja": "(J)일괄등록_결과_목록"
  },
  "전체_목록": {
    "ko": "전체_목록",
    "en": "(E)전체_목록",
    "id": "(ID)전체_목록",
    "ja": "(J)전체_목록"
  },
  "일괄 등록": {
    "ko": "일괄 등록",
    "en": "Register Collectively",
    "id": "Mendaftar secara kolektif",
    "ja": "(J)일괄 등록"
  },
  "등록 폼 다운로드": {
    "ko": "등록 폼 다운로드",
    "en": "Download Registration Form",
    "id": "Unduh formulir pendaftaran",
    "ja": "(J)등록 폼 다운로드"
  },
  "전체 목록 다운로드": {
    "ko": "전체 목록 다운로드",
    "en": "Download All List",
    "id": "Unduh semua daftar",
    "ja": "(J)전체 목록 다운로드"
  },
  "개통 상태": {
    "ko": "개통 상태",
    "en": "Launch Status",
    "id": "Status peluncuran",
    "ja": "(J)개통 상태"
  },
  "설치": {
    "ko": "설치",
    "en": "Install",
    "id": "Install",
    "ja": "(J)설치"
  },
  "상품번호": {
    "ko": "상품번호",
    "en": "Product No.",
    "id": "Nomor produk.",
    "ja": "(J)상품번호"
  },
  "USIM 일련번호": {
    "ko": "USIM 일련번호",
    "en": "USIM Serial No.",
    "id": "USIM Serial No.",
    "ja": "(J)USIM 일련번호"
  },
  "선개통일시": {
    "ko": "선개통 일시",
    "en": "Date and Time Pre-launched",
    "id": "Tanggal dan waktu pra-peluncuran",
    "ja": "(J)선개통일시"
  },
  "리스트 목록이 존재하지 않습니다.": {
    "ko": "리스트 목록이 존재하지 않습니다.",
    "en": "Cannot find the list!",
    "id": "Tidak dapat menemukan daftarnya!",
    "ja": "(J)리스트 목록이 존재하지 않습니다."
  },
  "업데이트 : {value} 기준": {
    "ko": "업데이트 : {value} 기준",
    "en": "Update: Based on {value}",
    "id": "UPDATE: Berdasarkan {value}",
    "ja": "(J)업데이트 : {value} 기준"
  },
  "새로고침": {
    "ko": "새로고침",
    "en": "Refresh",
    "id": "Menyegarkan",
    "ja": "(J)새로고침"
  },
  "목록이 존재하지 않습니다.": {
    "ko": "목록이 존재하지 않습니다.",
    "en": "Cannot find the list!",
    "id": "Tidak dapat menemukan daftarnya!",
    "ja": "(J)목록이 존재하지 않습니다."
  },
  "주일": {
    "ko": "주일",
    "en": "Weeks",
    "id": "Minggu",
    "ja": "(J)주일"
  },
  "개월": {
    "ko": "개월",
    "en": "Months",
    "id": "Bulan",
    "ja": "(J)개월"
  },
  "년": {
    "ko": "년",
    "en": "Years",
    "id": "Bertahun-tahun",
    "ja": "(J)년"
  },
  "일": {
    "ko": "일",
    "en": "Days",
    "id": "Hari -hari",
    "ja": "(J)일"
  },
  "기간": {
    "ko": "기간",
    "en": "Period",
    "id": "Periode",
    "ja": "(J)기간"
  },
  "종료날짜보다 지난 날짜를 선택할 수 없습니다.": {
    "ko": "종료 날짜보다 지난 날짜를 선택할 수 없습니다.",
    "en": "Cannot select a date later than the end date!",
    "id": "Tidak dapat memilih tanggal lebih lambat dari tanggal akhir!",
    "ja": "(J)종료날짜보다 지난 날짜를 선택할 수 없습니다."
  },
  "종료날짜": {
    "ko": "종료 날짜",
    "en": "End Date",
    "id": "Tanggal akhir",
    "ja": "(J)종료날짜"
  },
  "시작날짜보다 전 날짜를 선택할 수 없습니다.": {
    "ko": "시작 날짜보다 전 날짜를 선택할 수 없습니다.",
    "en": "Cannot select a date earlier than the start date!",
    "id": "Tidak dapat memilih tanggal lebih awal dari tanggal mulai!",
    "ja": "(J)시작날짜보다 전 날짜를 선택할 수 없습니다."
  },
  "회원 현황": {
    "ko": "회원 현황",
    "en": "Member Status",
    "id": "Status anggota",
    "ja": "(J)회원 현황"
  },
  "누적가입": {
    "ko": "누적 가입",
    "en": "Cumulative Members",
    "id": "Anggota kumulatif",
    "ja": "(J)누적가입"
  },
  "{value}명": {
    "ko": "{value}명",
    "en": "{value} persons",
    "id": "{value} orang",
    "ja": "(J){value}명"
  },
  "신규가입 (1주)": {
    "ko": "신규 가입 (1주)",
    "en": "New Members (1 week)",
    "id": "Anggota baru (1 minggu)",
    "ja": "(J)신규가입 (1주)"
  },
  "일 접속": {
    "ko": "일 접속",
    "en": "Daily Access",
    "id": "Akses harian",
    "ja": "(J)일 접속"
  },
  "누적가입은 아차키 회원으로 가입한(탈퇴포함) 전체 회원 수를 의미합니다.": {
    "ko": "누적 가입은 아차키 회원으로 가입한(탈퇴 포함) 전체 회원 수를 의미합니다.",
    "en": "‘Cumulative Members’ refer to the number of the members who joined Achakey (including those who withdrew membership).",
    "id": "'Anggota kumulatif' merujuk pada jumlah anggota yang bergabung dengan Achakey (termasuk mereka yang menarik keanggotaan).",
    "ja": "(J)누적가입은 아차키 회원으로 가입한(탈퇴포함) 전체 회원 수를 의미합니다."
  },
  "신규가입은 조회일 기준 일주일간 가입한 신규 회원 수를 의미합니다.": {
    "ko": "신규 가입은 조회일 기준 일주일간 가입한 신규 회원 수를 의미합니다.",
    "en": "‘New Members’ represent the number of the members who newly joined Achakey for the past week.",
    "id": "'Anggota baru' mewakili jumlah anggota yang baru bergabung dengan Achakey selama seminggu terakhir.",
    "ja": "(J)신규가입은 조회일 기준 일주일간 가입한 신규 회원 수를 의미합니다."
  },
  "앱 일 접속은 마지막 갱신 시점 기준 24시간 내에 접속한 회원 수를 의미합니다.": {
    "ko": "앱 일 접속은 마지막 갱신 시점 기준 24시간 내에 접속한 회원 수를 의미합니다.",
    "en": "‘Daily Access’ means the number of members who visited the app for the past 24 hours.",
    "id": "'Akses harian' berarti jumlah anggota yang mengunjungi aplikasi selama 24 jam terakhir.",
    "ja": "(J)앱 일 접속은 마지막 갱신 시점 기준 24시간 내에 접속한 회원 수를 의미합니다."
  },
  "회": {
    "ko": "회",
    "en": "Times",
    "id": "Waktu",
    "ja": "(J)회"
  },
  "디지털 차키 사용현황": {
    "ko": "디지털 차 키 사용현황",
    "en": "Digital Car Key Usage",
    "id": "Penggunaan Kunci Mobil Digital",
    "ja": "(J)디지털 차키 사용현황"
  },
  "업데이트: {value} 기준": {
    "ko": "업데이트: {value} 기준",
    "en": "Update: Based on {value}",
    "id": "UPDATE: Berdasarkan {value}",
    "ja": "(J)업데이트: {value} 기준"
  },
  "연결": {
    "ko": "연결",
    "en": "Connection",
    "id": "Koneksi",
    "ja": "(J)연결"
  },
  "문닫힘": {
    "ko": "문닫힘",
    "en": "Close",
    "id": "Menutup",
    "ja": "(J)문닫힘"
  },
  "문열림": {
    "ko": "문열림",
    "en": "Open",
    "id": "Membuka",
    "ja": "(J)문열림"
  },
  "차량공유": {
    "ko": "차량 공유",
    "en": "Car Share",
    "id": "Berbagi mobil",
    "ja": "(J)차량공유"
  },
  "연결은 차량과 아차키가 연결된 회수를 의미합니다.": {
    "ko": "연결은 차량과 아차키가 연결된 횟수를 의미합니다.",
    "en": "‘Connection’ refers the number of car-Achakey connections.",
    "id": "‘Koneksi’ mengacu jumlah koneksi mobil-acak.",
    "ja": "(J)연결은 차량과 아차키가 연결된 회수를 의미합니다."
  },
  "문닫힘은 아차키키를 통해 차 문 닫힘이 제어된 회수를 의미합니다.": {
    "ko": "문닫힘은 아차키키를 통해 차 문 닫힘이 제어된 횟수를 의미합니다.",
    "en": "‘Close’ refers to the number of the car door closed via Achakey.",
    "id": "'Tutup' mengacu pada jumlah pintu mobil yang ditutup melalui Achakey.",
    "ja": "(J)문닫힘은 아차키키를 통해 차 문 닫힘이 제어된 회수를 의미합니다."
  },
  "문열림은 아차키를 통해 차 문 열림이 제어된 회수를 의미합니다.": {
    "ko": "문열림은 아차키를 통해 차 문 열림이 제어된 횟수를 의미합니다.",
    "en": "‘Open’ represents the number of the car door opened via Achakey.",
    "id": "‘Open’ mewakili jumlah pintu mobil yang dibuka melalui Achakey.",
    "ja": "(J)문열림은 아차키를 통해 차 문 열림이 제어된 회수를 의미합니다."
  },
  "차량공유는 아차키를 통해 차량이 공유된 회수를 의미합니다.": {
    "ko": "차량 공유는 아차키를 통해 차량이 공유된 횟수를 의미합니다.",
    "en": "‘Car Share’ means the number of the vehicle shared via Achakey.",
    "id": "'Bagikan mobil' berarti jumlah kendaraan yang dibagikan melalui Achakey.",
    "ja": "(J)차량공유는 아차키를 통해 차량이 공유된 회수를 의미합니다."
  },
  "(표시단위: 1주일/누적)": {
    "ko": "(표시단위: 1주일/누적)",
    "en": "(unit: 1 week/cumulative)",
    "id": "(Unit: 1 minggu/kumulatif)",
    "ja": "(J)(표시단위: 1주일/누적)"
  },
  "자동차 현황": {
    "ko": "자동차 현황",
    "en": "Vehicle Status",
    "id": "Status kendaraan",
    "ja": "(J)자동차 현황"
  },
  "자동차 수": {
    "ko": "자동차 수",
    "en": "No. of Cars",
    "id": "Jumlah mobil",
    "ja": "(J)자동차 수"
  },
  "디지털 차키 설치": {
    "ko": "디지털 차 키 설치",
    "en": "Install Digital Car Keys",
    "id": "Instal Kunci Mobil Digital",
    "ja": "(J)디지털 차키 설치"
  },
  "인포 설치": {
    "ko": "인포 설치",
    "en": "Install INFO",
    "id": "Instal info",
    "ja": "(J)인포 설치"
  },
  "커넥티드 설치": {
    "ko": "커넥티드 설치",
    "en": "Install Connected",
    "id": "Instal terhubung",
    "ja": "(J)커넥티드 설치"
  },
  "자동차 수는 어제까지 등록되어 운영 중인 전체 차량 수를 의미합니다.": {
    "ko": "자동차 수는 어제까지 등록되어 운영 중인 전체 차량 수를 의미합니다.",
    "en": "‘No. of Cars’ represents a total number of the vehicles registered and operated until yesterday.",
    "id": "'TIDAK. mobil 'mewakili jumlah total kendaraan yang terdaftar dan dioperasikan hingga kemarin.",
    "ja": "(J)자동차 수는 어제까지 등록되어 운영 중인 전체 차량 수를 의미합니다."
  },
  "(E)1분": {
    "ko": "(E)1분",
    "en": "(E)(E)1분",
    "id": "(ID)(E)1분",
    "ja": "(J)(E)1분"
  },
  "만": {
    "ko": "만",
    "en": "__",
    "id": "__",
    "ja": "(J)만"
  },
  "세": {
    "ko": "세",
    "en": "Years old",
    "id": "Tahun",
    "ja": "(J)세"
  },
  "{value}분 미만": {
    "ko": "{value}분 미만",
    "en": "Less than {value} min.",
    "id": "Kurang dari {value} min.",
    "ja": "(J){value}분 미만"
  },
  "{value1}시간 {value2}분": {
    "ko": "{value1}시간 {value2}분",
    "en": "{value1} hrs. {value2} min.",
    "id": "{value1} jam. {value2} min.",
    "ja": "(J){value1}시간 {value2}분"
  },
  "{value}분": {
    "ko": "{value}분",
    "en": "{value} min.",
    "id": "{value} min.",
    "ja": "(J){value}분"
  },
  "{value}시간": {
    "ko": "{value}시간",
    "en": "{value} hrs.",
    "id": "{value} jam.",
    "ja": "(J){value}시간"
  },
  "홈": {
    "ko": "홈",
    "en": "Home",
    "id": "Rumah",
    "ja": "(J)홈"
  },
  "운영자정보": {
    "ko": "운영자 정보",
    "en": "Operator Information",
    "id": "Informasi operator",
    "ja": "(J)운영자정보"
  },
  "운영자권한": {
    "ko": "운영자 권한",
    "en": "Operator Permission",
    "id": "Izin operator",
    "ja": "(J)운영자권한"
  },
  "코드관리": {
    "ko": "코드 관리",
    "en": "Code Management",
    "id": "Manajemen kode",
    "ja": "(J)코드관리"
  },
  "리소스관리": {
    "ko": "리소스 관리",
    "en": "Resource Management",
    "id": "Pengelolaan sumber daya",
    "ja": "(J)리소스관리"
  },
  "메뉴관리": {
    "ko": "메뉴 관리",
    "en": "Menu Management",
    "id": "Manajemen Menu",
    "ja": "(J)메뉴관리"
  },
  "개인정보 조회이력": {
    "ko": "개인정보 조회 이력",
    "en": "Personal Information Search History",
    "id": "Riwayat Pencarian Informasi Pribadi",
    "ja": "(J)개인정보 조회이력"
  },
  "디바이스관리": {
    "ko": "디바이스 관리",
    "en": "Device Management",
    "id": "Manajemen perangkat",
    "ja": "(J)디바이스관리"
  },
  "노드관리": {
    "ko": "노드 관리",
    "en": "Node Management",
    "id": "Manajemen Node",
    "ja": "(J)노드관리"
  },
  "펌웨어버젼관리": {
    "ko": "펌웨어 버젼 관리",
    "en": "Firmware Version Management",
    "id": "Manajemen Versi Firmware",
    "ja": "(J)펌웨어버젼관리"
  },
  "펌웨어배포관리": {
    "ko": "펌웨어 배포 관리",
    "en": "Firmware Distribution Management",
    "id": "Manajemen Distribusi Firmware",
    "ja": "(J)펌웨어배포관리"
  },
  "디바이스 기술지원": {
    "ko": "디바이스 기술 지원",
    "en": "Device Technical Assistance",
    "id": "Bantuan teknis perangkat",
    "ja": "(J)디바이스 기술지원"
  },
  "개통관리": {
    "ko": "개통 관리",
    "en": "Launch Management",
    "id": "Manajemen Peluncuran",
    "ja": "(J)개통관리"
  },
  "자동차이관": {
    "ko": "자동차 이관",
    "en": "Car Transfer",
    "id": "Transfer mobil",
    "ja": "(J)자동차이관"
  },
  "제어이력": {
    "ko": "제어 이력",
    "en": "Control History",
    "id": "Riwayat kontrol",
    "ja": "(J)제어이력"
  },
  "주행이력": {
    "ko": "주행 이력",
    "en": "Driving Data",
    "id": "Data mengemudi",
    "ja": "(J)주행이력"
  },
  "주행이력 상세화면": {
    "ko": "주행 이력 상세화면",
    "en": "Driving Data Detailed Page",
    "id": "Mengemudi Halaman Data Data",
    "ja": "(J)주행이력 상세화면"
  },
  "공유이력": {
    "ko": "공유 이력",
    "en": "Share History",
    "id": "Bagikan Sejarah",
    "ja": "(J)공유이력"
  },
  "케어알림이력": {
    "ko": "케어알림이력",
    "en": "(E)케어알림이력",
    "id": "(ID)케어알림이력",
    "ja": "(J)케어알림이력"
  },
  "앱메인배너": {
    "ko": "앱 메인 배너",
    "en": "App Main Banner",
    "id": "Aplikasi Banner Utama",
    "ja": "(J)앱메인배너"
  },
  "앱메뉴배너": {
    "ko": "앱 메뉴 배너",
    "en": "App Menu Banner",
    "id": "Spanduk Menu Aplikasi",
    "ja": "(J)앱메뉴배너"
  },
  "사용메뉴얼": {
    "ko": "사용 매뉴얼",
    "en": "User Manual",
    "id": "Panduan pengguna",
    "ja": "(J)사용메뉴얼"
  },
  "공지사항": {
    "ko": "공지사항",
    "en": "Notices",
    "id": "Pemberitahuan",
    "ja": "(J)공지사항"
  },
  "약관등록": {
    "ko": "약관 등록",
    "en": "Add Terms of Use",
    "id": "Tambahkan Ketentuan Penggunaan",
    "ja": "(J)약관등록"
  },
  "이용약관 정보": {
    "ko": "이용약관 정보",
    "en": "Terms of Use Information",
    "id": "Ketentuan Informasi Penggunaan",
    "ja": "(J)이용약관 정보"
  },
  "자주묻는질문": {
    "ko": "자주 묻는 질문",
    "en": "FAQs",
    "id": "FAQ",
    "ja": "(J)자주묻는질문"
  },
  "뉴스게시글": {
    "ko": "뉴스 게시글",
    "en": "News Posts",
    "id": "Posting Berita",
    "ja": "(J)뉴스게시글"
  },
  "뉴스 게시글": {
    "ko": "뉴스 게시글",
    "en": "News Posts",
    "id": "Posting Berita",
    "ja": "(J)뉴스 게시글"
  },
  "앱버전관리": {
    "ko": "앱 버전 관리",
    "en": "App Version Management",
    "id": "Manajemen Versi Aplikasi",
    "ja": "(J)앱버전관리"
  },
  "지원차량": {
    "ko": "지원차량",
    "en": "(E)지원차량",
    "id": "(ID)지원차량",
    "ja": "(J)지원차량"
  },
  "앱마케팅배너": {
    "ko": "앱마케팅배너",
    "en": "(E)앱마케팅배너",
    "id": "(ID)앱마케팅배너",
    "ja": "(J)앱마케팅배너"
  },
  "파트너정보": {
    "ko": "파트너 정보",
    "en": "Partner Information",
    "id": "Informasi pasangan",
    "ja": "(J)파트너정보"
  },
  "관리자정보": {
    "ko": "관리자 정보",
    "en": "Admin Information",
    "id": "Informasi Admin",
    "ja": "(J)관리자정보"
  },
  "엔지니어정보": {
    "ko": "엔지니어 정보",
    "en": "Engineer Information",
    "id": "Informasi Insinyur",
    "ja": "(J)엔지니어정보"
  },
  "파트너공지사항": {
    "ko": "파트너 공지사항",
    "en": "Partner Notices",
    "id": "PEMBERITAHUAN MITRA",
    "ja": "(J)파트너공지사항"
  },
  "통계자료": {
    "ko": "통계자료",
    "en": "Statistics",
    "id": "Statistik",
    "ja": "(J)통계자료"
  },
  "일별 디바이스 현황": {
    "ko": "일별 디바이스 현황",
    "en": "Daily Device Status",
    "id": "Status perangkat harian",
    "ja": "(J)일별 디바이스 현황"
  },
  "일별 회원 현황": {
    "ko": "일별 회원 현황",
    "en": "Daily Member Status",
    "id": "Status anggota harian",
    "ja": "(J)일별 회원 현황"
  },
  "블루포인트 기술지원": {
    "ko": "블루포인트 기술지 원",
    "en": "Bluepoint Technical Assistance",
    "id": "Bantuan teknis Bluepoint",
    "ja": "(J)블루포인트 기술지원"
  },
  "조회 성공": {
    "ko": "조회 성공",
    "en": "(E)조회 성공",
    "id": "(ID)조회 성공",
    "ja": "(J)조회 성공"
  },
  "원격제어 ${result}": {
    "ko": "원격제어 ${result}",
    "en": "(E)원격제어 ${result}",
    "id": "(ID)원격제어 ${result}",
    "ja": "(J)원격제어 ${result}"
  },
  "원격제어에 ${result}했습니다.": {
    "ko": "원격제어에 ${result}했습니다.",
    "en": "(E)원격제어에 ${result}했습니다.",
    "id": "(ID)원격제어에 ${result}했습니다.",
    "ja": "(J)원격제어에 ${result}했습니다."
  },
  "운영관리": {
    "ko": "운영 관리",
    "en": "Operation Management",
    "id": "Manajemen operasional",
    "ja": "(J)운영관리"
  },
  "권한관리": {
    "ko": "권한 관리",
    "en": "Permission Management",
    "id": "Manajemen izin",
    "ja": "(J)권한관리"
  },
  "펌웨어버전관리": {
    "ko": "펌웨어 버전 관리",
    "en": "Firmware Version Management",
    "id": "Manajemen Versi Firmware",
    "ja": "(J)펌웨어버전관리"
  },
  "회원관리": {
    "ko": "회원 관리",
    "en": "Member Management",
    "id": "Manajemen anggota",
    "ja": "(J)회원관리"
  },
  "자동차정보관리": {
    "ko": "자동차 정보 관리",
    "en": "Car Information Management",
    "id": "Manajemen Informasi Mobil",
    "ja": "(J)자동차정보관리"
  },
  "사용이력": {
    "ko": "사용 이력",
    "en": "Use History",
    "id": "Gunakan riwayat",
    "ja": "(J)사용이력"
  },
  "서비스관리": {
    "ko": "서비스 관리",
    "en": "Service Management",
    "id": "Manajemen Pelayanan",
    "ja": "(J)서비스관리"
  },
  "파트너관리": {
    "ko": "파트너 관리",
    "en": "Partner Management",
    "id": "Manajemen Mitra",
    "ja": "(J)파트너관리"
  },
  "파트너 공지사항": {
    "ko": "파트너 공지사항",
    "en": "Partner Notices",
    "id": "PEMBERITAHUAN MITRA",
    "ja": "(J)파트너 공지사항"
  },
  "로그아웃 하시겠습니까?": {
    "ko": "로그아웃 하시겠습니까?",
    "en": "Do you want to log out?",
    "id": "Apakah Anda ingin keluar?",
    "ja": "(J)로그아웃 하시겠습니까?"
  },
  "로그아웃": {
    "ko": "로그아웃",
    "en": "Log out",
    "id": "Keluar",
    "ja": "(J)로그아웃"
  },
  "안녕하세요": {
    "ko": "안녕하세요",
    "en": "Hello!",
    "id": "Halo!",
    "ja": "(J)안녕하세요"
  },
  "주식회사 튠잇": {
    "ko": "주식회사 튠잇",
    "en": "TUNEIT CO., LTD.",
    "id": "TOLEIT CO., Ltd.",
    "ja": "(J)주식회사 튠잇"
  },
  "주소: 경기도 성남시 분당구 느티로 67(정자동) 4층 401-1": {
    "ko": "주소: 경기도 성남시 분당구 느티로 67(정자동) 4층 401-1",
    "en": "Address: 67, Neuti-ro, #401-1 (4F), Bundang-gu, Seongnam-si, Gyeonggi-do",
    "id": "Alamat: 67, Neuti-ro, #401-1 (4F), Bundang-gu, Seongnam-si, Gyeonggi-do",
    "ja": "(J)주소: 경기도 성남시 분당구 느티로 67(정자동) 4층 401-1"
  },
  "대표전화: ": {
    "ko": "대표전화:",
    "en": "TEL:",
    "id": "Tel:",
    "ja": "(J)대표전화: "
  },
  "문의메일: ": {
    "ko": "문의 메일:",
    "en": "Email:",
    "id": "Surel:",
    "ja": "(J)문의메일: "
  },
  "다시 로그인해주세요.": {
    "ko": "다시 로그인해 주세요.",
    "en": "Please log in again.",
    "id": "Harap masuk lagi.",
    "ja": "(J)다시 로그인해주세요."
  },
  "Google OTP 등록이 완료되었습니다.\n로그인 페이지로 이동합니다.\n이메일/비밀번호로 로그인하면 Google OTP로 2차 인증을 완료해야 정상적으로 로그인을 할 수 있습니다.": {
    "ko": "Google OTP 등록이 완료되었습니다.\n로그인 페이지로 이동합니다.\n이메일/비밀번호로 로그인하면 Google OTP로 2차 인증을 완료해야 정상적으로 로그인을 할 수 있습니다.",
    "en": "(E)Google OTP 등록이 완료되었습니다.\n로그인 페이지로 이동합니다.\n이메일/비밀번호로 로그인하면 Google OTP로 2차 인증을 완료해야 정상적으로 로그인을 할 수 있습니다.",
    "ja": "(J)Google OTP 등록이 완료되었습니다.\n로그인 페이지로 이동합니다.\n이메일/비밀번호로 로그인하면 Google OTP로 2차 인증을 완료해야 정상적으로 로그인을 할 수 있습니다.",
    "id": "(ID)Google OTP 등록이 완료되었습니다.\n로그인 페이지로 이동합니다.\n이메일/비밀번호로 로그인하면 Google OTP로 2차 인증을 완료해야 정상적으로 로그인을 할 수 있습니다."
  }
};
